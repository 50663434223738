import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import NotificationStore from "./notification.store"
import UserStore from "./user.store"
import React from 'react'
// import UserStore from "./user.store";
const notification = NotificationStore

class ExamStore {
    exams
    selectedExam
    errors
    pendingExams

    constructor () {
        this.exams = []
        this.pendingExams = []
        this.selectedExam = {}
        this.errors = { exams: true }
        this.api = api
        makeObservable( this, {
            exams: observable,
            errors: observable,
            selectedExam: observable,
            getExam: computed,
            getPendingExams: computed,
            getErrors: computed,
            getExams: computed,
            createExam: action,
            updateExam: action,
            setExams: action,
            setPendingExams: action,
            setSelectedExam: action,
            fetchExam: action,
            fetchExams: action,
            fetchExamsBySubject: action,
            fetchPendingExams: action
        } )
    }

    get getExam() {
        return toJS( this.selectedExam )
    }

    get getExams() {
        return toJS( this.exams )
    }

    get getErrors() {
        return toJS( this.errors )
    }

    get getPendingExams() {
        return toJS( this.pendingExams )
    }

    setExams( exams ) {
        runInAction( () => {
            this.exams = exams
        } )
    }

    setPendingExams( exams ) {
        runInAction( () => {
            this.pendingExams = exams
        } )
    }

    setSelectedExam( exam ) {
        this.selectedExam = exam
    }

    async createExam( payload ) {
        try {
            await this.api.createExam( payload )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }

    async fetchExams() {
        try {
            const res = await this.api.getAllExams()
            if ( res.data.success === true || res.data.exams ) {
                this.setExams( res.data.exams )
                runInAction( () => {
                    this.errors.exams = false
                } )
                return true
            } else {
                runInAction( () => {
                    this.errors.exams = true
                } )
                return false
            }
        } catch ( err ) {
            runInAction( () => {
                this.errors.exams = true
            } )
            console.log( err, err.status, err?.response?.status )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                return false
        }
    }

    async fetchExamsByDept( deptCode ) {
        try {
            const res = await api.fetchExamsByDept( deptCode )
            if ( res.data.success === true || res.data.exams ) {
                this.setExams( res.data.exams )
                return true
            } else
                return false
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }

    async fetchExamsByInstitute( institute ) {
        try {
            const { data } = await api.fetchExamsByInstitute( institute )
            if ( data.success === true || data.exams ) {
                this.setExams( data.exams )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }

    async fetchExamsBySubject( subject ) {
        try {
            const { data } = await api.fetchExamsBySubject( subject )
            if ( data.success === true || data.data ) {
                this.setExams( data.data )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }


    async fetchPendingExams( subjectId ) {
        try {
            const { data } = await api.fetchPendingExams( { subjectId } )
            if ( data.success === true || data.data ) {
                this.setPendingExams( data.data )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }


    async updateExam( id, payload ) {
        try {
            const res = await this.api.updateExam( id, payload )
            if ( res.status === 200 ) {
                notification.setNotification( true, "Exam updated" )
            }
            await this.fetchExam( id )

        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err?.response?.data?.message || "Something went wrong!" )
        }
    }

    async fetchExam( id ) {
        try {
            const res = await this.api.getExam( id )
            this.setSelectedExam( res.data )
            return { success: true, code: 200 }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching the exam" )
            return { success: false, code: err?.response?.status || 500 }
        }
    }
}


const ExamStoreInstance = new ExamStore()

export default ExamStoreInstance

const ExamStoreContext = React.createContext()

export const ExamStoreProvider = ( { children, store } ) => {
    return (
        <ExamStoreContext.Provider value={store}>{children}</ExamStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useExamStore = () => React.useContext( ExamStoreContext )