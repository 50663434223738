import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    Popover,
    TextField,
    Typography,
    useTheme,
    Select,
    MenuItem
} from '@mui/material'
import {
    ArrowDropDown,
    CancelOutlined,
    CheckBox,
    CheckBoxOutlineBlank,
} from '@mui/icons-material'
import { useDiscussionForum } from '../../store/discussionforum.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useUserStore } from '../../store/user.store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import api from '../../service/api'
import * as Yup from 'yup'
import CustomWysiwyg from '../common/CustomWysiwyg'

let editorRef, filesList = []

const handleFileInput = async ( type ) => {
    const input = document.createElement( 'input' )

    input.setAttribute( 'type', 'file' )
    input.setAttribute( 'accept', type === 'image' ? 'image/*' : "video/*" )
    input.click()

    input.onchange = async () => {
        var file = input.files[0]
        var formData = new FormData()
        // var fileName = `${Date.now()}_${file.name}`
        if ( type === 'image' && !file.type?.includes( 'image' ) ) {
            toast( "Invalid image format!" )
            return ''
        }
        if ( type === 'video' && !file.type?.includes( 'video' ) ) {
            toast( "Invalid video format!" )
            return ''
        }

        formData.append( type, file )

        try {
            if ( editorRef.current ) {
                const { data: { data: file } } = type === "image" ? await api.discussionForum.uploadImage( formData ) : await api.discussionForum.uploadVideo( formData )
                const range = editorRef.current.getSelection()

                var url = file.location

                editorRef.current.insertEmbed( range.index, type, url )
                editorRef.current.setSelection( range.index + 1 )
                filesList.push( url )
                setTimeout( () => {
                    editorRef.current.focus()
                }, 10 )
            }

        } catch ( err ) {
            console.log( err )
        }
    }
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ],
        handlers: {
            image: async () => await handleFileInput( 'image' ),
            video: async () => await handleFileInput( 'video' )
        }
    }
}


const CreateDiscussionDialog = ( { state, setState, getData } ) => {
    const [subjectsDialogAnchorEl, setSubjectsDialogAnchorEl] = useState( null )
    const [selectedSubjectsIds, setSelectedSubjectsIds] = useState( [] )
    const [selectedYear, setSelectedYear] = useState( 1 )
    const [selectedSem, setSelectedSem] = useState( 1 )
    const [semesters, setSemesters] = useState( [] )
    const [postingDiscussion, setPostingDiscussion] = useState( false )
    const [loadingSubjects, setLoadingSubjects] = useState( true )
    const [subjects, setSubjects] = useState( null )

    const DiscussionForumStore = useDiscussionForum()
    const subjectsInputContainerRef = useRef()
    const UserStore = useUserStore()

    const navigate = useNavigate()

    const { palette, theme, border } = useTheme()
    editorRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { subSearchText: '' } )
    const subSearchText = searchParams.get( 'subSearchText' ) || ''

    const initialValues = {
        title: '',
        content: '',
        access: 'subject_specific',
        access_groups: [],
        categories: [],
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( 'Discussion title is required' ),
        content: Yup.string().required( 'Content is required' ).test( "CHECK_CONTENT", "Please enter discussion content", val => {
            if ( !val || val?.trim() === "" || val?.trim()?.replace( /\s/gi, "" ) === `<p></p>` || val?.trim()?.replace( /\s/gi, "" ) === `<p><br></p>` ) return false
            return true
        } ),
        access: Yup.string()
            .required( 'Access type is required' )
            .oneOf( ['global', 'subject_specific'] ),
        access_groups: Yup.array()
            .required( 'Access groups are required' )
            .min( 1, 'Discussion should have at least one access group' ),
        categories: Yup.array()
            .required( 'Categories are required' )
            .min( 1, 'Discussion should have at least one category' ),
    } )

    const setSearchParamValue = ( key, val ) => {
        setSearchParams(
            ( prev ) => {
                prev.set( key, val )
                return prev
            },
            { replace: true }
        )
    }

    const setCategories = ( setFieldValue, val ) => {
        setFieldValue( 'categories', val )
    }

    const handleClose = ( resetForm = null ) => {
        if ( resetForm ) resetForm()
        setSelectedSubjectsIds( [] )
        setState( false )
    }

    const handleCategoriesAddition = ( e, setFieldValue, categories ) => {
        if ( ( e.key === 'Enter' || e.key === 'Tab' ) && e.target.value.trim() !== '' ) {
            e.preventDefault()
            setCategories( setFieldValue, [...categories, e.target.value] )
            e.target.value = ''
        }
    }

    const removeCategory = ( index, setFieldValue, categories ) => {
        let copy = [...categories]
        copy.splice( index, 1 )
        setCategories( setFieldValue, copy )
    }

    const toggleSubjectInclusion = ( sub, value, setFieldValue ) => {
        if ( selectedSubjectsIds.includes( sub.subject_id ) ) {
            let index = null
            for ( let k = 0; k < value.length; k++ ) {
                const currentSub = value[k]
                if ( sub.subject_id === currentSub.subject_id ) {
                    index = k
                    break
                }
            }
            if ( index > -1 ) {
                removeSubject( index, value, setFieldValue )
            }
        } else {
            setFieldValue( 'access_groups', [...value, sub] )
            setSelectedSubjectsIds( [...value, sub].map( ( s ) => s.subject_id ) )
        }
    }

    const removeSubject = ( index, subjects, setFieldValue ) => {
        let copy = [...subjects]
        copy.splice( index, 1 )
        setFieldValue( 'access_groups', copy )
        setSelectedSubjectsIds( copy.map( ( s ) => s.subject_id ) )
    }

    const getSubjects = async () => {
        setLoadingSubjects( true )
        if ( UserStore.getErrorsStatuses?.subjects === true )
            await UserStore.fetchUserSubjects()
        setSubjects( UserStore.getUserSubjects )
        if ( UserStore.getUser.user_role === "STUDENT" ) {
            const years = Object.keys( UserStore.getUserSubjects )
            setSelectedSem( UserStore.getUser.current_sem )
            setSelectedYear( UserStore.getUser.current_year )
            let sems = []
            for ( let i = 0; i < years.length; i++ ) {
                const year = years[i]
                sems.push( ...Object.keys( UserStore.getUserSubjects[year] ) )
            }
            setSemesters( sems )
        }
        setLoadingSubjects( false )
    }

    const handleSemesterChange = e => {
        let year = Math.ceil( e.target.value / 2 )
        year = year === 0 ? 1 : year
        setSelectedYear( year )
        setSelectedSem( e.target.value )
    }

    const cachedGetSubjects = useCallback( getSubjects, [UserStore] )

    const handleEditorValueChange = ( val, setFieldValue, previousValue ) => {
        setFieldValue( 'content', val )
        if ( val.length !== previousValue.length ) {
            for ( let i = 0; i < filesList.length; i++ ) {
                const url = filesList[i]
                if ( !val.includes( url ) ) {
                    try {
                        api.discussionForum.deleteFile( { url } )
                    } catch ( err ) {

                    }
                }
            }
        }
    }

    const createDiscussion = async ( values, { resetForm } ) => {
        setPostingDiscussion( true )
        const { success, data } = await DiscussionForumStore.createDiscussion( values )
        if ( success ) {
            await getData()
            toast( <span>Successfully created new discussion: <strong>{values.title}</strong> </span> )
            handleClose( resetForm )
            navigate( `${UserStore.getUser.user_role === 'STUDENT' ? "/student" : "/faculty"}/discussions/${data._id}` )
        }
        setPostingDiscussion( false )
    }


    useEffect( () => {
        const getData = async () => {
            await cachedGetSubjects()
        }
        getData()
    }, [cachedGetSubjects] )

    return (
        <Dialog
            PaperProps={{ sx: { background: palette.form.formCardBg, width: '90%', maxWidth: '850px' } }}
            open={state}
        >
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                gap='20px'
                padding='10px 20px'
                borderBottom='1px solid #d3d3d3'
            >
                <Typography variant='h6'>Create new discussion</Typography>
                <IconButton onClick={() => handleClose()}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            <DialogContent sx={{ padding: 0 }}>
                <Box padding="20px">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={createDiscussion}
                        validationSchema={validationSchema}
                    >
                        {( { values, setFieldValue, resetForm } ) => {
                            return (
                                <Form>
                                    <Box
                                        borderRadius="5px"
                                        bgcolor={palette.form.formBg}
                                        padding='20px'
                                        border={border[1]}
                                        display='flex'
                                        flexDirection='column'
                                        gap='10px'
                                    >
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Discussion title
                                            </Typography>
                                            <Typography
                                                marginTop='-5px'
                                                gutterBottom
                                                variant='body2'
                                                fontSize='12px'
                                                color='textSecondary'
                                            >
                                                Summarize the discussion topic in the short title.
                                            </Typography>
                                            <Field as={TextField} placeholder='Eg: State value is not changing in ReactJs' type='text' size='small' name='title' variant='outlined' fullWidth />
                                            <Typography variant='subtitle2' color='error'>
                                                {' '}
                                                <ErrorMessage name='title' />{' '}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Discussion content
                                            </Typography>
                                            <Typography
                                                marginTop='-5px'
                                                gutterBottom
                                                variant='body2'
                                                fontSize='12px'
                                                color='textSecondary'
                                            >
                                                Describe the discussion topic in detail.
                                            </Typography>
                                            <Box>
                                                <Box bgcolor={palette.form.inputBg}>
                                                    <CustomWysiwyg height='200px' onChange={( e ) => handleEditorValueChange( e, setFieldValue, values.content )}
                                                        setParentEditorRef={( refVal ) => {
                                                            editorRef = refVal
                                                        }}
                                                        value={values.content} modules={modules} placeholder='Share your thoughts...' />
                                                </Box>
                                                <Typography variant='subtitle2' color='error'>
                                                    <ErrorMessage name='content' />
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Specify discussion categories
                                            </Typography>
                                            <Box
                                                padding='5px 10px'
                                                flexWrap='wrap'
                                                display='flex'
                                                alignItems='center'
                                                gap='10px'
                                                borderRadius='5px'
                                                border='1px solid #d3d3d3'
                                            >
                                                {values.categories.length > 0 &&
                                                    values.categories.map( ( category, index ) => (
                                                        <Box
                                                            key={index}
                                                            bgcolor={`${palette.primary.light}11`}
                                                            display='flex'
                                                            gap='10px'
                                                            borderRadius='0px'
                                                            width='fit-content'
                                                            alignItems='center'
                                                            padding='0 5px'
                                                            border={`1px solid ${palette.primary.light}55`}
                                                        >
                                                            <Typography variant='subtitle2'>
                                                                {category}
                                                            </Typography>
                                                            <IconButton
                                                                size='small'
                                                                onClick={() =>
                                                                    removeCategory(
                                                                        index,
                                                                        setFieldValue,
                                                                        values.categories
                                                                    )
                                                                }
                                                            >
                                                                <CancelOutlined fontSize='small' />
                                                            </IconButton>
                                                        </Box>
                                                    ) )}
                                                <Box flexGrow={1}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        sx={{ borderRadius: "5px", overflow: "hidden" }}
                                                        inputProps={{ sx: { paddingX: "5px", paddingY: "10px" } }}
                                                        onKeyDown={( e ) => handleCategoriesAddition( e, setFieldValue, values.categories )}
                                                        onBlur={e => handleCategoriesAddition( e, setFieldValue, values.categories )}
                                                        variant='standard'
                                                        InputProps={{ disableUnderline: true }}
                                                        type='text'
                                                        placeholder='Type categoriy and press enter to add.'
                                                    />
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2' color='error'>
                                                <ErrorMessage name='categories' />
                                            </Typography>
                                        </Box>
                                        {/* <Box>
                                            <Typography variant="subtitle1" fontWeight="500">Select discussion access type</Typography>
                                            <RadioGroup name='access' value={values.access} onChange={( e ) => handleAccessTypeChange( e, setFieldValue )} sx={{ marginTop: "-5px" }} row>
                                                <FormControlLabel control={<Radio size="small" />} value="global" label="Global access" />
                                                <FormControlLabel control={<Radio size="small" />} value="subject_specific" label="Subject specific access" />
                                            </RadioGroup>
                                            <Typography variant='subtitle2' color="error" > <ErrorMessage name="access" /> </Typography>
                                        </Box> */}
                                        {values.access === 'subject_specific' && (
                                            <Box>
                                                <Typography variant='subtitle1' fontWeight='500'>
                                                    Select access groups(subjects)
                                                </Typography>
                                                <Typography
                                                    marginTop='-5px'
                                                    gutterBottom
                                                    variant='body2'
                                                    fontSize='12px'
                                                    color='textSecondary'
                                                >
                                                    Only selected groups can participate in the
                                                    discussion.
                                                </Typography>
                                                <Box
                                                    padding='5px 10px'
                                                    ref={subjectsInputContainerRef}
                                                    flexWrap='wrap'
                                                    display='flex'
                                                    alignItems='stretch'
                                                    gap='10px'
                                                    borderRadius='5px'
                                                    border='1px solid #d3d3d3'
                                                >
                                                    {values.access_groups.map( ( selSub, index ) => (
                                                        <Box
                                                            key={selSub.subject_id}
                                                            bgcolor={`${palette.primary.light}11`}
                                                            display='flex'
                                                            gap='10px'
                                                            borderRadius="5px"
                                                            width='fit-content'
                                                            alignItems='center'
                                                            padding='0 5px'
                                                            border={`1px solid ${palette.primary.light}55`}
                                                        >
                                                            <Box>
                                                                <Typography variant='subtitle2'>
                                                                    {selSub.subject_name}
                                                                </Typography>
                                                                <Typography fontSize='12px'>
                                                                    {selSub.subject_code}
                                                                </Typography>
                                                            </Box>
                                                            <IconButton
                                                                size='small'
                                                                onClick={() =>
                                                                    removeSubject(
                                                                        index,
                                                                        values.access_groups,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            >
                                                                <CancelOutlined fontSize='small' />
                                                            </IconButton>
                                                        </Box>
                                                    ) )}
                                                    <Button
                                                        onClick={( e ) =>
                                                            setSubjectsDialogAnchorEl(
                                                                subjectsInputContainerRef.current
                                                            )
                                                        }
                                                        variant='outlined'
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            color: '#666666',
                                                            border: '1px solid #666 !important',
                                                        }}
                                                        endIcon={
                                                            <ArrowDropDown sx={{ fontSize: '15px' }} />
                                                        }
                                                    >
                                                        {loadingSubjects ? (
                                                            <DataLoadingSpinner
                                                                padding='0'
                                                                waitingMessage='Loading subjects...'
                                                                size={14}
                                                            />
                                                        ) : (
                                                            `${values?.access_groups?.length > 0 ? "Add" : "Select"} subjects`
                                                        )}
                                                    </Button>
                                                </Box>
                                                <Typography variant='subtitle2' color='error'>
                                                    <ErrorMessage name='access_groups' />
                                                </Typography>
                                                <Popover
                                                    elevation={10}
                                                    anchorReference='anchorEl'
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}

                                                    slotProps={{ paper: { className: "custom-scrollbar", sx: { maxHeight: "320px", minHeight: "200px", minWidth: "300px", border: theme === 'dark' ? '1px solid #d3d3d3' : "none" } } }}
                                                    onClose={() => setSubjectsDialogAnchorEl( null )}
                                                    open={Boolean( subjectsDialogAnchorEl )}
                                                    anchorEl={subjectsDialogAnchorEl}
                                                >
                                                    <Box
                                                        padding='10px 10px'
                                                        sx={{ zIndex: 5 }}
                                                        position='sticky'
                                                        right='0'
                                                        bgcolor={palette.background.paper}
                                                        display="flex"
                                                        gap="10px"
                                                        alignItems="center"
                                                        left='0'
                                                        top='0'
                                                    >
                                                        <TextField
                                                            onChange={( e ) =>
                                                                setSearchParamValue(
                                                                    'subSearchText',
                                                                    e.target.value
                                                                )
                                                            }
                                                            size='small'
                                                            fullWidth
                                                            type='text'
                                                            placeholder='Search subjects...'
                                                        />
                                                        {UserStore.getUser['user_role'] === "STUDENT" && <Select size='small' variant='outlined' onChange={handleSemesterChange} value={selectedSem}>
                                                            {semesters.map( ( sem, i ) => (
                                                                <MenuItem dense sx={{ fontSize: "14px" }} value={sem} key={sem}>Semester {sem}</MenuItem>
                                                            ) )}
                                                        </Select>}
                                                    </Box>
                                                    <Box>
                                                        {UserStore.getUser.user_role === 'STUDENT' && <Box>
                                                            <Box
                                                                padding='0 0 10px 0'
                                                                gap='5px'
                                                                display='flex'
                                                                alignItems='center'
                                                            >
                                                                <Typography
                                                                    variant='subtitle2'
                                                                    color='secondary'
                                                                >
                                                                    Year - {selectedYear}
                                                                </Typography>
                                                                <Box
                                                                    bgcolor='GrayText'
                                                                    height='100%'
                                                                    borderRadius='50%'
                                                                    padding='2px'
                                                                ></Box>
                                                                <Typography
                                                                    color='GrayText'
                                                                    variant='subtitle2'
                                                                >
                                                                    Semester - {selectedSem}
                                                                </Typography>
                                                            </Box>
                                                            {UserStore.getUserSubjects[selectedYear] && UserStore.getUserSubjects[selectedYear][selectedSem] && UserStore.getUserSubjects[selectedYear][selectedSem].length > 0 &&
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    overflow="auto"
                                                                    maxHeight="300px"
                                                                    className="slim-custom-scrollbar"
                                                                >
                                                                    {UserStore.getUserSubjects[selectedYear][selectedSem]
                                                                        .filter( ( s ) =>
                                                                            s.subject_name
                                                                                ?.toLowerCase()
                                                                                ?.includes( subSearchText.toLowerCase() ) ||
                                                                            s.subject_code
                                                                                ?.toLowerCase()
                                                                                ?.includes( subSearchText.toLowerCase() ) )
                                                                        .map( ( sub ) => {
                                                                            return (
                                                                                // <FormControlLabel sx={{ "&:hover": { background: palette.rowHover }, borderBottom: "1px solid #d3d3d3", margin: 0, padding: 0 }} size="small" onChange={( e ) => toggleSubjectInclusion( e, sub, values.access_groups, setFieldValue )} control={<Checkbox sx={{ height: "40px", width: "40px", transform: "scale(0.9)" }} />} label={`${sub.subject_name}(${sub.subject_name_short})`} />
                                                                                <Box key={sub.subject_id}>
                                                                                    <Box
                                                                                        onClick={() =>
                                                                                            toggleSubjectInclusion(
                                                                                                sub,
                                                                                                values.access_groups,
                                                                                                setFieldValue
                                                                                            )
                                                                                        }
                                                                                        display='flex'
                                                                                        gap='10px'
                                                                                        sx={{
                                                                                            cursor: 'pointer',
                                                                                            '&:hover': {
                                                                                                background:
                                                                                                    palette.rowHover,
                                                                                            },
                                                                                        }}
                                                                                        padding='2px 10px'
                                                                                        alignItems='center'
                                                                                        key={sub.subject_id}
                                                                                        borderBottom='1px solid #d3d3d3'
                                                                                    >
                                                                                        {!selectedSubjectsIds.includes(
                                                                                            sub.subject_id
                                                                                        ) ? (
                                                                                            <CheckBoxOutlineBlank
                                                                                                sx={{
                                                                                                    color:
                                                                                                        'darkgrey',
                                                                                                    '&:hover': {
                                                                                                        color:
                                                                                                            'GrayText',
                                                                                                    },
                                                                                                }}
                                                                                                fontSize='small'
                                                                                            />
                                                                                        ) : (
                                                                                            <CheckBox fontSize='small' />
                                                                                        )}
                                                                                        <Typography variant='subtitle2'>{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            )
                                                                        } )}
                                                                </Box>
                                                            }
                                                            {UserStore.getUserSubjects[selectedYear] && UserStore.getUserSubjects[selectedYear][selectedSem] && UserStore.getUserSubjects[selectedYear][selectedSem]?.filter( ( s ) =>
                                                                s.subject_name
                                                                    ?.toLowerCase()
                                                                    ?.includes( subSearchText.toLowerCase() ) ||
                                                                s.subject_code
                                                                    ?.toLowerCase()
                                                                    ?.includes( subSearchText.toLowerCase() ) )?.length === 0 && <Typography variant="subtitle2" color="textSecondary">
                                                                    No subject for selected Semester
                                                                </Typography>}
                                                        </Box>}
                                                        {UserStore.getUser.user_role !== 'STUDENT' &&
                                                            subjects.length > 0 &&
                                                            subjects
                                                                .filter(
                                                                    ( s ) =>
                                                                        s.subject_name
                                                                            ?.toLowerCase()
                                                                            ?.includes( subSearchText.toLowerCase() ) ||
                                                                        s.subject_code
                                                                            ?.toLowerCase()
                                                                            ?.includes( subSearchText.toLowerCase() )
                                                                )
                                                                .map( ( sub ) => (
                                                                    <Box
                                                                        onClick={() =>
                                                                            toggleSubjectInclusion(
                                                                                sub,
                                                                                values.access_groups,
                                                                                setFieldValue
                                                                            )
                                                                        }
                                                                        display='flex'
                                                                        gap='10px'
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                background: palette.rowHover,
                                                                            },
                                                                        }}
                                                                        padding='2px 10px'
                                                                        alignItems='center'
                                                                        key={sub.subject_id}
                                                                        borderBottom='1px solid #d3d3d3'
                                                                    >
                                                                        {!selectedSubjectsIds.includes(
                                                                            sub.subject_id
                                                                        ) ? (
                                                                            <CheckBoxOutlineBlank
                                                                                sx={{
                                                                                    color: 'darkgrey',
                                                                                    '&:hover': { color: 'GrayText' },
                                                                                }}
                                                                                fontSize='small'
                                                                            />
                                                                        ) : (
                                                                            <CheckBox fontSize='small' />
                                                                        )}
                                                                        <Typography variant='subtitle2'>{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                                                    </Box>
                                                                ) )}
                                                    </Box>
                                                </Popover>
                                            </Box>
                                        )}
                                        <Box
                                            display='flex'
                                            gap='10px'
                                        >
                                            <Button
                                                disabled={postingDiscussion}
                                                type='submit'
                                                sx={{ textTransform: 'capitalize' }}
                                                variant='contained'
                                                disableElevation
                                                startIcon={
                                                    postingDiscussion ? (
                                                        <CircularProgress size={14} />
                                                    ) : (
                                                        Icons.default.AddIcon
                                                    )
                                                }
                                                color='primary'
                                            >
                                                {postingDiscussion
                                                    ? 'Creating discussion...'
                                                    : 'Create Discussion'}
                                            </Button>
                                            <Button
                                                disabled={postingDiscussion}
                                                onClick={() => handleClose( resetForm )}
                                                disableElevation
                                                sx={{ textTransform: 'capitalize' }}
                                                variant='contained'
                                                color='error'
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>

                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CreateDiscussionDialog


// const handleAccessTypeChange = async ( e, setFieldValue ) => {
//     const val = e.target.value
//     setFieldValue( 'access', val )
//     if ( val === 'global' ) {
//         setFieldValue( 'access_groups', [
//             {
//                 subject_name: 'all',
//                 subject_code: 'ALL',
//                 subject_name_short: 'all',
//                 subject_id: '-1',
//             },
//         ] )
//     } else {
//         setFieldValue( 'access_groups', [] )
//         if ( Object.keys( subjects ).length === 0 ) await getSubjects()
//     }
//     setSelectedSubjectsIds( [] )
// }