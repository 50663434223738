import { Box, IconButton, Tooltip, Typography, Paper, useTheme, Button, ButtonGroup } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import ListView from '../../components/exams-components/ListView'
import GridView from '../../components/exams-components/GridView'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GridViewIcon from '@mui/icons-material/GridView'
import { useUserStore } from '../../store/user.store'
import { useExamStore } from '../../store/exam.store'
import React, { useCallback, useEffect, useState } from 'react'
import noExamImg from '../../assets/noexams.svg'
import { Icons } from '../../utils/utilities'
import { observer } from 'mobx-react'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'

const Exams = observer( () => {

    const [searchParams, setSearchParams] = useSearchParams( { selectedExam: "", mode: "manage", searchText: "", instituteCode: "0", view: window.localStorage.getItem( 'aliveExamsViewType' ) ? window.localStorage.getItem( 'aliveExamsViewType' ) : "grid" } )
    const mode = searchParams.get( 'mode' ) || "manage"
    const searchText = searchParams.get( "searchText" ) || ""
    const view = searchParams.get( "view" ) || "grid"

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const ExamStore = useExamStore()

    const [inProgress, setInProgresss] = useState( true )

    const { palette } = useTheme()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const getExams = useCallback( async () => {
        if ( ExamStore.getErrors.exams === true )
            await ExamStore.fetchExams()
        setInProgresss( false )
    }, [ExamStore] )

    useEffect( () => {
        getExams()
    }, [getExams] )

    return (
        <Box flexGrow={1} padding="20px" overflow="auto">

            <Paper sx={{ minHeight: inProgress ? "initial" : "450px", display: "flex", flexDirection: "column", overflow: "hidden" }} >
                <Box position="relative" sx={{
                    background: `url('/imgs/exams-header-bg.jpg')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                    <Box height="100%" position="absolute" sx={{ zIndex: 1, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}>
                    </Box>
                    <Box position="relative" sx={{ zIndex: "10" }} >

                        <Box padding="20px" gap="20px" display="flex" flexDirection="column" justifyContent="space-between">
                            <Box>
                                <Typography variant='h5' color="white">Exams</Typography>
                                <Typography variant='body2' color="white">{( !mode || mode === "manage" ) ? "View all your exams" : UserStore.getUser.user_role === "PRINCIPAL" ? `Audit ${UserStore.getUser.user_org_code} Exams` : UserStore.getUser.user_role === "HOD" ? `Audit ${UserStore.getUser.user_dept_code} exams` : "Audit all the exams across institutes"}</Typography>
                            </Box>
                            {!inProgress && !ExamStore.getErrors.exams && <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center">
                                <CustomSearchBar onChange={( e ) => setSearchParamValue( 'searchText', e.target.value )} value={searchText} />
                                <Box display="flex" gap="20px" alignItems="stretch">
                                    <ButtonGroup sx={{ background: palette.contentBg, borderRadius: "10px", }}>
                                        <Tooltip title="List view">
                                            <IconButton
                                                color={view === 'list' ? "primary" : "greyed"}
                                                onClick={() => {
                                                    setSearchParamValue( 'view', 'list' )
                                                    window.localStorage.setItem( 'aliveExamsViewType', 'list' )
                                                }}
                                            >
                                                <FormatListBulletedIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Grid view">
                                            <IconButton color={view === 'grid' ? "primary" : "greyed"}
                                                onClick={() => {
                                                    setSearchParamValue( 'view', 'grid' )
                                                    window.localStorage.setItem( 'aliveExamsViewType', 'grid' )
                                                }}
                                            >
                                                <GridViewIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonGroup>

                                    {UserStore.getUser.user_role !== 'STUDENT' && ( !mode || mode === "manage" ) &&
                                        <Button sx={{ textTransform: "capitalize" }} disableElevation onClick={() => navigate( `/faculty/addexam` )} variant='contained' startIcon={Icons.default.AddIcon}>Create</Button>
                                    }
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
                {!inProgress && !ExamStore.getErrors.exams && ( !mode || mode === "manage" ) && ExamStore.getExams.length !== 0 && <Box marginTop="20px" flexGrow={1} display="flex" flexDirection="column" padding="20px" overflow="auto" paddingTop="0">
                    {view === 'grid' && <GridView exams={ExamStore.getExams} userRole={UserStore.getUser.user_role} />}
                    {view === 'list' && <ListView exams={ExamStore.getExams} userRole={UserStore.getUser.user_role} />}
                </Box>}

                {inProgress && <DataLoadingSpinner waitingMessage="Loading exams..." />}
                {
                    !inProgress && !ExamStore.getErrors.exams && ( ExamStore.getExams.length === 0 ) && <Box flexGrow={1} borderRadius="5px" flexDirection="column" display="flex" justifyContent="center" alignItems="center" padding="20px" bgcolor={palette.contentBg} margin="10px" gap="20px" color="textSecondary" variant='subtitle2'>
                        <img src={noExamImg} width="200px" alt="no exams!" />
                        <Typography variant='subtitle2'>No exams to show!</Typography>
                        {UserStore.getUser.user_role !== 'STUDENT' ? ( !mode || mode === "manage" ) ? <Button sx={{ textTransform: "capitalize" }} variant='outlined' startIcon={Icons.default.AddIcon} onClick={() => navigate( `/faculty/addexam` )} >Create One</Button> : "" : ""}
                    </Box>
                }

                {!inProgress && ExamStore.getErrors.exams && <Box flexGrow={1} display="flex" alignItems="center">
                    <TryAgainMessageBlock styles={{ margin: "20px", flexGrow: 1 }} err='Something went wrong while fetching exams.' parent='component' getData={getExams} loading={inProgress} />
                </Box>}

            </Paper >
        </Box >
    )
} )

export default Exams
