import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
// import api from '../service/api'
import NotificationStore from "./notification.store"
import React from 'react'

import defaultThumbs1 from '../assets/quiz-default-1.svg'
import defaultThumbs2 from '../assets/quiz-default-2.svg'
import defaultThumbs3 from '../assets/quiz-default-3.svg'
import api from '../service/api'


class QuizAndQuestionbankStore {
    quiz
    quizes
    defaultThumbs
    questionBanks
    quizInstance
    quizAttemptQuestions
    errors

    constructor () {
        this.quiz = { details: {}, questions: [] }
        this.quizzes = []
        this.defaultThumbs = {
            "default-1": defaultThumbs1,
            "default-2": defaultThumbs2,
            "default-3": defaultThumbs3,
        }
        this.errors = { quizzes: true, questionBanks: true }
        this.answeredCount = 0
        this.questionBanks = []
        this.questionBank = null
        this.quizInstance = null
        this.quizAttemptQuestions = []
        this.attemptingAnswers = {}

        makeObservable( this, {
            quiz: observable,
            quizzes: observable,
            errors: observable,
            quizInstance: observable,
            questionBank: observable,
            answeredCount: observable,
            defaultThumbs: observable,
            questionBanks: observable,
            attemptingAnswers: observable,
            quizAttemptQuestions: observable,

            fetchQuiz: action,
            createQuiz: action,
            fetchQuizes: action,
            addQuestions: action,
            deleteTopic: action,
            deleteQuestion: action,
            addQuestionBank: action,
            updateQuestion: action,
            fetchQuestionBank: action,
            submitQuizAnswers: action,
            createQuizInstance: action,
            deleteQuestionBank: action,
            fetchQuestionBanks: action,
            setAttemptingAnswers: action,
            rescheduleQuizInstance: action,
            fetchQuizInstanceForStudent: action,
            fetchQuizQuestionsForAttempt: action,

            getQuiz: computed,
            getQuizes: computed,
            getErrors: computed,
            getQuizInstance: computed,
            getQuestionBank: computed,
            getAnsweredCount: computed,
            getDefaultThumbs: computed,
            getQuestionBanks: computed,
            getAttemptingAnswers: computed,
            getQuizAttemptQuestions: computed,
        } )
    }

    get getQuiz() {
        return toJS( this.quiz )
    }

    get getQuizes() {
        return toJS( this.quizzes )
    }

    get getDefaultThumbs() {
        return toJS( this.defaultThumbs )
    }

    get getQuestionBanks() {
        return toJS( this.questionBanks )
    }

    get getQuestionBank() {
        return toJS( this.questionBank )
    }

    get getQuizInstance() {
        return toJS( this.quizInstance )
    }

    get getQuizAttemptQuestions() {
        return toJS( this.quizAttemptQuestions )
    }

    get getAttemptingAnswers() {
        return toJS( this.attemptingAnswers )
    }

    get getAnsweredCount() {
        return toJS( this.answeredCount )
    }

    get getErrors() {
        return toJS( this.errors )
    }

    async addQuestionBank( values, subject_id = undefined ) {
        try {
            const { data } = await api.quiz.addQuestionBank( values )
            this.fetchQuestionBanks( subject_id )
            return data
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating the question bank" )
            return false
        }
    }

    async createTopic( id, values ) {
        try {
            await api.quiz.createTopic( id, values )
            this.fetchQuestionBank( id )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating the topic" )
            return false
        }
    }

    async deleteQuestionBank( bankId ) {
        try {
            await api.quiz.deleteQuestionBank( bankId )
            NotificationStore.setNotification( true, "Question bank deleted successfully!" )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while deleting the question bank" )
            return false
        }
    }

    async deleteTopic( bankId, id ) {
        try {
            await api.quiz.deleteTopic( bankId, id )
            this.fetchQuestionBank( bankId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while deleting the topic" )
            return false
        }
    }

    async deleteQuestion( bankId, topicId, id ) {
        try {
            await api.quiz.deleteQuestion( bankId, topicId, id )
            this.fetchQuestionBank( bankId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while deleting the question" )
            return false
        }
    }

    async fetchQuestionBanks( subject_id ) {
        try {
            const { data } = await api.quiz.fetchQuestionBanks( subject_id ? { subject_id } : {} )
            runInAction( () => {
                this.questionBanks = ( data.success && data.data ) || []
                this.errors.questionBanks = false
            } )
        } catch ( err ) {
            runInAction( () => {
                this.errors.questionBanks = true
            } )
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching the question banks" )
        }
    }

    async fetchQuestionBank( id ) {
        try {
            let { data } = await api.quiz.fetchQuestionBank( id )

            if ( data.success ) {
                const formatedQuestions = {}
                const questions = ( data?.data?.questions || [] )
                if ( questions.length > 0 ) {
                    for ( let k = 0; k < questions.length; k++ ) {
                        const question = questions[k]
                        if ( formatedQuestions[question.topic_id] )
                            formatedQuestions[question.topic_id].push( question )
                        else
                            formatedQuestions[question.topic_id] = [question]
                    }
                }

                data.data['formattedQuestions'] = formatedQuestions
                runInAction( () => {
                    this.questionBank = data.data || null
                } )
            }

        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching the question bank" )
            return { code: err?.response?.status || 500, message: err?.response?.data?.message }
        }
    }

    async addQuestions( bankId, topicId, questions ) {
        try {
            const { data } = await api.quiz.addQuestions( bankId, topicId, { questions } )
            this.fetchQuestionBank( bankId )
            return { data: data.data, message: data.message }
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while adding the questions to bank" )
            return false
        }
    }

    async updateQuestion( bankId, topicId, questionId, updatedQuestion ) {
        try {
            const { data } = await api.quiz.updateQuestionBankQuestion( bankId, topicId, questionId, updatedQuestion )
            runInAction( () => {
                const topicQuestions = this.questionBank.formattedQuestions[updatedQuestion.topic_id] || []

                const updatedQuestionIndex = topicQuestions?.findIndex( q => q.id === questionId )
                if ( updatedQuestionIndex > -1 )
                    topicQuestions[updatedQuestionIndex] = data.data
                else {
                    const prevTopicQuestions = this.questionBank.formattedQuestions[topicId]
                    const updatedQuestionIndex = prevTopicQuestions.findIndex( q => q.id === questionId )
                    prevTopicQuestions?.splice( updatedQuestionIndex, 1 )
                    topicQuestions?.push( data.data )
                }
            } )

            return data.data
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while adding the questions to bank" )
            return false
        }
    }

    async createQuiz( values, subject, refreshAll = false ) {
        try {
            const { data: { data } } = await api.quiz.createQuiz( values )
            this.fetchQuizes( refreshAll ? {} : { subject_id: subject?.subject_id } )
            return { success: true, data }
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching quizes" )
            return { success: false }
        }
    }

    async fetchQuizes( params ) {
        try {
            const { data } = await api.quiz.fetchQuizes( params )
            runInAction( () => {
                this.quizzes = data.success ? data.data : []
                this.errors.quizzes = false
            } )
            return true
        } catch ( err ) {
            runInAction( () => {
                this.errors.quizzes = true
            } )
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching quizes" )
        }
    }

    async fetchQuiz( id ) {
        try {
            const { data } = await api.quiz.fetchQuiz( id )
            runInAction( () => {
                this.quiz = data.success ? data.data : null
            } )
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching quizes" )
            return err?.response?.status || 500
        }
    }

    async createQuizInstance( id, values ) {
        try {
            const { data: { data } } = await api.quiz.createQuizInstance( id, values )
            this.fetchQuiz( id )
            return { success: true, data: data }
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while adding instance" )
        }
    }

    async rescheduleQuizInstance( id, instance_id, values ) {
        try {
            await api.quiz.rescheduleQuizInstance( id, instance_id, values )
            this.fetchQuiz( id )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while updating instance" )
        }
    }

    async fetchQuizInstanceForStudent( quizId, InstanceId ) {
        try {
            const { data } = await api.quiz.fetchQuizInstanceForStudent( quizId, InstanceId )
            runInAction( () => {
                this.quizInstance = data.success ? data.data : null
            } )
            return { success: true }
        } catch ( err ) {
            console.log( err )
            return err?.response ? { message: err?.response?.data?.message || "Something went wrong while fetching your quiz!", success: false, code: err.response?.status } : { message: err?.response?.data?.message || "Something went wrong while fetching your quiz!", success: false, code: null }
        }
    }
    async fetchQuizQuestionsForAttempt( quizId, InstanceId, attemptId ) {
        try {
            const { data } = await api.quiz.fetchQuizQuestionsForAttempt( quizId, InstanceId, attemptId )
            runInAction( () => {
                this.quizAttemptQuestions = data.success ? data.data : null
            } )
            if ( +window.localStorage.getItem( 'current_quiz_id' ) !== +InstanceId ) {
                localStorage.setItem( 'current_quiz_id', InstanceId )
                localStorage.setItem( 'quiz_local_answer', '{}' )
            } else {
                this.setAttemptingAnswers( JSON.parse( localStorage.getItem( 'quiz_local_answer' ) ) || {} )
            }
            return { success: true }
        } catch ( err ) {
            console.log( err )
            // NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching the quiz!" )
            return err?.response ? { message: err?.response?.data?.message, success: false, code: err.response?.status } : { message: "Something went wrong! please try again", success: false, code: null }
        }
    }

    setAttemptingAnswers( answers ) {
        window.localStorage.setItem( 'quiz_local_answer', JSON.stringify( answers ) )
        const answeredQuestions = Object.keys( answers )
        let answersCount = 0
        for ( let i = 0; i < answeredQuestions.length; i++ ) {
            const ans = answeredQuestions[i]
            if ( !answers[ans] ) continue
            if ( typeof answers[ans] !== 'object' )
                answersCount += 1
            else {
                if ( ans?.length > 0 )
                    answersCount += 1
            }

        }
        runInAction( () => {
            this.attemptingAnswers = answers || {}
            this.answeredCount = answersCount
        } )
    }

    async submitQuizAnswers( quizId, InstanceId, attemptId ) {
        try {
            await api.quiz.submitAnswers( quizId, InstanceId, attemptId, { answers: this.attemptingAnswers } )
            this.setAttemptingAnswers( {} )
            localStorage.setItem( 'current_quiz_id', '' )
            localStorage.setItem( 'quiz_local_answer', '{}' )
            NotificationStore.setNotification( true, "Submitted your answers succcessfully" )
            return true
        } catch ( err ) {
            console.log( err )
            return false
        }
    }

}


const QuizAndQuestionbankStoreInstance = new QuizAndQuestionbankStore()

export default QuizAndQuestionbankStoreInstance

const QuizAndQuestionbankStoreContext = React.createContext()

export const QuizAndQuestionbankStoreProvider = ( { children, store } ) => {
    return (
        <QuizAndQuestionbankStoreContext.Provider value={store}>{children}</QuizAndQuestionbankStoreContext.Provider>
    )
}

export const useQuizAndQuestionbankStore = () => React.useContext( QuizAndQuestionbankStoreContext )