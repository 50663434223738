import { Box, Button, CircularProgress, Collapse, Dialog, FormControl, IconButton, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { useUserStore } from '../../../store/user.store'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Icons, latexDelimiters, leadingZeroFormatter, secondsToHMS, showDateInIndianDateFormat } from '../../../utils/utilities'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { CheckBox, CheckBoxOutlineBlank, RefreshOutlined } from '@mui/icons-material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import ManageNewQuestionBank from './class-quizzes/ManageNewQuestionBank'
import CustomSwitch from '../../common/CustomSwitch'
import Latex from 'react-latex-next'

const CreateQuizDialog = observer( ( { fromComponent = 'subject', room = null, state, setState, setFromClassDialogState, subject } ) => {

    const [classes, setClasses] = useState( [] )
    const [adding, setAdding] = useState( false )
    const [loading, setLoading] = useState( true )
    const [isFetchingClasses, setIsFetchingClasses] = useState( false )
    const [academicYearId, setAcademicYearId] = useState( "-" )
    const [questionBankMode, setQuestionBankMode] = useState( 'select' )
    const [newBankId, setNewBankId] = useState( null )
    const [classFetchError, setClassFetchError] = useState( false )
    const [fetchingSubjects, setFetchingSubjects] = useState( false )
    const [isLoadingBatches, setIsLoadingBatches] = useState( false )
    const [hasNewQuestionBankSaved, setHasNewQuestionBankSaved] = useState( true )
    const [associateWithClass] = useState( true )
    const [loadingQuestionbank, setLoadingQuestionbank] = useState( false )

    const UserStore = useUserStore()
    const QuizAndQuestionBankStore = useQuizAndQuestionbankStore()

    const { palette, border } = useTheme()

    const newQuestionBankNameInputRef = useRef()

    const initialValues = {
        name: '',
        // thumbnail: `default-${Math.ceil( Math.random() * Object.keys( QuizAndQuestionBankStore.getDefaultThumbs ).length )}`,
        subject: room ? UserStore.getUserSubjects?.find( s => room?.subject_id === s?.subject_id ) : subject || '-',
        questionbank: '-',
        questionbank_topics: [],
        questionbank_topics_max_question: [],
        use_selected_topic_question: false,
        quiz_questions: [],
        type: 'scheduled_quiz',
        end_time: room ? room.eTime : '00:00',
        start_time: room ? room.sTime : '00:00',
        access_group: room ? room.batch_short_name !== "NA" ?
            { course_name_short: room.course_name_short, current_sem: room.current_sem, current_year: room.current_year, id: room.batch_assignment_id, batch_short_name: room.batch_short_name, batch_assignment_id: room.batch_assignment_id, branch_name: room.branch_name, branch_id: room.branch_id } :
            { course_branch_short_name: room.course_branch_short_name, current_sem: room.current_sem, current_year: room.current_year, id: `${room.section_assignment_id}-${room.section_short_name}`, section_assignment_id: room.section_assignment_id, section_short_name: room.section_short_name, branch_name: room.branch_name, branch_id: room.branch_id } : '-',
        quiz_duration: '',
        start_date: room ? new Date( room.selected_date ) : new Date(),
        maximum_attempts: 1,
        room_id: room ? room.time_table_id : undefined
    }

    const validationSchema = Yup.object().shape( {
        name: Yup.string().required( "Name is required." ),
        subject: Yup.mixed().notOneOf( ["-"], "Specify the quiz subject" ).required( "Subject is required." ),
        access_group: Yup.mixed().test( "CHAK_FOR_ACCESS_GROUP", "Access groups are required.", ( val, { parent } ) => {
            if ( parent.type !== "scheduled-quiz" ) return true
            if ( !val || val === "-" ) return false
            return true
        } ),
        questionbank: Yup.mixed().notOneOf( ["-"], "Specify the quiz's question bank" ).required( "Question bank is required." ).test( "ASK_TO_SELECT_QB_IF_NEW_QB_IS_DELETED", "Specify the quiz's question bank", ( val ) => {
            return hasNewQuestionBankSaved
        } ),
        questionbank_topics: Yup.mixed().test( "ATLEAST_ONE_TOPIC", "Please select atleast one topic for the quiz", ( val ) => {
            if ( !val || val.length === 0 )
                return false
            return true
        } ),
        quiz_questions: Yup.mixed().test( "ATLEAST_ONE_QUESTION", "Please select atleast one question for the quiz", ( val ) => {
            if ( !val || val.length === 0 )
                return false
            return true
        } ),
        start_time: Yup.string().test( "TEST_FOR_START_TIME", "For a scheduled quiz a valid start time is required.", ( val, { parent } ) => {
            try {
                if ( parent.type !== "scheduled-quiz" ) return true
                if ( !val || val.length === 0 || !val.match( /^(\d\d:\d\d)$/ ) ) return false
                return true
            } catch ( err ) {
                return true
            }
        } ),
        end_time: Yup.string().test( "TEST_FOR_END_TIME", "For a scheduled quiz a valid end time is required.", ( val, { parent } ) => {
            if ( parent.type !== "scheduled-quiz" ) return true
            if ( !val || val.length === 0 || !val.match( /^(\d\d:\d\d)$/ ) ) return false
            return true
        } ).test( "TEST_FOR_VALID_END_TIME", "For a scheduled quiz a valid end time must be greater than start time.", ( val, { parent } ) => {
            try {
                const start = new Date( `2024-01-01 ${parent.start_time}` )
                const end = new Date( `2024-01-01 ${val}` )
                if ( end <= start ) return false
                return true
            } catch ( err ) {
                return true
            }
        } ),
        quiz_duration: Yup.string().test( "CHECK_FOR_QUIZ_DURATION", "Please enter a valid duration. (format: HH:MM)", ( val, { parent } ) => {
            const splitted = val?.split( ":" )
            if ( !val ) return true
            if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] >= 24 ) )
                return false
            return true
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Invalid duration or duration exceeds the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const dateObject = new Date( parent.start_date )
                const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth()}-${dateObject.getDate()}`
                const selectedDuration = ( new Date( `${dateStr} ${parent.end_time}` ).getTime() - new Date( `${dateStr} ${parent.start_time}` ).getTime() ) / 1000
                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 ) ) {
                    return false
                } else {
                    if ( val === "00:00" )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        ),
        room_id: Yup.string( "To associate with a class, A class should be selected!" ).test( "FOR_ROOM_ID", "To associate with a class, A class should be selected!", val => {
            if ( !associateWithClass ) return true
            if ( val ) return true
            return false
        } ),
    } )

    const handleClose = () => {
        setState( false )
        if ( setFromClassDialogState )
            setFromClassDialogState( null )
    }

    const handleQuestionbankChange = async ( val, setValue ) => {
        const qb = QuizAndQuestionBankStore.getQuestionBanks.find( q => q.id === val )
        setLoadingQuestionbank( true )
        await QuizAndQuestionBankStore.fetchQuestionBank( qb.id )
        setValue( 'questionbank', qb )
        setValue( 'questionbank_topics', [] )
        setLoadingQuestionbank( false )
        setHasNewQuestionBankSaved( true )
    }

    const handleAcademicYearChange = async ( e, setValue ) => {
        setIsLoadingBatches( true )
        await UserStore.fetchUserBatchesAndSection( e.target.value )
        setAcademicYearId( e.target.value )
        setValue( 'access_group', '-' )
        setIsLoadingBatches( false )
    }

    const refreshYears = async () => {
        await UserStore.fetchUserAcademicYears( true )
    }

    const refreshBatches = async () => {
        if ( academicYearId !== "-" )
            await UserStore.fetchUserBatchesAndSection( academicYearId, true )
    }

    const handleSubjectChange = async ( e, setValue, date ) => {
        setValue( 'subject', UserStore.getUserSubjects.find( s => s?.subject_id === e.target.value ) )
        setValue( 'questionbank', "-" )
        setIsFetchingClasses( true )
        try {
            let reqDate = new Date( date )
            reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

            let { data: { data } } = await api.getClassesByDate( reqDate )
            setClasses( ( data || [] ).filter( room => room?.subject_id === e.target.value ) )
            setClassFetchError( false )
        } catch ( err ) {
            setClassFetchError( true )
            toast( "Error occured while fetching classes" )
        } finally {
            setIsFetchingClasses( false )
        }
    }

    const fetchClassesByDate = async ( subject_id, date ) => {
        setIsFetchingClasses( true )
        try {
            let reqDate = new Date( date )
            reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

            let { data: { data } } = await api.getClassesByDate( reqDate )
            setClasses( ( data || [] ).filter( room => room?.subject_id === subject_id ) )
            setClassFetchError( false )
        } catch ( err ) {
            setClassFetchError( true )
            toast( "Error occured while fetching classes" )
        } finally {
            setIsFetchingClasses( false )
        }
    }

    const openAddNewQuestionBankDialog = ( setFieldValue ) => {
        setQuestionBankMode( 'addnew' )
        setFieldValue( 'questionbank', "-" )
        setFieldValue( 'quiz_questions', [] )
        setFieldValue( 'questionbank_topics', [] )
        setFieldValue( 'questionbank_topics_max_question', [] )
    }


    const addNewQuestionBank = async ( selectedSubject, setFieldValue ) => {
        if ( newQuestionBankNameInputRef.current.value && newQuestionBankNameInputRef?.current?.value?.trim().length > 0 ) {
            if ( !( subject || UserStore.getUserSubjects?.find( s => room?.subject_id === s?.subject_id ) )?.subject_id && ( !selectedSubject || selectedSubject === "-" ) )
                return toast( "Please specify the subject first!" )
            const success = await QuizAndQuestionBankStore.addQuestionBank( { name: newQuestionBankNameInputRef.current.value, subject: subject || UserStore.getUserSubjects?.find( s => room?.subject_id === s?.subject_id ) || selectedSubject }, ( subject || UserStore.getUserSubjects?.find( s => room?.subject_id === s?.subject_id ) )?.subject_id )
            if ( success ) {
                setNewBankId( success.data )
                await QuizAndQuestionBankStore.fetchQuestionBanks()
                const newQuiz = QuizAndQuestionBankStore.getQuestionBanks?.find( qb => qb.id = success.data )
                setFieldValue( "questionbank", newQuiz )
                setHasNewQuestionBankSaved( true )
                setQuestionBankMode( 'managequestions' )
            }
        }
    }

    const handleTimeChange = ( name, val, setValue ) => {
        const time = new Date( val ).toLocaleTimeString( 'en-IN', { hourCycle: "h24", hour: "2-digit", minute: "2-digit", } )
        setValue( name, time )
    }

    const handleMaxQuestionsChange = ( val, prev, setValue, index ) => {
        let newVals = [...prev]
        newVals[index] = val
        setValue( 'questionbank_topics_max_question', newVals )
    }

    const toggleQuestionSelection = ( topicPos, pos, newVal, setFieldValue, prev ) => {
        const newIDsList = [...prev.quiz_questions]
        let newVals = [...prev.questionbank_topics_max_question]

        if ( pos > -1 ) {
            newIDsList.splice( pos, 1 )
            if ( newVals[topicPos] > 0 )
                newVals[topicPos] = newVals[topicPos] - 1
            setFieldValue( 'quiz_questions', newIDsList )
        } else {
            newIDsList.push( newVal )
            newVals[topicPos] = +newVals[topicPos] + 1
            setFieldValue( 'quiz_questions', newIDsList )
        }
        setFieldValue( 'questionbank_topics_max_question', newVals )
    }

    // const toggleClassAssociation = async ( e, date, setField, values ) => {
    //     const { checked } = e.target
    //     let reqDate = new Date( date )
    //     reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`
    //     if ( checked ) {
    //         if ( values.subject === "-" )
    //             return toast( "Please select a subject!" )
    //         setIsFetchingClasses( true )
    //         try {
    //             let { data: { data } } = await api.getClassesByDate( reqDate )
    //             setClasses( ( data || [] ).filter( room => room?.subject_id === values?.subject?.subject_id ) )
    //         } catch ( err ) {
    //             toast( "Error occured while fetching classes" )
    //         } finally {
    //             setIsFetchingClasses( false )
    //         }
    //     } else {
    //         setField( 'room_id', "" )
    //         setField( 'access_group', "-" )
    //         setClasses( [] )
    //     }
    //     setAssociateWithClass( checked )
    // }

    const toggleSelectedQuestionsUsage = ( val, setValue ) => {
        setValue( 'use_selected_topic_question', val )
        setValue( 'quiz_questions', [] )
        setValue( 'questionbank_topics', [] )
        setValue( 'questionbank_topics_max_question', [] )
    }

    const toggleTopic = ( val, setValue, state, prev, totalQuestions ) => {
        let newVal = [...prev.questionbank_topics]
        let newMaxQuestions = [...prev.questionbank_topics_max_question]
        let newQuestionList = [...prev.quiz_questions]
        if ( state ) {
            let index
            for ( let i = 0; i < prev.questionbank_topics.length; i++ ) {
                const topic = prev.questionbank_topics[i]
                if ( topic.id === val.id ) {
                    index = i
                    break
                }
            }
            newQuestionList = newQuestionList.filter( q => q.topic_id !== val.id )
            newVal.splice( index, 1 )
            newMaxQuestions.splice( index, 1 )
        } else {
            if ( !prev.use_selected_topic_question ) {
                newQuestionList.push( ...QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[val.id] )
            }
            newVal.push( val )
            newMaxQuestions.push( !prev.use_selected_topic_question ? Math.ceil( totalQuestions / 2 ) : 0 )
        }
        setValue( 'quiz_questions', newQuestionList )
        setValue( 'questionbank_topics', newVal )
        setValue( 'questionbank_topics_max_question', newMaxQuestions )
    }

    const handleAccessGroupChange = ( val, setValue ) => {
        let access = ( UserStore.getUserBatchesAndSection['batches'] || [] ).find( i => i.batch_assignment_id === val )
        if ( !access ) {
            access = ( UserStore.getUserBatchesAndSection["sections"] || [] ).find( i => i.section_assignment_id + "-" + i.section_short_name === val )
        }
        access["id"] = val
        setValue( 'access_group', access )
    }

    const handleRoomChange = ( e, setField ) => {
        setField( 'room_id', e.target.value )
        const room = classes.find( r => r.time_table_id === e.target.value )
        setField( 'end_time', room.eTime )
        setField( 'start_time', room.sTime )
        setField( 'access_group', room.batch_short_name !== "NA" ?
            { course_name_short: room.course_name_short, current_sem: room.current_sem, current_year: room.current_year, id: room.batch_assignment_id, batch_short_name: room.batch_short_name, batch_assignment_id: room.batch_assignment_id, branch_name: room.branch_name, branch_id: room.branch_id } :
            { course_branch_short_name: room.course_branch_short_name, current_sem: room.current_sem, current_year: room.current_year, id: `${room.section_assignment_id}-${room.section_short_name}`, section_assignment_id: room.section_assignment_id, section_short_name: room.section_short_name, branch_name: room.branch_name, branch_id: room.branch_id } )

    }

    const handleStartDateChange = async ( val, setFieldValue, selectedSubject ) => {
        setFieldValue( 'start_date', val )
        if ( associateWithClass ) {
            setIsFetchingClasses( true )
            try {
                let reqDate = new Date( val )
                reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

                let { data: { data } } = await api.getClassesByDate( reqDate )
                setClasses( ( data || [] ).filter( room => room?.subject_id === selectedSubject?.subject_id ) )
                setClassFetchError( false )
            } catch ( err ) {
                setClassFetchError( true )
                toast( "Error occured while fetching classes" )
            } finally {
                setIsFetchingClasses( false )
            }
        }
    }

    const addQuiz = async ( values, { resetForm, setFieldValue } ) => {
        setAdding( true )

        const formData = new FormData()
        for ( const key in values ) {
            if ( key === "questionbank" )
                formData.append( key, values[key].id )
            else if ( ["questionbank_topics_max_question", "subject", "access_group"].includes( key ) )
                formData.append( key, JSON.stringify( values[key] ) )
            else if ( ["start_time", "end_time"].includes( key ) ) {
                if ( typeof values[key] !== 'string' || !values[key].match( /\d\d:\d\d/ ) ) {
                    handleTimeChange( key, values[key], setFieldValue )
                    formData.append( key, values[key] )
                } else
                    formData.append( key, values[key] )

            } else if ( key === 'questionbank_topics' ) {
                let topics = values[key]
                let validTopics = []
                for ( const topic of topics ) {
                    if ( values.quiz_questions?.find( q => q.topic_id === topic.id ) )
                        validTopics.push( topic )
                }
                formData.append( key, JSON.stringify( validTopics ) )
            } else if ( key === 'quiz_questions' ) {
                const qList = values[key].map( q => q.id )
                formData.append( key, JSON.stringify( qList ) )
            } else if ( key === 'start_date' ) {
                const date = new Date( values[key] )
                formData.append( key, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` )
            } else if ( values[key] !== "-" )
                formData.append( key, values[key] )
        }
        const { success } = await QuizAndQuestionBankStore.createQuiz( formData, values.subject, fromComponent !== 'subject' )
        if ( success ) {
            setAcademicYearId( "-" )
            handleClose( resetForm )
        }
        setAdding( false )
    }

    const getFacultySubjects = useCallback( async () => {
        setFetchingSubjects( true )
        await UserStore.fetchUserSubjects()
        setFetchingSubjects( false )
    }, [UserStore] )

    useEffect( () => {
        const getData = async () => {
            if ( UserStore.getErrorsStatuses?.subjects === true )
                await getFacultySubjects()
            await QuizAndQuestionBankStore.fetchQuestionBanks()
            if ( subject ) {
                setIsFetchingClasses( true )
                try {
                    let reqDate = room ? new Date( room.selected_date ) : new Date()
                    reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

                    let { data: { data } } = await api.getClassesByDate( reqDate )
                    setClasses( ( data || [] ).filter( room => room?.subject_id === subject?.subject_id ) )
                    setClassFetchError( false )
                } catch ( err ) {
                    setClassFetchError( true )
                    toast( "Error occured while fetching classes" )
                } finally {
                    setIsFetchingClasses( false )
                }
            }
            setLoading( false )
        }
        getData()
    }, [QuizAndQuestionBankStore, getFacultySubjects, subject, UserStore, room] )


    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "3050px", oveflow: "hidden", background: palette.form.formCardBg } }} open={state}>

            <Box position="sticky" top="0" bgcolor={palette.form.formCardBg} sx={{ zIndex: 5 }} display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="10px 20px">
                <Typography variant="h6" fontSize="18px">{fromComponent === 'subject' ? `Create new quiz for ${subject.subject_name}` : "Create new quiz"}</Typography>
                <IconButton onClick={() => handleClose()} >{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box padding="20px" position="relative" display="grid" gridTemplateColumns={questionBankMode !== "managequestions" ? "1fr" : { md: "1fr 1fr", xs: "1fr" }}>
                <Formik onSubmit={addQuiz} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue, resetForm, errors } ) => {

                        let maxDuration
                        if ( values.end_time && values.start_time ) {
                            const dateObject = new Date( values.start_date )
                            const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth()}-${dateObject.getDate()}`
                            const selectedDuration = ( new Date( `${dateStr} ${values.end_time}` ).getTime() - new Date( `${dateStr} ${values.start_time}` ).getTime() ) / 1000
                            maxDuration = secondsToHMS( selectedDuration )?.substring( 0, 5 )
                        }

                        return (
                            <Box overflow={questionBankMode !== "managequestions" ? "initial" : "hidden"} display="flex">
                                <Box overflow="auto" width={questionBankMode !== "managequestions" ? "auto" : { md: "auto", xs: "0" }} sx={{ flexGrow: 1, paddingTop: "0" }}>
                                    <Form>
                                        <Box overflow="auto" bgcolor={palette.form.formBg} padding={{ md: "20px", xs: "10px" }} border={border[1]} borderRadius="5px" >
                                            <FormControl fullWidth>
                                                <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>What would you like to name this quiz? *</Typography>
                                                <Field size="small" as={TextField} placeholder="Eg: Data structures quiz" type="text" name="name" />
                                                <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='name' /></Typography>
                                            </FormControl>
                                            <FormControl margin='normal' fullWidth>
                                                <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Which subject does this quiz cover? *</Typography>
                                                <Field
                                                    MenuProps={{ transformOrigin: { vertical: "top", horizontal: "center" }, slotProps: { paper: { className: "custom-scrollbar", sx: { maxHeight: "300px" } } } }}
                                                    size="small"
                                                    disabled={Boolean( room ) || Boolean( subject )}
                                                    as={Select}
                                                    sx={{ color: values.subject === "-" && "#cbcbcb" }}
                                                    labelId="ac-year-id"
                                                    value={fetchingSubjects ? "-" : values?.subject?.subject_id || "-"}
                                                    onChange={e => handleSubjectChange( e, setFieldValue, values.start_date )}
                                                    name="subject"
                                                >
                                                    <MenuItem value="-" sx={{ fontSize: "12px" }} dense disabled >{loading ? "Loading ..." : "Eg: Data structures"}</MenuItem>
                                                    {!loading &&
                                                        UserStore.getUserSubjects.map( ( sub ) => {
                                                            return ( <MenuItem sx={{ fontSize: "12px" }} dense key={sub?.subject_id} value={sub?.subject_id}>
                                                                {sub.subject_name}
                                                            </MenuItem> )
                                                        } )
                                                    }
                                                </Field>
                                                {!subject && UserStore.getErrorsStatuses?.subjects && <Box bgcolor={palette.errorMessage.light + "22"} marginTop="10px" borderRadius="5px" padding="5px 10px" border={`1px dotted ${palette.errorMessage.main}`} display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary">
                                                    <Typography color="errorMessage.main" variant='subtitle2'>Failed to fetch <strong><em>subjects</em></strong> from ERP...</Typography>
                                                    <Button color="errorMessage" sx={{ textTransform: "capitalize" }} onClick={() => getFacultySubjects()} endIcon={fetchingSubjects ? <CircularProgress size={14} /> : Icons.default.Replay}>Retry</Button>
                                                </Box>}
                                                {!subject && fetchingSubjects && !UserStore.getErrorsStatuses?.subjects && <Typography marginTop="5px" display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary"> <DataLoadingSpinner padding='0' size={14} /> {`Fetching your subjects from ERP...`} </Typography>}
                                                <Typography fontSize="12px" marginTop="5px" color="errorMessage.main"> <ErrorMessage name="subject" /> </Typography>
                                            </FormControl>
                                            {values.subject !== "-" && <FormControl margin='normal' fullWidth>
                                                <Typography variant='subtitle2' gutterBottom color={palette.labelColor}>Which question bank would you like to use for this quiz? *</Typography>
                                                {['select', 'managequestions', 'addnew'].includes( questionBankMode ) && <Box display="flex" gap="10px">
                                                    <Field
                                                        size="small"
                                                        as={Select}
                                                        sx={{ flexGrow: 1, color: ( values.questionbank === "-" || questionBankMode === 'managequestions' || !hasNewQuestionBankSaved ) && "darkgrey", fontWeight: "regular" }}
                                                        labelId="ac-year-id"
                                                        value={questionBankMode === 'managequestions' || !hasNewQuestionBankSaved ? "-" : values.questionbank?.id || "-"}
                                                        onChange={( e ) => handleQuestionbankChange( e.target.value, setFieldValue )}
                                                        name="questionbank"
                                                    >
                                                        <MenuItem value="-" sx={{ fontSize: "12px" }} dense disabled >{loading ? "Loading ..." : questionBankMode === 'managequestions' ? "Save the new question bank to access here..." : "Select question bank"}</MenuItem>
                                                        {!loading &&
                                                            QuizAndQuestionBankStore.getQuestionBanks.filter( qb => +qb.question_count > 0 && qb?.subject?.subject_id === values?.subject?.subject_id ).map( ( bank ) => {
                                                                return ( <MenuItem dense sx={{ fontSize: "12px" }} key={bank.id} value={bank.id}>
                                                                    {bank.name}
                                                                </MenuItem> )
                                                            } )
                                                        }
                                                        {!loading && QuizAndQuestionBankStore.getQuestionBanks.filter( qb => +qb.question_count > 0 ).length === 0 && <MenuItem value="" disabled ><Typography color="secondary">No questionbank present with questions in it! please add question banks and questions</Typography></MenuItem>}
                                                    </Field>
                                                    {!loading && questionBankMode === 'select' && <Button variant='contained' disableElevation onClick={() => openAddNewQuestionBankDialog( setFieldValue )} color='secondary' startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} >Create new</Button>}
                                                </Box>}
                                            </FormControl>}
                                            {!loadingQuestionbank && questionBankMode === 'select' && values.questionbank !== "-" && hasNewQuestionBankSaved && <Box marginTop="10px">
                                                <Box display="flex" gap="10px" alignItems="center">
                                                    <Typography variant='subtitle2' color={palette.form.label} >Select questions manually?</Typography>
                                                    <CustomSwitch onChange={e => toggleSelectedQuestionsUsage( e.target.checked, setFieldValue )} name="use_selected_topic_question" checked={values.use_selected_topic_question} />
                                                </Box>
                                                <Typography fontSize="12px" marginTop="-10px" variant='subtitle2' color={palette.form.label} >(By default questions are selected randomly)</Typography>
                                                {QuizAndQuestionBankStore.getQuestionBank?.topics?.length > 0 && <Box padding="5px 0">
                                                    <Typography marginTop="10px" color={palette.labelColor} gutterBottom variant='subtitle2'>On which topics you would like to set quiz on?</Typography>
                                                    <Box maxHeight="400px" display="flex" flexDirection="column" gap="5px" overflow="auto" className="custom-scrollbar">
                                                        {values.questionbank_topics
                                                            .map( ( topic, index ) => {
                                                                const selectedQuestionsFromThisTopic = values.quiz_questions?.filter( q => q.topic_id === topic.id )
                                                                return <Box key={topic.id} borderRadius="5px" padding="5px" border="1px solid #d3d3d3">
                                                                    <Box paddingBottom={values.use_selected_topic_question && "5px"} borderBottom={values.use_selected_topic_question && border[1]} display="flex" gap="20px">
                                                                        <Box onClick={() => toggleTopic( topic, setFieldValue, true, values, QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[topic.id]?.length || 0 )} sx={{ cursor: "pointer", "&:hover": { color: "black" } }} display="flex" gap="5px" alignItems="center">
                                                                            <CheckBox sx={{ fontSize: "18px", color: palette.primaryDark.main }} />
                                                                            <Typography display="flex" fontSize="14px" flexDirection="column">
                                                                                {topic.name}
                                                                                <small><em>{QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[topic.id]?.length || 0} questions</em></small>
                                                                            </Typography>
                                                                        </Box>
                                                                        {( !values.use_selected_topic_question || ( values.use_selected_topic_question && values.quiz_questions?.filter( q => q.topic_id === topic.id )?.length > 0 ) ) && <Box flexGrow={1} display="flex" alignItems="center" >
                                                                            <Tooltip placement='top' title="Number of question to include from question bank" >
                                                                                <TextField sx={{ background: "#f5f5f5", width: "fit-content" }} type="number" size='small' InputProps={{ sx: { width: "fit-content" }, startAdornment: <Typography fontSize="12px" width="70px" color="GrayText" marginTop="2px" paddingRight="10px" fontWeight="500">Max: {selectedQuestionsFromThisTopic?.length || 0}</Typography>, inputProps: { min: 1, max: selectedQuestionsFromThisTopic?.length } }} onChange={( e ) => handleMaxQuestionsChange( e.target.value, values.questionbank_topics_max_question, setFieldValue, index )} onWheel={e => e.target.blur()} value={values.questionbank_topics_max_question[index]} />
                                                                            </Tooltip>
                                                                        </Box>}
                                                                    </Box>
                                                                    <Collapse in={values.use_selected_topic_question}>
                                                                        <Box marginTop="5px">
                                                                            {QuizAndQuestionBankStore.getQuestionBank?.questions?.filter( q => q.topic_id === topic.id )?.map( question => {
                                                                                const selectedArrayPos = values.quiz_questions?.findIndex( q => q.id === question.id )
                                                                                return (
                                                                                    <Box onClick={() => toggleQuestionSelection( index, selectedArrayPos, question, setFieldValue, values )} sx={{ cursor: "pointer", '&:hover .question-text': { fontWeight: "600" } }} key={question.id} display="flex" gap="5px" alignItems="flex-start">
                                                                                        {selectedArrayPos >= 0 ? <CheckBox sx={{ fontSize: "18px", color: palette.primaryDark.main }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "18px", color: "GrayText" }} />}
                                                                                        <Box className='quiz-dialog-wysiwyg question-text' variant='body2' fontSize="12px">
                                                                                            <Latex delimiters={latexDelimiters}>{question.question_text}</Latex>
                                                                                        </Box>
                                                                                    </Box>
                                                                                )
                                                                            } )}
                                                                        </Box>
                                                                    </Collapse>
                                                                </Box>
                                                            } )}
                                                        {QuizAndQuestionBankStore.getQuestionBank?.topics
                                                            .filter( t => QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[t.id] && QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[t.id]?.length > 0 )
                                                            .map( topic => {
                                                                const included = values.questionbank_topics.find( t => t.id === topic.id )
                                                                return !included ? <Box borderRadius="5px" padding="5px" border="1px solid #d3d3d3" key={topic.id} display="flex" gap="20px">
                                                                    <Box onClick={() => toggleTopic( topic, setFieldValue, false, values, QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[topic.id]?.length || 0 )} sx={{ cursor: "pointer", "&:hover": { color: "black" } }} color="GrayText" display="flex" gap="5px" alignItems="center">
                                                                        <CheckBoxOutlineBlank sx={{ fontSize: "18px", color: "GrayText" }} />
                                                                        <Typography display="flex" fontSize="14px" flexDirection="column">
                                                                            {topic.name}
                                                                            <small><em>{QuizAndQuestionBankStore.getQuestionBank?.formattedQuestions[topic.id]?.length || 0} questions</em></small>
                                                                        </Typography>
                                                                    </Box>
                                                                </Box> : ""
                                                            } )}
                                                    </Box>
                                                </Box>}
                                            </Box>}
                                            {loadingQuestionbank && <DataLoadingSpinner waitingMessage="Loading question bank topics...." />}
                                            <Typography fontSize="12px" color="errorMessage.main"> <ErrorMessage name="questionbank" /> </Typography>
                                            {values.questionbank !== "-" && hasNewQuestionBankSaved && <Typography fontSize="12px" color="errorMessage.main"> <ErrorMessage name="questionbank_topics" /> </Typography>}
                                            {values.questionbank !== "-" && hasNewQuestionBankSaved && <Typography fontSize="12px" color="errorMessage.main"> <ErrorMessage name="quiz_questions" /> </Typography>}
                                            <Collapse in={values.type === "scheduled_quiz"}>
                                                <Box>
                                                    <FormControl margin="normal">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Typography variant="subtitle2" gutterBottom color={palette.labelColor} >On what date should the quiz be scheduled? *</Typography>
                                                            <DatePicker
                                                                disabled={Boolean( room )}
                                                                sx={{ width: "100%", fontSize: "12px", '& input': { padding: "10px" } }}
                                                                slotProps={{}}
                                                                minDate={dayjs( new Date() )}
                                                                disableToolbar
                                                                variant="inline"
                                                                format="DD-MM-YYYY"
                                                                value={dayjs( values.start_date )}
                                                                onChange={val => handleStartDateChange( val, setFieldValue, values.subject )}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                    {/* <Box display="flex" gap="10px" alignItems="center">
                                                            {isFetchingClasses && <CircularProgress size={14} />}
                                                            <FormControlLabel label="Associate with a class in time table" labelPlacement='end' checked={associateWithClass} onChange={e => toggleClassAssociation( e, values.start_date, setFieldValue, values )} control={<Checkbox />} />
                                                        </Box> */}
                                                    {associateWithClass && <FormControl fullWidth margin='normal'>
                                                        <Field
                                                            MenuProps={{ transformOrigin: { vertical: "bottom", horizontal: "center" }, slotProps: { paper: { className: "custom-scrollbar", sx: { maxHeight: "300px" } } } }}
                                                            size="small"
                                                            disabled={Boolean( room ) || !values?.subject?.subject_id}
                                                            as={Select}
                                                            sx={{ color: !values.room_id && "#cbcbcb" }}
                                                            value={values?.room_id || "-"}
                                                            onChange={e => handleRoomChange( e, setFieldValue )}
                                                            name="room_id"
                                                            renderValue={val => {
                                                                const cls = classes.find( r => r.time_table_id === val )
                                                                const title = cls ? cls.batch_short_name !== "NA" ?
                                                                    `${cls.batch_short_name}, Y${cls.current_year}, S${cls.current_sem} ${cls.batch_short_name}, ${cls.branch_name}` :
                                                                    `${cls.course_branch_short_name}, Y${cls.current_year}, S${cls.current_sem}, Sec: ${cls.section_short_name}, ${cls.branch_name}` : ""
                                                                return cls ? <Box fontSize="12px">
                                                                    <Typography variant='subtitle2' fontSize="12px">{values?.subject?.subject_name_short}</Typography>
                                                                    <em>{title}</em>
                                                                </Box> :
                                                                    <Typography sx={{ color: "#cbcbcb" }} fontSize="16px">Select Class</Typography>
                                                            }}
                                                        >
                                                            <MenuItem value={"-"} sx={{ fontSize: "12px" }} dense disabled>{isFetchingClasses ? "Loading ..." : "Select class"}</MenuItem>
                                                            {!isFetchingClasses &&
                                                                classes.map( ( cls ) => {
                                                                    const title = cls.batch_short_name !== "NA" ?
                                                                        `${cls.batch_short_name}, Y${cls.current_year}, S${cls.current_sem} ${cls.batch_short_name}, ${cls.branch_name}` :
                                                                        `${cls.course_branch_short_name}, Y${cls.current_year}, S${cls.current_sem}, Sec: ${cls.section_short_name}, ${cls.branch_name}`
                                                                    return ( <MenuItem dense sx={{ fontSize: "12px" }} key={cls.time_table_id} value={cls.time_table_id}>
                                                                        <Box>
                                                                            <Typography variant='subtitle2' fontSize="12px">{cls.subject_name_short}</Typography>
                                                                            <em>{title}</em>
                                                                        </Box>
                                                                    </MenuItem> )
                                                                } )
                                                            }
                                                        </Field>
                                                        {classFetchError && values?.subject?.subject_id && <Box bgcolor={palette.errorMessage.light + "22"} marginTop="10px" borderRadius="5px" padding="5px 10px" border={`1px dotted ${palette.errorMessage.main}`} display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary">
                                                            <Typography color="errorMessage.main" variant='subtitle2'>Failed to fetch <strong><em>{values?.subject?.subject_name}</em></strong> classes on <strong><em>{showDateInIndianDateFormat( values.start_date )}</em></strong>...</Typography>
                                                            <Button color="errorMessage" sx={{ textTransform: "capitalize" }} onClick={() => fetchClassesByDate( values?.subject?.subject_id, values?.start_date )} endIcon={isFetchingClasses ? <CircularProgress size={14} /> : Icons.default.Replay}>Retry</Button>
                                                        </Box>}
                                                        {isFetchingClasses && !classFetchError && values?.subject?.subject_id && <Typography marginTop="5px" display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary"> <DataLoadingSpinner padding='0' size={14} /> {`Fetching ${values?.subject?.subject_name} classes on ${showDateInIndianDateFormat( values.start_date )}...`} </Typography>}
                                                        {classes.length === 0 && values?.subject?.subject_id && !classFetchError && <Typography marginTop="5px" fontSize="12px" color="textSecondary"> <em>No <strong><em>{values?.subject?.subject_name}</em></strong> classes scheduled for <strong>{showDateInIndianDateFormat( values.start_date )}!</strong></em></Typography>}
                                                        {!values?.subject?.subject_id && <Typography marginTop="5px" fontSize="12px" color="error">Select subject and date to fetch the classes!</Typography>}
                                                        <Typography color="errorMessage.main" marginTop="5px" fontSize="12px"> <ErrorMessage name="room_id" /> </Typography>
                                                    </FormControl>}
                                                    <Box display="flex" gap="20px" alignItems="center">
                                                        <FormControl margin="normal">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Typography variant="subtitle2" gutterBottom color={palette.labelColor} >Start time *</Typography>
                                                                <TimePicker
                                                                    sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                                    disableToolbar
                                                                    value={dayjs( new Date( `2024-01-01 ${values.start_time}` ) )}
                                                                    onChange={val => handleTimeChange( 'start_time', val, setFieldValue )}
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                        <FormControl margin="normal">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Typography variant="subtitle2" gutterBottom color={palette.labelColor} >End time *</Typography>
                                                                <TimePicker
                                                                    sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                                    value={dayjs( new Date( `2024-01-01 ${values.end_time}` ) )}
                                                                    onChange={val => handleTimeChange( 'end_time', val, setFieldValue )}
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                                <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="start_date" /> </Typography>
                                                <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="start_time" /> </Typography>
                                                <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="end_time" /> </Typography>
                                                {!( Boolean( room ) || associateWithClass ) && <Box marginTop="10px" display="flex" flexWrap="wrap" gap="10px" alignItems="flex-start">
                                                    <FormControl margin='normal' sx={{ minWidth: "170px", width: "250px" }}>
                                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Select academic year of admission</Typography>
                                                        <Select size="small" placeholder='Select an academic year' name="academicYear" value={academicYearId} onChange={e => handleAcademicYearChange( e, setFieldValue )}>
                                                            <MenuItem sx={{ fontSize: "14px" }} key={"default"} value={'-'}>
                                                                Select an academic year
                                                            </MenuItem>
                                                            {UserStore.getUserAcademicYears.map( ( batchYear, k ) => {
                                                                return ( <MenuItem sx={{ fontSize: "14px" }} key={batchYear.ac_year_id} value={batchYear.ac_year_id}>
                                                                    {batchYear.ac_year}
                                                                </MenuItem> )
                                                            } )}
                                                        </Select>
                                                        {<Typography onClick={refreshYears} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color="secondary" fontWeight="500"><RefreshOutlined fontSize='16px' />Refresh academic years</Typography>}
                                                    </FormControl>
                                                    <FormControl margin='normal' sx={{ minWidth: "170px", width: "250px" }}>
                                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Select Batch / Section *</Typography>
                                                        <Field
                                                            MenuProps={{ transformOrigin: { vertical: "bottom", horizontal: "center" }, slotProps: { paper: { className: "custom-scrollbar", sx: { maxHeight: "300px" } } } }}
                                                            size="small"
                                                            as={Select}
                                                            sx={{ color: values.access_group === "-" && "darkgrey", fontWeight: "regular" }}
                                                            placeholder="Select acces group"
                                                            disabled={isLoadingBatches || ( UserStore.getUserBatchesAndSection["batches"]?.length === 0 && UserStore.getUserBatchesAndSection["sections"].length === 0 )}
                                                            name="access_group"
                                                            onChange={( e ) => handleAccessGroupChange( e.target.value, setFieldValue )}
                                                            value={
                                                                values?.access_group?.batch_assignment_id ? values?.access_group?.batch_assignment_id : values?.access_group?.section_assignment_id ? values?.access_group?.section_assignment_id + "-" + values?.access_group?.section_short_name : "-"
                                                            }
                                                        // values?.access_group?.batch_assignment_id ? values?.access_group?.batch_assignment_id : values?.access_group?.section_assignment_id ? values?.access_group?.section_assignment_id + "-" + values?.access_group?.section_short_name : "" : ""
                                                        >
                                                            <MenuItem value={'-'} disabled key="key" color='textSecondary' sx={{ fontSize: "14px" }}> Select batch </MenuItem>
                                                            {UserStore.getUserBatchesAndSection["batches"]?.length !== 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="10px" color="secondary">Batches</Typography>}
                                                            {
                                                                UserStore.getUserBatchesAndSection["batches"]?.map( ( batch, k ) => {
                                                                    return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.batch_assignment_id} value={batch.batch_assignment_id}>
                                                                        {batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name}
                                                                    </MenuItem> )
                                                                } )
                                                            }
                                                            {UserStore.getUserBatchesAndSection["sections"]?.length !== 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="10px" color="secondary">Sections</Typography>}
                                                            {
                                                                UserStore.getUserBatchesAndSection["sections"]?.map( ( batch, k ) => {
                                                                    return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.section_assignment_id} value={batch.section_assignment_id + "-" + batch.section_short_name}>
                                                                        {batch.course_branch_short_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " (" + batch.section_short_name + ")"}
                                                                    </MenuItem> )
                                                                } )
                                                            }
                                                        </Field>
                                                        {academicYearId !== "-" && <Typography onClick={refreshBatches} sx={{ cursor: "pointer", '&:hover': { cursor: "not-allowed", textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color={"secondary"} fontWeight="500"><RefreshOutlined fontSize='16px' /> Refresh batches</Typography>}
                                                        {isLoadingBatches && <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2' color="textSecondary"> <CircularProgress size={14} /> Loading batches...</Typography>}
                                                    </FormControl>
                                                </Box>}
                                                <Typography fontSize="12px" color="errorMessage.main"> <ErrorMessage name="access_group" /> </Typography>
                                            </Collapse>
                                            <FormControl margin="normal" fullWidth>
                                                <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>How long will the quiz last? {values.end_time && values.start_time && `(Max: ${maxDuration})`}</Typography>
                                                <Field size="small" as={TextField} placeholder="For 2 hrs quiz set value as 02:00" type="text" name="quiz_duration" />
                                                <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='quiz_duration' /></Typography>
                                            </FormControl>
                                            <Box marginTop="40px" display="flex" gap="20px" flexWrap="wrap">
                                                <Button type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >{adding ? "Adding new quiz..." : "Add Quiz"}</Button>
                                                <Button onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation color="error" >Cancel</Button>
                                            </Box>
                                            <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "540px" } }} open={questionBankMode === 'addnew'}>
                                                <Box>
                                                    <Typography padding="10px 20px" borderBottom={border[1]} variant='h6'>New Question bank</Typography>
                                                    <Box display="flex" flexDirection="column" gap="30px" border={border[1]} padding="20px" bgcolor={palette.form.formBg}>
                                                        <TextField onKeyUp={e => e.key === 'Enter' && addNewQuestionBank( values.subject, setFieldValue )} inputRef={newQuestionBankNameInputRef} autoFocus sx={{ flexGrow: 1 }} size="small" placeholder='Question bank name' />
                                                        <Box display="flex" gap="10px">
                                                            <Button onClick={() => addNewQuestionBank( values.subject, setFieldValue )} variant='contained' disableElevation color="secondary" startIcon={Icons.default.AddIcon} sx={{ width: "max-content", textTransform: "capitalize" }} >Add question bank</Button>
                                                            <Button variant='contained' onClick={() => setQuestionBankMode( 'select' )} disableElevation color="errorMessage" startIcon={Icons.default.CloseIcon} sx={{ width: "max-content", textTransform: "capitalize" }} >Cancel</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Dialog>
                                        </Box>
                                    </Form>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
                {questionBankMode === 'managequestions' && newBankId && <Box sx={{ position: { md: "static", xs: "absolute" }, right: { md: "initial", xs: "0" }, height: { md: "initial", xs: "100%" }, background: { md: "none", xs: palette.form.formCardBg } }} padding="20px" paddingTop="0">
                    <ManageNewQuestionBank setHasNewQuestionBankSaved={setHasNewQuestionBankSaved} setQuestionBankMode={setQuestionBankMode} bank_id={newBankId} />
                </Box>}

            </Box>
        </Dialog>
    )
} )

export default CreateQuizDialog
