import { Box, Typography, Button, MenuItem, Paper, useTheme, Menu, CircularProgress } from '@mui/material'
import FacultyMaterialsList from '../../components/study-material-components/FacultyMaterialsList'
import StudentMaterialList from '../../components/study-material-components/StudentMaterialsList'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { useOrganizationStore } from '../../store/organization.store'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import fileLogo from '../../assets/no-material.svg'
import { Icons } from '../../utils/utilities'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'


const Materials = observer( () => {

    const [materials, setMaterials] = useState( [] )
    const [docsCount, setDocsCount] = useState( {} )
    const [filteredMaterials, setFilteredMaterials] = useState( [] )
    const [isLoading, setIsLoading] = useState( true )
    const [rowsPerPage, setRowsPerPage] = useState( 50 )
    const [page, setPage] = useState( 0 )
    const [isInstitutesLoading, setIsInstitutesLoading] = useState( false )
    const [instituteMenuAnchor, setInstituteMenuAnchor] = useState( null )

    const [searchParams, setSearchParams] = useSearchParams( { mode: "manage", searchText: "", institute: "0" } )
    const mode = searchParams.get( 'mode' )
    const institute = searchParams.get( 'institute' ) || "0"
    const searchText = searchParams.get( 'searchText' ) || ""

    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()

    const { palette } = useTheme()

    const navigate = useNavigate()
    const StudyMaterialStore = useStudyMaterialStore()

    const searchMaterials = ( e ) => {
        setSearchParams( prev => {
            prev.set( "searchText", e.target.value )
            return prev
        }, { replace: true } )
    }

    const handleStudentInstituteChange = ( val ) => {
        setSearchParams( prev => {
            prev.set( "institute", val )
            return prev
        }, { replace: true } )
        setInstituteMenuAnchor( null )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const removeFilters = async () => {
        setSearchParams( prev => {
            prev.set( "searchText", "" )
            return prev
        }, { replace: true } ); setSearchParams( prev => {
            prev.set( "institute", "0" )
            return prev
        }, { replace: true } )
        if ( StudyMaterialStore.getErrors.materials )
            await StudyMaterialStore.fetchMaterials()
        setMaterials( StudyMaterialStore.getMaterials["materials"] )
        const countsData = StudyMaterialStore.getMaterials["docsCount"]
        const counts = {}
        countsData.forEach( count => {
            counts[count.study_material_id] = count.docs_count
        } )
        setDocsCount( counts )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getData = async () => {
            localStorage.setItem( "studyMaterialMode", "manage" )
            if ( ( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) ) {
                if ( StudyMaterialStore.getErrors.materials )
                    await StudyMaterialStore.fetchMaterials()
                setMaterials( StudyMaterialStore.getMaterials["materials"] )
                const countsData = StudyMaterialStore.getMaterials["docsCount"]
                const counts = {}
                countsData.forEach( count => {
                    counts[count.study_material_id] = count.docs_count
                } )
                setDocsCount( counts )
            } else {
                setIsInstitutesLoading( true )
                if ( OrganizationStore.getOrganization?.length === 0 )
                    await OrganizationStore.fetchOrganization()
                setIsInstitutesLoading( false )
                if ( StudyMaterialStore.getErrors.materials )
                    await StudyMaterialStore.fetchMaterials()
                setMaterials( StudyMaterialStore.getMaterials["materials"] )
                const countsData = StudyMaterialStore.getMaterials["docsCount"]
                const counts = {}
                countsData.forEach( count => {
                    counts[count.study_material_id] = count.docs_count
                } )
                setDocsCount( counts )
            }
            setIsLoading( false )
        }
        getData()
    }, [UserStore, StudyMaterialStore, OrganizationStore] )

    useEffect( () => {
        const searchRegExp = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        setFilteredMaterials(
            materials.filter( material => {
                return ( material.subject_name?.match( searchRegExp ) ||
                    material.study_material_name?.match( searchRegExp ) ||
                    material.subject_code?.match( searchRegExp ) ||
                    material.study_material_created_by?.match( searchRegExp ) ||
                    material?.study_material_tags?.match( searchRegExp )
                ) && ( material.institute === institute || institute === "0" )
            } )
        )
    }, [materials, searchText, institute] )

    return (
        <Box minWidth="0" overflow="auto" flexGrow={1} display="flex" flexDirection="column" padding="20px">

            {/* Main Content */}
            <Paper sx={{
                flexGrow: ( ( !isLoading && filteredMaterials && filteredMaterials.length > 0 ) && ( institute !== "0" || UserStore.getUser.user_role !== "STUDENT" ) ) ? 1 : "initial", overflow: "hidden", display: "flex", flexDirection: "column", minHeight: "600px"
            }}>
                <Box
                    position="relative"
                    sx={{
                        background: `url('/imgs/study-material-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    padding="20px"
                    display="flex"
                    gap="20px"
                    alignItems="flex-start"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}></Box>
                    <Box position="relative" sx={{ zIndex: 10 }}>
                        <Typography color="white" variant="h5" width="fit-content" fontWeight="normal">{UserStore.getUser["user_role"] === "FACULTY" ? mode === "manage" ? "Your Study Materials" : "Study material audit" : "Study Materials"}</Typography>
                        <Typography color="whitesmoke" variant='subtitle2'>{UserStore.getUser["user_role"] === "STUDENT" ? "Browse study materials here" : mode === "manage" ? "Manage your study materials here" : `Audit all ${UserStore.getUser["user_role"] === "HOD" ? UserStore.getUser["user_dept_code"] : UserStore.getUser["user_org_code"]} study materials`}</Typography>
                    </Box>

                    {!isLoading && <Box width="100%" display="flex" gap="20px" justifyContent="space-between" flexWrap="wrap">

                        {/* Students Filters */}
                        <Box display="flex" gap="20px" alignItems="stretch">
                            {UserStore.getUser["user_role"] === "STUDENT" &&
                                <Box>
                                    <Button disabled={isInstitutesLoading} sx={{ height: "100%" }} variant='contained' disableElevation endIcon={isInstitutesLoading ? <CircularProgress size={14} /> : Icons.small.DropDown} onClick={e => setInstituteMenuAnchor( e.currentTarget )} color="customThemeColor">{isInstitutesLoading ? "Fetching institutes..." : institute !== "0" ? institute : "Select institute"}</Button>
                                    <Menu onClose={() => setInstituteMenuAnchor( null )} anchorEl={instituteMenuAnchor} open={Boolean( instituteMenuAnchor )} >
                                        <MenuItem onClick={() => handleStudentInstituteChange( "0" )} dense key={"all"} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={"0"}>
                                            All institutes
                                        </MenuItem>
                                        {OrganizationStore.getOrganization.map( org => {
                                            return <MenuItem onClick={() => handleStudentInstituteChange( org.institute_name_short )} dense key={org.institute_id} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={org.institute_name_short}>
                                                {org.institute_name.toLowerCase()}
                                            </MenuItem>
                                        } )}
                                    </Menu>
                                </Box>}
                            <CustomSearchBar style={{ flexGrow: 1, width: "400px" }} border='1px solid #d3d3d3' value={searchText} onChange={searchMaterials} placeholder="Search by Subjects, Faculty, Title or Subject code..." />
                        </Box>

                        {/* Create Material Button */}
                        {( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) && mode === "manage" && <Button disableElevation variant='contained' sx={{ textTransform: "capitalize" }} color='customThemeColor' onClick={() => navigate( '/faculty/material/add' )} startIcon={Icons.default.AddIcon}>Create</Button>}
                    </Box>}
                </Box>


                {!isLoading
                    && filteredMaterials
                    && filteredMaterials.length > 0
                    && UserStore.getUser.user_role !== 'STUDENT'
                    && <FacultyMaterialsList filteredMaterials={filteredMaterials} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} docsCount={docsCount} />}


                {!isLoading
                    && filteredMaterials
                    && filteredMaterials.length > 0
                    && UserStore.getUser.user_role === 'STUDENT'
                    && <StudentMaterialList filteredMaterials={filteredMaterials} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} docsCount={docsCount} />}


                {!isLoading && <Box flexGrow={1} display="flex" flexDirection="column">
                    {( !filteredMaterials || filteredMaterials.length === 0 ) && <Box display="flex" flexDirection="column" flexGrow={1}>
                        {mode === "audit" && <Box flexGrow={1}>
                            {UserStore.getUser.user_role === "PRINCIPAL" && !UserStore.getUser.user_auditor && <Box display="flex" flexDirection="column" bgcolor={palette.contentBg} padding="20px" alignItems="center">
                                <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />
                                <Typography color="textSecondary" variant='subtitle2'>No materials!</Typography>
                            </Box>}
                            {UserStore.getUser.user_role === "PRINCIPAL" && UserStore.getUser.user_auditor && institute !== "0" && <Box display="flex" flexDirection="column" bgcolor={palette.contentBg} padding="20px" alignItems="center">
                                <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />
                                <Typography color="textSecondary" variant='subtitle2'>No materials!</Typography>
                            </Box>}
                            {<Box display="flex" flexDirection="column" bgcolor={palette.contentBg} padding="20px" alignItems="center">
                                <img src={selectInstituteImg} height="150px" style={{ marginBottom: "20px" }} alt="Select instiute!" />
                                <Typography color="textSecondary" variant='subtitle2'>{searchText === "" ? "Please select an institute to audit study materials" : `No materials found`}</Typography>
                                {searchText !== "" && <Button variant='contained' onClick={() => searchMaterials( { target: { value: "" } } )} sx={{ textTransform: "capitalize", marginTop: "20px" }} color="secondary">Clear filter</Button>}
                            </Box>}
                        </Box>}


                        {mode === "manage" && <Box padding="20px" flexGrow={1} bgcolor={palette.contentBg} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            {( UserStore.getUser.user_role !== 'STUDENT' ) ? <img src={fileLogo} width="300px" style={{ marginBottom: "20px" }} alt="No Material!" /> : ( institute !== "0" || searchText !== "" ) && <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />}
                            < Typography textAlign="center" color="textSecondary" variant='subtitle2'>{( UserStore.getUser.user_role !== 'STUDENT' ) ? "You doesn't seem to have any materials!" : ( institute === "0" && searchText === "" ) ? "Please select an institute to browse study materials or search" : "Selected institute doesn't seem to have any study materials"}</Typography>
                            <Box marginTop="20px" display="flex" gap="20px" alignItems="center" flexWrap="wrap">
                                {materials && ( materials.length > 0 || searchText !== "" || institute !== "0" ) && <Button onClick={removeFilters} disableElevation variant='contained' color="secondary" sx={{ textTransform: "capitalize" }}>Remove filter</Button>}
                                {( UserStore.getUser.user_role !== 'STUDENT' ) && <Button disableElevation variant='contained' onClick={() => navigate( "/faculty/material/add" )} sx={{ textTransform: "capitalize" }}>Add one</Button>}
                            </Box>
                        </Box>}
                    </Box>}

                </Box>}
                {isLoading && <DataLoadingSpinner waitingMessage="Fetching materials..." />}
            </Paper>
        </Box >
    )
} )

export default Materials
