import { Box, Button, CircularProgress, Paper, Typography, useTheme, Tab, Tabs, Card, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import AnswerSection from '../../components/admission-exam/AnswerSection'
import QuestionList from '../../components/admission-exam/QuestionList'
import { useParams, useNavigate } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { formatDateTimeForInputField } from '../../utils/exam-utilities'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../service/api'
import {
    isMobileDevice,
    // erpResultsPageLink,
    shuffleArray
} from '../../utils/utilities'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import { useUserStore } from '../../store/user.store'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import fullScreenImg from '../../assets/full-screen.svg'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useSettingsStore } from '../../store/settings.store'


const examWindowHeaderStyle = {
    padding: "20px",
    background: "#151D48",
    color: "white",
    zIndex: 1000,
    '@media(max-width:900px)': {
        flexDirection: "column",
        height: "fit-content"
    }
}

const CountDown = ( { examDuration, time, finishExam, setTimeLeft } ) => {
    const [remainingTime, setRemainingTime] = useState( time )
    const [timeElapsed, setTimeElapsed] = useState( '' )
    const [countdown, setCountdown] = useState( '' )

    useEffect( () => {
        const interval = setInterval( () => {
            if ( remainingTime || remainingTime === 0 ) {
                if ( remainingTime <= 0 ) {
                    finishExam( "TimeUP", 0, 0 )
                    clearInterval( interval )
                } else setRemainingTime( pd => pd - 1 )
                let hours = Math.floor( remainingTime / 3600 )
                let minutes = Math.floor( ( remainingTime % 3600 ) / 60 )
                let seconds = parseInt( ( ( remainingTime % 3600 ) % 60 ) )

                hours = hours < 10 ? "0" + hours : hours
                minutes = minutes < 10 ? "0" + minutes : minutes
                seconds = seconds < 10 ? "0" + seconds : seconds
                setCountdown( `${hours}:${minutes}:${seconds} Sec` )
                setTimeLeft( remainingTime )
            }
        }, 1000 )
        return () => clearInterval( interval )
    }, [remainingTime, finishExam, setTimeLeft] )

    useEffect( () => {

        let totalhours = Math.floor( ( examDuration - remainingTime - 1 ) / 3600 )
        let totalminutes = Math.floor( ( ( examDuration - remainingTime - 1 ) % 3600 ) / 60 )
        let totalseconds = parseInt( ( ( ( examDuration - remainingTime - 1 ) % 3600 ) % 60 ) )

        totalhours = totalhours < 10 ? "0" + totalhours : totalhours
        totalminutes = totalminutes < 10 ? "0" + totalminutes : totalminutes
        totalseconds = totalseconds < 10 ? "0" + totalseconds : totalseconds
        if ( ( examDuration - remainingTime - 1 ) > 0 )
            setTimeElapsed( `${totalhours}:${totalminutes}:${totalseconds} Sec` )
    }, [remainingTime, examDuration] )

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant='subtitle2' fontSize="12px">Elapsed Time: {timeElapsed}</Typography>
            <Typography variant='subtitle2' sx={{ color: remainingTime < 300 ? "red" : "white" }} width="90px"> {countdown} </Typography>
        </Box>
    )
}



const AdmissionExamAttempt = () => {
    const [currentIndex, setCurrentIndex] = useState( 0 )
    const [submissions, setSubmissions] = useState( null )
    const [currentQuestion, setCurrentQuestion] = useState( null )
    const [questions, setQuestions] = useState( null )
    const [exam, setExam] = useState( null )
    const [remainingTime, setRemainingTime] = useState( null )
    const [examDuration, setExamDuration] = useState( 0 )
    const [timeLeft, setTimeLeft] = useState( '' )
    const [participation, setParticipation] = useState( null )
    const [isExamStarted, setIsExamStarted] = useState( false )
    const [showQuestions, setShowQuestions] = useState( true )
    const [isStarting, setIsStarting] = useState( false )
    const [finishDialog, setFinishDialog] = useState( false )
    const [isSubmitting, setIsSubmitting] = useState( false )
    const [showWarning, setShowWarning] = useState( false )
    const [saving, setSaving] = useState( false )
    const [error, setError] = useState( null )
    const [faultCount, setFaultCount] = useState( 0 )
    const [section, setSection] = useState( "" )
    const [allQuestionSections, setAllQuestionSections] = useState( [] )
    const [filteredQuestions, setFilteredQuestions] = useState( [] )
    const [loading, setLoading] = useState( true )

    const navigate = useNavigate()

    const AdmissionExamStore = useAdmissionExamStore()
    const SettingsStore = useSettingsStore()
    const UserStore = useUserStore()

    const { id, pid, attempt } = useParams()
    const { palette, border, theme } = useTheme()

    const examAttemptFullScreenHandle = useFullScreenHandle()

    const fullscreeBtnRef = useRef()
    const fullscreenHelperInputRef = useRef()

    const handleSectionChange = ( event, newValue ) => {
        setSection( newValue )
    }

    const uniqueSubmissions = ( subs ) => {
        const unique = []
        const map = new Map()

        subs.forEach( item => {
            const combinedKey = item['question_id']
            if ( !map.has( combinedKey ) ) {
                map.set( combinedKey, true )
                unique.push( item )
            }
        } )
        return unique
    }

    const selectQuestion = id => {
        for ( let i = 0; i < filteredQuestions.length; i++ ) {
            if ( filteredQuestions[i].question.question_id === id ) {
                setCurrentQuestion( filteredQuestions[i] )
                setCurrentIndex( i )
                break
            }
        }
    }

    const moveToNextQuestion = () => {
        let nextIndex = currentIndex + 1
        if ( nextIndex >= filteredQuestions.length ) {
            let currentSectionIndex = allQuestionSections.indexOf( section )
            currentSectionIndex += 1
            if ( currentSectionIndex >= allQuestionSections?.length ) {
                currentSectionIndex = 0
            }
            nextIndex = 0
            setCurrentIndex( nextIndex )
            setSection( allQuestionSections[currentSectionIndex] )
        } else {
            setCurrentIndex( nextIndex )
            setCurrentQuestion( filteredQuestions[nextIndex] )
        }

    }

    const saveAnswer = async ( answer, type, selectionType, reset = false ) => {

        try {
            if ( examAttemptFullScreenHandle?.active === false ) {
                await fullscreenHelperInputRef?.current?.focus()
                await fullscreenHelperInputRef?.current?.click()
                await fullscreeBtnRef?.current?.click()
                if ( window.screen.orientation && window.screen.orientation.lock ) {
                    window.screen.orientation.lock( 'portrait' ).catch( ( err ) => {
                        console.warn( 'Screen orientation lock failed:', err )
                    } )
                }
            }
        } catch ( err1 ) {
            console.log( err1 )
        }

        if ( answer || reset ) {
            if ( String( answer ).trim() !== '' || reset ) {
                setSaving( true )
                try {
                    const res = await api.admissionExams.submitAnswer( id, pid, { answer, qid: currentQuestion.question.question_id, type, selectionType, remainingDuration: timeLeft, reset } )
                    if ( res.status === 200 ) {
                        const { data: { data: sData } } = await api.admissionExams.fetchParticipationSubmissions( id, pid )
                        setSubmissions( uniqueSubmissions( sData ) )
                        toast( "Answer saved", { position: "bottom-right", containerId: "answer-toast" } )
                    }
                } catch ( e ) {
                    console.log( e )
                    if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                        UserStore.setERPSessionState( "ended" )
                    }
                    else
                        toast( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! submit answer again", { containerId: "answer-toast" } )
                } finally {
                    setSaving( false )
                }
            } else toast( "No answer given for previous question!", { position: "bottom-right", containerId: "answer-toast" } )
        } else toast( "No answer given for previous question!", { position: "bottom-right", containerId: "answer-toast" } )
    }

    const handleClickOnFinishExam = () => {
        if ( submissions?.length !== questions?.length )
            return toast( "Please answer all the questions!", { position: "bottom-right" } )
        setFinishDialog( true )
    }

    const checkForReload = ( e ) => {
        if ( e.code === 'F5' )
            e.preventDefault()
        e = e || e.window.event
        if ( e.ctrlKey ) {
            var c = e.which || e.keyCode
            if ( c === 82 ) {
                e.preventDefault()
                e.stopPropagation()
            }
        }
    }

    const handleFullScreenChange = async ( state, handle ) => {
        if ( isExamStarted && state === false ) {
            if ( ( AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring === true || AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring === 'true' ) ) {
                setFaultCount( prev => prev + 1 )
            }
            setShowWarning( "fullscreenexit" )
            try {
                if ( examAttemptFullScreenHandle?.active === false ) {
                    await fullscreenHelperInputRef?.current?.focus()
                    await fullscreenHelperInputRef?.current?.click()
                    await fullscreeBtnRef?.current?.click()
                    if ( window.screen.orientation && window.screen.orientation.lock ) {
                        window.screen.orientation.lock( 'portrait' ).catch( ( err ) => {
                            console.warn( 'Screen orientation lock failed:', err )
                        } )
                    }
                }
            } catch ( err ) { }
        }
    }

    const handleScreenChange = useCallback( ( e ) => {
        if ( isExamStarted ) {
            if ( ( AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring === true || AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring === 'true' ) ) {
                setFaultCount( prev => prev + 1 )
            }
            setShowWarning( "windowchange" )
        }
    }, [isExamStarted, AdmissionExamStore] )

    const startExam = async () => {
        setIsStarting( true )
        try {
            const { isMobile } = isMobileDevice()
            if ( isMobile && ( AdmissionExamStore?.getExam?.exam?.exam_constraints?.restrictMobileAttempt === 'true' || AdmissionExamStore?.getExam?.exam?.exam_constraints?.restrictMobileAttempt === true ) && SettingsStore?.getUseSettings['restrict_admission_exam_attempt_on_mobile_devices'] === true ) {
                toast( `This exam cannot be taken on mobile device! please use laptop or desktop with stable internet connection.` )
                return
            }
            const deviceData = {}

            deviceData.platform = navigator?.userAgentData?.platform
            deviceData.userAgent = navigator?.userAgent
            deviceData.vendor = navigator?.vendor
            const res = await api.admissionExams.startExam( id, pid, { device_data: deviceData } )
            const { data: qData } = await api.admissionExams.fetchQuestionsForAttempt( id, pid )
            if ( AdmissionExamStore.getExam.exam.enable_randomize )
                shuffleArray( qData.data )
            setQuestions( qData.data )
            if ( AdmissionExamStore.getExam.sections && AdmissionExamStore.getExam.sections?.length > 0 ) {
                setSection( AdmissionExamStore.getExam.sections[0] )
            } else {
                setFilteredQuestions( qData.data )
            }
            setCurrentQuestion( qData.data[0] )
            setRemainingTime( res.data.duration )
            setExamDuration( res.data.totalDuration )
            setIsExamStarted( true )
            return true
        } catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            toast( e?.response?.data?.message || "Something went wrong! try to start exam again", { containerId: "main" } )
        } finally {
            setIsStarting( false )
        }
    }

    const handleStart = async () => {
        try {
            if ( await startExam() ) {
                examAttemptFullScreenHandle.enter()
            }
        } catch ( err ) { }
    }

    const finishExam = useCallback( async ( reason = "TimeUP", answeredQuestion, totalQuestions ) => {
        try {
            if ( answeredQuestion !== totalQuestions && reason !== "TimeUP" && reason !== "MaximumFaultsReached" ) {
                toast( "Please answer all the questions!" )
                return
            }
            const { data } = await api.admissionExams.finishExam( id, pid, { reason } )
            localStorage.setItem( 'admission_exam_candidate_ref_no', data?.candidate_ref_no )
            toast( "Exam submitted!", { containerId: "main" } )
            if ( AdmissionExamStore?.getExam?.exam?.exam_constraints?.receiveWorksheet === 'true' || AdmissionExamStore?.getExam?.exam?.exam_constraints?.receiveWorksheet === true )
                return navigate( `/assessment/${id}/${pid}/worksheet-upload`, { replace: true } )
            if ( AdmissionExamStore?.getExam?.exam?.exam_constraints?.showScoresAfterFinishingExam === 'true' || AdmissionExamStore?.getExam?.exam?.exam_constraints?.showScoresAfterFinishingExam === true )
                return navigate( `/assessment/${id}/${pid}/score`, { replace: true } )
            navigate( `/thankyou`, { replace: true } )
        }
        catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else
                toast.error( e?.response?.data?.message || "Something went wrong while submitting you answers", { containerId: "main" } )
        }
    }, [UserStore, AdmissionExamStore, navigate, id, pid] )

    const toggleQuestions = () => {
        setShowQuestions( ps => !ps )
    }

    useEffect( () => {
        window.addEventListener( 'keydown', checkForReload )
        window.addEventListener( 'blur', handleScreenChange )

        return () => {
            window.removeEventListener( 'keydown', checkForReload )
            window.removeEventListener( 'blur', handleScreenChange )
        }
    }, [handleScreenChange] )

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { success, error } = await AdmissionExamStore.fetchExam( id )
            if ( success ) {
                setError( null )
            } else {
                setError( error )
                return
            }
            const participation = await api.admissionExams.fetchParticipationData( id, pid )
            if ( String( participation.data.participation.exam_participation_end_time ) !== String( null ) || participation.data.participation.exam_participation_status === 'Completed' ) {
                // toast( "The attempt you tried to acess already ended!", { containerId: "main" } )
                return setError( { code: 400, message: "The attempt you tried to acess already ended", error: "ENDED" } )
            }
            if ( new Date( formatDateTimeForInputField( AdmissionExamStore.getExam.exam.exam_end_date, AdmissionExamStore.getExam.exam.exam_end_time ) ) < new Date() ) {
                // finishExam( "LateAccess", 0, 0 )
                // toast( "Exam already ended!", { containerId: "main" } )
                return setError( { code: 400, message: "Exam already ended! if not, please set your system date and time correctly and log in again." } )
            }
            const { data: { data: sData } } = await api.admissionExams.fetchParticipationSubmissions( id, pid )
            setParticipation( participation.data )

            setRemainingTime( participation.data?.participation?.remaining_time || participation.data?.participation?.duration )
            setExamDuration( participation.data?.participation?.duration )
            setFaultCount( participation.data?.participation?.faults_made )
            setAllQuestionSections( AdmissionExamStore.getExam.sections )
            setSubmissions( uniqueSubmissions( sData ) )
            setExam( AdmissionExamStore.getExam.exam )
            setError( null )
        } catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else setError( { code: e?.response?.status, message: e?.response?.data?.message || "Something went wrong! try again later" } )
        } finally {
            setLoading( false )
        }
    }, [id, pid, AdmissionExamStore, UserStore] )

    useEffect( () => {
        getData()
    }, [getData] )

    useEffect( () => {

        const registerFault = async () => {

            if ( faultCount > +( participation?.participation?.faults_made || 0 ) ) {
                try {
                    await api.admissionExams.registerExamAttemptFault( id, pid, { faultCount } )
                } catch ( err ) {
                    console.log( err )
                }
            }
            if ( faultCount >= ( SettingsStore?.getUseSettings['admission_exam_maximum_fault_excuses'] || 5 ) ) {
                // setIsExamStarted( ps => false )
                toast( "You have exceeded the maximum fault count. submitting and finishing exam..." )
                finishExam( "MaximumFaultsReached" )
            }
        }
        registerFault()
    }, [finishExam, faultCount, id, pid, participation, SettingsStore] )

    useEffect( () => {
        if ( section && allQuestionSections.length > 0 ) {
            const filtered = questions.filter( q => q.question.question_section === section )
            setFilteredQuestions( filtered )
            setCurrentIndex( 0 )
            setCurrentQuestion( filtered[0] )
        }
    }, [section, questions, allQuestionSections] )


    return (

        <FullScreen handle={examAttemptFullScreenHandle} onChange={handleFullScreenChange} className={`full-screen-element ${theme === 'dark' ? 'dark' : 'light'}`}>
            <ToastContainer containerId="answer-toast" position='bottom-left' autoClose={3000} hideProgressBar closeOnClick theme='dark' style={{ fontSize: "12px", zIndex: 100000000 }} />
            {loading && <DataLoadingSpinner waitingMessage="Loading exam details..." />}

            {!loading && !isExamStarted && !error && exam && <Box padding="20px" flexGrow={1} display="flex" flexDirection="column" minWidth="0" overflow="auto">
                <Paper sx={{ paddingBottom: "1px", overflow: "auto", display: "flex", flexDirection: "column", }}>
                    <Box padding="20px" bgcolor={palette.primary.main} display="flex" flexDirection="column" gap="5px" color="white">
                        <Typography variant='h5'>{exam.exam_name}</Typography>
                        <Typography variant='subtitle2'>{exam.exam_duration !== null && exam.exam_duration !== "null" ? "Duration: " + exam.exam_duration : ""}</Typography>
                        <Typography variant='body2'>Attempt number: {attempt}</Typography>
                    </Box>
                    <Box flexGrow={1} className="custom-scrollbar" overflow="auto" margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.contentBg} padding="20px">
                        <Typography marginTop="20px" variant='h6'>Important Instrctions</Typography>
                        <Box marginTop="10px" className='wysiwyg-text-container' borderRadius="10px">
                            <Box dangerouslySetInnerHTML={{ __html: AdmissionExamStore.getExam?.instructions }} ></Box>
                        </Box>
                        <Button onClick={handleStart} disabled={isStarting} variant='contained' color='success' sx={{ marginY: "20px", textTransform: "capitalize" }} disableElevation > {isStarting ? <Typography display="flex" alignItems="center" gap="5px" fontSize={14}> <CircularProgress size={14} /> Starting assessment...</Typography> : <Typography fontSize={14}>Start assessment</Typography>} </Button>
                    </Box>
                </Paper>

            </Box>
            }
            {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={getData} />}

            {/* {loading && <DataLoadingSpinner waitingMessage="Loading exam details..." />} */}

            {isExamStarted && <Box onContextMenu={e => e.preventDefault()} flexGrow={1} id='exam-window' display="flex" flexDirection="column" overflow="auto">
                <Box width="0" sx={{ opacity: "0" }} overflow="hidden" height="0" >
                    <input style={{ height: "0 !important", width: "0 !important" }} type="text" ref={fullscreenHelperInputRef} />
                </Box>
                <Box bgcolor="rgba(0,0,0,0.3)" sx={{ position: "fixed", zIndex: "20000", display: Boolean( showWarning ) ? "flex" : "none", alignItems: "center", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center" }} >
                    <Card>
                        <Box maxWidth="400px">
                            <Box padding="10px 20px" display="flex" alignItems="flex-end" justifyContent="space-between" gap="20px">
                                <Typography textAlign="center" color="primaryDark.main" variant='h6' >Warning!</Typography>
                                {AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && <Typography textAlign="center" color="primary.main" fontWeight="bolder" variant='subtitle2'>Only {+( SettingsStore?.getUseSettings['admission_exam_maximum_fault_excuses'] || 5 ) - faultCount} excuses left</Typography>}
                            </Box>
                            {AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && <Box display="flex" flexDirection="column" margin="0 10px" borderRadius="5px" alignItems="center" justifyContent="center" bgcolor={palette.contentBg} padding="20px">
                                <Typography marginY="10px" textAlign="center" variant='h6' fontWeight="500" fontSize="16px" color="#ED1C24" >YOU HAVE COMMITTED {faultCount} FAULT(S)!</Typography>
                                <Typography textAlign="center" color="GrayText" fontWeight="bolder" variant='subtitle2'>
                                    Ongoing exam will be submitted automatically once the excuses exceeds the limit!
                                </Typography>
                            </Box>}
                            {!AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && showWarning === "fullscreenexit" && <img src={fullScreenImg} alt="full screen" width="200px" />}
                            {!AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && showWarning === "fullscreenexit" && <Typography color="GrayText" textAlign="center" padding="20px" variant='subtitle2'>Please stay in fullscreen mode for better experiance!</Typography>}
                            {!AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && showWarning === "fullscreenexit" && <Typography color="GrayText" textAlign="center" padding="20px" variant='subtitle2'>Please stay in fullscreen mode for better experiance!</Typography>}
                            {!AdmissionExamStore?.getExam?.exam?.exam_constraints?.enableMonitoring && showWarning === "windowchange" && <Typography color="GrayText" textAlign="center" padding="20px" variant='subtitle2'>Please do not move to different windows while taking exams!</Typography>}
                            <Box padding="20px" display="flex" gap="20px" justifyContent="center" alignItems="center" flexWrap="wrap">
                                <Button disabled={isSubmitting} onClick={async () => {
                                    setShowWarning( null )
                                    if ( examAttemptFullScreenHandle?.active === false )
                                        await examAttemptFullScreenHandle.enter()
                                }} variant='contained' color="customThemeColor" disableElevation sx={{ textTransform: "capitalize" }}>Okay, got it!</Button>
                                <Button onClick={
                                    async () => {
                                        setIsSubmitting( true )
                                        await finishExam( "ManualSubmission", submissions.length, questions.length )
                                        setIsSubmitting( false )
                                    }}
                                    variant='contained' color="secondary" startIcon={isSubmitting && <CircularProgress size={14} />} disabled={isSubmitting} disableElevation sx={{ textTransform: "capitalize" }}>Finish exam now</Button>
                            </Box>
                        </Box>
                    </Card>
                </Box>

                <Button
                    sx={{ position: "fixed", opacity: 0, pointerEvents: "none", width: 0, padding: "0", height: "0" }} onClick={async ( e ) => {
                        try {
                            e?.target?.focus()
                            await examAttemptFullScreenHandle.enter()
                        } catch ( err ) {
                            console.log( err )
                        }
                    }} ref={fullscreeBtnRef}
                ></Button>
                <Box bgcolor="rgba(0,0,0,0.3)" sx={{ position: "fixed", zIndex: "20000", display: finishDialog ? "flex" : "none", alignItems: "center", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center" }} >
                    <Box bgcolor={palette.common.bg} margin="20px" borderRadius="5px">
                        <Typography color={palette.common.font} padding="10px 20px" borderBottom={border[1]} variant='h6'>Are you sure?</Typography>
                        <Box padding="20px">
                            <Typography color={palette.common.font} variant='subtitle2' marginBottom="10px">There is still time left. Do you want to end and submit your exam now?</Typography>
                            <Box display="flex" gap="10px" marginTop="20px" flexWrap="wrap">
                                <Button color='primaryDark' onClick={async () => { setIsSubmitting( true ); await finishExam( "ManualSubmission", submissions.length, questions.length ); setIsSubmitting( false ) }} disableElevation disabled={isSubmitting} variant='contained' sx={{ textTransform: "capitalize" }}>{isSubmitting ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px"> <CircularProgress size={14} /> Submitting your answers</Typography> : "Finish now"}</Button>
                                <Button onClick={() => setFinishDialog( false )} disabled={isSubmitting} variant='contained' disableElevation color="error" sx={{ textTransform: "capitalize" }}>Cancel</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {participation &&
                    <Box sx={examWindowHeaderStyle}>
                        <Box display="flex" alignItems="flex-end" marginBottom="10px" justifyContent="space-between">
                            <Box>
                                <Typography variant='h6' display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }} alignItems="center" justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center" }} noWrap>{exam && exam.exam_name}</Typography>
                                <Typography variant='subtitle2' fontSize="12px">Total marks: {questions?.reduce( ( sum, ques ) => sum + ques.question.question_point, 0 )}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="20px" justifyContent={{ lg: "initial", md: "initial", sm: "space-between", xs: "space-between" }}>
                                <Box textAlign="right" display="flex" flexDirection="column" alignItems="flex-end">
                                    <Typography variant='subtitle2' fontSize="14px">{submissions?.length} / {questions?.length} questions answered</Typography>
                                    <Tooltip title="Answer all the questions" ><span><Button variant='contained' color='errorMessage' size="small" onClick={handleClickOnFinishExam} sx={{ '&.Mui-disabled': { background: "grey" }, textTransform: "capitalize !important", margin: "10px 0 0 10px !important", width: "fit-content", '@media(max-width:350px)': { width: "100% !important" } }}>Finish Exam</Button></span></Tooltip>
                                </Box>
                            </Box>
                        </Box>
                        <CountDown setTimeLeft={setTimeLeft} examDuration={examDuration} finishExam={finishExam} time={remainingTime} />
                    </Box>}
                {allQuestionSections?.length > 1 && <Box width="full" paddingY="10px" bgcolor={palette.common.bg}>
                    <Tabs sx={{ marginLeft: { md: "-20px", xs: "0" } }} variant='scrollable' TabIndicatorProps={{ sx: { height: "4px" } }} scrollButtons value={section} onChange={handleSectionChange} >
                        {allQuestionSections?.map( sectionValue => (
                            <Tab label={sectionValue} value={sectionValue} key={sectionValue} />
                        ) )}
                    </Tabs>
                </Box>}
                {questions && <Box paddingBottom="10px" borderRadius="0 0 10px 10px" display="grid" flexGrow={1} bgcolor={palette.common.bg} overflow="auto" gridTemplateColumns={{ lg: `${showQuestions ? "300px" : "0px"} auto`, md: `${showQuestions ? "250px" : "0px"} auto`, sm: `${showQuestions ? "50px" : "0px"} auto`, xs: `${showQuestions ? "50px" : "0px"} auto` }} sx={{ transition: "0.2s ease-out" }}>
                    <QuestionList showQuestions={showQuestions} questions={filteredQuestions} currentQuestionIndex={currentIndex} submissions={submissions} selectQuestion={selectQuestion} />
                    <AnswerSection handleClickOnFinishExam={handleClickOnFinishExam} allAnswered={questions?.length === submissions?.length} saving={saving} toggleQuestions={toggleQuestions} showQuestions={showQuestions} question={currentQuestion} currentQuestionIndex={currentIndex} nextQuestion={moveToNextQuestion} submitAnswer={saveAnswer} submissions={submissions} finishExam={finishExam} />
                </Box>}
            </Box >}
        </FullScreen>
    )
}

export default AdmissionExamAttempt