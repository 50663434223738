import React, { useCallback, useEffect, useState } from 'react'
import { Typography, Box, Button, FormLabel, FormControl, TextField, RadioGroup, FormControlLabel, Checkbox, Radio, Tooltip, IconButton, useTheme } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import parse from 'html-react-parser'
import Edit from '@mui/icons-material/Edit'
import { EditableMathField } from 'react-mathquill'
import AudioPlayer from './AudioPlayer'
import AudioInput from './AudioInput'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { CancelOutlined, Mic } from '@mui/icons-material'
import { useUserStore } from '../../store/user.store'
import CustomWysiwyg from '../common/CustomWysiwyg'
import Latex from 'react-latex-next'
import { latexDelimiters } from '../../utils/utilities'

const QuestionDetail = ( { selectedQuestion, exam, questionNumber, deleteQuestion, update } ) => {

    const [question, setQuestion] = useState( null )
    const [questionText, setQuestionText] = useState( '' )
    const [questionPlainText, setQuestionPlainText] = useState( '' )
    const [questionHint, setQuestionHint] = useState( null )
    const [questionType, setQuestionType] = useState( null )
    const [questionMarks, setQuestionMarks] = useState( 0 )
    const [questionSelectionType, setQuestionSelectionType] = useState( null )
    const [errorMessages, setErrorMessages] = useState( {} )
    const [newOptions, setNewOptions] = useState( null )
    const [correctAnswer, setCorrectAnswer] = useState( null )
    const [latexOptions, setLatexOptions] = useState( {} )
    const [latex, setLatex] = useState( selectedQuestion?.question?.question_text?.trim() )

    const [options, setOptions] = useState( null )
    const [isUpdating, setIsUpdating] = useState( false )
    const [isDisabled, setIsDisabled] = useState( true )
    const [answers, setAnswers] = useState( {} )
    const [changeOptionEnabled, setChangeOptionEnabled] = useState( false )
    const [plainAnswers, setPlainAnswers] = useState( {} )
    const [multipleCorrectAnswers, setMultipleCorrectAnswers] = useState( {} )
    const [numberOfAnswer, setNumberOfAnswer] = useState( [1] )
    const [isAudioLoading, setIsAudioLoading] = useState( true )
    const [signedUrls, setSignedUrls] = useState( [] )
    const [audioRecorderStatus, setAudioRecorderStatus] = useState( false )
    const [selectedAudioFiles, setSelectedAudioFiles] = useState( [] )

    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const modules = {
        toolbar: [
            [{ header: 1 }, { header: 2 }, { header: [3, 4, 5, 6] }, { font: [] }],
            [{ size: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ]
    }

    const validate = useCallback( () => {
        let questionError, marksError, answersError
        if ( questionPlainText.trim() === '' ) questionError = "Question is required"
        if ( questionMarks < 1 ) marksError = "Minumum marks should be 1"
        if ( changeOptionEnabled ) {
            if ( questionType === "MCQ" && questionSelectionType === 'single' ) {
                if ( !correctAnswer ) answersError = "Please select the correct answer"
                if ( !newOptions || Object.keys( newOptions ).length < 2 ) answersError = "Provide multiple options for the questions"
                if ( newOptions && Object.keys( newOptions ).length > 1 ) {
                    let valid = true
                    for ( const ans in plainAnswers ) {
                        if ( plainAnswers[ans].trim() === '' ) {
                            answersError = "Please fill all the answer fileds!"
                            valid = false
                            break
                        }
                    }
                    if ( !valid || numberOfAnswer.length !== Object.keys( newOptions ).length ) answersError = "Please fill all the answer fileds!"
                }
            } else if ( questionType === "MCQ" && questionSelectionType !== 'single' ) {
                if ( !newOptions || Object.keys( newOptions ).length < 2 ) answersError = "Provide multiple options for the questions"
                if ( newOptions && Object.keys( newOptions ).length > 1 ) {
                    let valid = true
                    for ( const ans in plainAnswers ) {
                        if ( plainAnswers[ans].trim() === '' ) {
                            answersError = "Please fill all the answer fileds!"
                            valid = false
                            break
                        }
                    }
                    if ( !valid || numberOfAnswer.length !== Object.keys( newOptions ).length ) answersError = "Please fill all the answer fileds!"
                    else {
                        let correctAnswersCount = 0
                        let valid = false
                        for ( const answerIndex in multipleCorrectAnswers ) {
                            if ( multipleCorrectAnswers[answerIndex] ) correctAnswersCount++
                            if ( correctAnswersCount > 1 ) {
                                valid = true
                                break
                            }
                        } if ( !valid ) answersError = "Please select multiple correct answers"
                    }
                }
            } else if ( questionType !== "MCQ" ) {
                if ( !newOptions || newOptions[0].trim() === '' ) answersError = "Answers field is required!"
            }
        }
        setErrorMessages( { question: questionError, marks: marksError, answers: answersError } )
        return { question: questionError, marks: marksError, answers: answersError }
    }, [changeOptionEnabled, correctAnswer, multipleCorrectAnswers, newOptions, numberOfAnswer, plainAnswers, questionMarks, questionPlainText, questionSelectionType, questionType] )

    const addAnswerField = () => {
        setNumberOfAnswer( [...numberOfAnswer, numberOfAnswer.length + 1] )
        validate()
    }

    const removeLastOption = () => {
        setCorrectAnswer( null )
        setMultipleCorrectAnswers( {} )
        const lastIndex = numberOfAnswer.length - 1
        const newNbrOfAns = numberOfAnswer.splice( 0, lastIndex )
        delete newOptions[lastIndex]
        setNewOptions( { ...newOptions } )
        setNumberOfAnswer( newNbrOfAns )
    }

    const setNewAnswers = ( number, answer, text ) => {
        const newAnswers = { ...answers, [number]: answer }
        setAnswers( newAnswers )
        console.log( { ...plainAnswers, [number]: text } )
        setPlainAnswers( { ...plainAnswers, [number]: text } )
        return newAnswers
    }

    const retrieveOptions = () => {
        setChangeOptionEnabled( false )
        setNumberOfAnswer( [1] )
        setMultipleCorrectAnswers( {} )
        setCorrectAnswer( null )
        setNewOptions( null )
        setErrorMessages( { ...errorMessages, answers: null } )
    }

    const updateQuestion = async () => {
        const errors = validate()
        if ( !errors.question && !errors.hint && !errors.answers ) {
            setIsUpdating( true )
            const updatedData = { question: questionText, marks: questionMarks, hint: questionHint, questionType, selectionType: questionSelectionType, correctAnswer, multiAnswer: multipleCorrectAnswers, optionsChanged: changeOptionEnabled, newOptions: newOptions }
            const formData = new FormData()
            for ( const name in updatedData ) {
                const val = updatedData[name]
                if ( typeof val === 'object' )
                    formData.append( name, JSON.stringify( val ) )
                else
                    formData.append( name, val )
            }
            if ( selectedAudioFiles.length > 0 ) {
                for ( let j = 0; j < selectedAudioFiles.length; j++ ) {
                    const item = selectedAudioFiles[j]
                    formData.append( 'audioattachment[]', item, item.name )
                }
            }
            const { updatedOptions, success } = await update( formData, selectedQuestion.question.question_id )
            if ( success ) {
                if ( updatedOptions )
                    setOptions( updatedOptions )
                setChangeOptionEnabled( false )
                setNumberOfAnswer( [1] )
                setMultipleCorrectAnswers( {} )
                setCorrectAnswer( null )
                setNewOptions( null )
                setErrorMessages( {} )
                setIsDisabled( true )
                setLatexOptions( {} )
            }
            setIsUpdating( false )
        }
    }

    const removeAudioAttachment = async ( index, key ) => {
        try {
            setIsUpdating( true )
            const updatedData = { updateType: "removeAttachment", attachmentIndex: index, attachmentKey: key }
            await update( updatedData, selectedQuestion.question.question_id )
            setIsUpdating( false )
            console.log( question )
        } catch ( err ) {

        }
    }

    useEffect( () => {
        validate()
        if ( question &&
            ( ( ( questionPlainText.trim() === question.question_text.trim() || questionText.trim() === question.question_text.trim() ) &&
                parseInt( questionMarks ) === parseInt( question.question_point ) &&
                questionHint.trim() === question.question_hint ) ) ) {

            if ( changeOptionEnabled || selectedAudioFiles.length > 0 ) setIsDisabled( false )
            else setIsDisabled( true )
        }
        else setIsDisabled( false )
    }, [question, validate, questionPlainText, questionText, questionMarks, questionHint, changeOptionEnabled, selectedAudioFiles] )

    useEffect( () => {
        if ( selectedQuestion && selectedQuestion.question ) {
            setQuestion( selectedQuestion.question )
            setOptions( selectedQuestion.options )
            setQuestionText( selectedQuestion.question.question_text )
            setLatex( selectedQuestion.question.question_text.trim() )
            setQuestionPlainText( selectedQuestion.question.question_text )
            setQuestionType( selectedQuestion.question.question_type )
            setQuestionHint( selectedQuestion.question.question_hint )
            setQuestionSelectionType( selectedQuestion.question.question_answer_selection )
            setQuestionMarks( selectedQuestion.question.question_point )
            setChangeOptionEnabled( false )
            setNumberOfAnswer( [1] )
            setMultipleCorrectAnswers( {} )
            setCorrectAnswer( null )
            setNewOptions( null )
            setErrorMessages( {} )
            setSelectedAudioFiles( [] )
            setAudioRecorderStatus( false )
        }
    }, [selectedQuestion] )


    useEffect( () => {
        const getAudios = async () => {
            try {
                let signedUrlsList = []
                for ( let i = 0; i < selectedQuestion.question?.audio_attachments_keys.length; i++ ) {
                    const key = selectedQuestion.question.audio_attachments_keys[i]
                    const { data: signedUrl } = await api.getAudioPresignedUrl( exam.exam_id, selectedQuestion.question.question_id, { key } )
                    signedUrlsList.push( signedUrl )
                }
                setSignedUrls( signedUrlsList )
            } catch ( err ) {
                if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                    UserStore.setSessionState( "ended" )
                }
                else
                    toast( "Error occured while fetching audio attachments" )
            } finally {
                setIsAudioLoading( false )
            }
        }
        getAudios()
    }, [selectedQuestion, exam, UserStore] )

    return (
        <Box className="custom-scrollbar" overflow="auto" height="100%" flexGrow={1}>
            {question && <Box display="flex" flexDirection="column" gap="10px" alignItems="flex-start" bgcolor={palette.contentBg} padding="20px">
                {/* <Typography variant='h6'>Question-{questionNumber}</Typography> */}
                <form autoComplete='off' style={{ width: "100%", paddingRight: "20px" }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontSize: "14px !important", marginBottom: "10px" }}>Question*</FormLabel>
                        {questionText.includes( 'LATEX-' ) ? <EditableMathField sx={{ width: "100%" }} latex={latex.split( "LATEX-" )[1]} onChange={( value ) => {
                            setLatex( `LATEX-${value.latex()}` )
                            setQuestionText( `LATEX-${value.latex()}` )
                            setQuestionPlainText( `LATEX-${value.latex()}` )
                        }}></EditableMathField> : <CustomWysiwyg height='100px' value={latex || ""} onChange={( e, textVal ) => {
                            setLatex( e )
                            setQuestionText( e )
                            setQuestionPlainText( textVal )
                        }} />}
                        {latex?.match( /\$(.)+\$/ ) && <Box marginTop="10px" className='wysiwyg-text-container' padding="10px" border={border[1]} borderRadius="10px">
                            <Latex delimiters={latexDelimiters} >{latex}</Latex>
                        </Box>}
                        <Typography variant="body2" color="errorMessage.main" sx={{ fontWeight: "bold", margin: "0 0 0 0" }}>{errorMessages.question ? errorMessages.question : ""}</Typography>
                    </FormControl>
                    {question?.audio_attachments.length > 0 && !isAudioLoading && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center" padding="10px 0">
                        {question?.audio_attachments.map( ( attachment, index ) => (
                            <Box border="1px solid #d3d3d3" display="flex" alignItems="center" key={index}>
                                <AudioPlayer audioName={attachment} index={index} audioSrc={signedUrls[index]} />
                                <Tooltip title="Remove this attachment">
                                    <IconButton onClick={() => removeAudioAttachment( index, question?.audio_attachments_keys[index] )} color="error" size="small">
                                        <CancelOutlined fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ) )}
                    </Box>}
                    {question?.audio_attachments.length === 0 && !isAudioLoading && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center" padding="10px 0">
                        {!audioRecorderStatus && <Button color="secondary" onClick={() => setAudioRecorderStatus( !audioRecorderStatus )} sx={{ fontSize: "12px", textTransform: "capitalize" }} size="small" startIcon={<Mic sx={{ fontSize: "14px" }} />}>Add audio attachment</Button>}
                        {audioRecorderStatus &&
                            <FormControl margin='normal' fullWidth>
                                <FormLabel sx={{ fontSize: "14px", marginBottom: "10px" }}>Question audio attachment</FormLabel>
                                <AudioInput container="bordered-box" setRecordedCardStatus={setAudioRecorderStatus} selectedAudioFiles={selectedAudioFiles} setSelectedAudioFiles={setSelectedAudioFiles} maxLength={5} />
                            </FormControl>
                        }
                    </Box>}
                    <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>{questionHint ? "Hint" : "Add an hint"}</Typography>
                        <TextField size="small" sx={{ fontSize: "14px !important" }} value={questionHint ? questionHint : ""} onChange={e => { setQuestionHint( e.target.value ) }} InputLabelProps={{ sx: { fontSize: "14px !important" } }} name="hint" />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Marks *</Typography>
                        <TextField size="small" sx={{ fontSize: "14px !important" }} onWheel={( e ) => e.target.blur()} value={questionMarks} onChange={e => { setQuestionMarks( e.target.value ) }} InputLabelProps={{ sx: { fontSize: "14px !important" } }} type="number" name="marks" />
                        <Typography variant="body2" color="errorMessage.main" sx={{ fontWeight: "bold", margin: "-5px 0 0 0" }}>{errorMessages.marks ? errorMessages.marks : ""}</Typography>
                    </FormControl>
                    {!changeOptionEnabled && questionType === "MCQ" && <Box>
                        <Typography marginTop="10px" variant='h6'>Options <Button onClick={() => setChangeOptionEnabled( true )} startIcon={<Edit sx={{ fontSize: "14px !important" }} />} sx={{ textTransform: "capitalize", fontSize: "12px !important" }}>Change options</Button> </Typography>
                        {options.map( ( option, index ) => (
                            <Box key={option.answer_id} display="flex" alignItems="center" marginTop="10px" gap="20px" bgcolor={option.answer_status === 1 ? "green" : "white"} border={option.answer_status === 1 ? "2px solid green" : "2px solid grey"} borderRadius="5px" padding="10px ">
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", background: option.answer_status === 1 ? "white" : "rgb(48,48,48)", color: option.answer_status !== 1 ? "white" : "rgb(48,48,48)", fontWeight: "bold", width: "30px", height: "30px" }}>{index + 1}</Typography>
                                <Box gap="10px" display="flex" alignItems="center" sx={{ color: option.answer_status === 1 ? "white" : "black", fontWeight: option.answer_status === 1 ? "bold" : "normal" }}>
                                    {option.answer_text.trim().includes( 'LATEX-' ) ? <EditableMathField id='static-latex' latex={option.answer_text.trim().split( "LATEX-" )[1]} /> : <Latex delimiters={latexDelimiters} >{option.answer_text.trim().replace( "LATEX-", "" )}</Latex>}
                                </Box>
                            </Box>
                        ) )}
                    </Box>}
                    {!changeOptionEnabled && questionType !== "MCQ" && <Box>
                        <Typography marginTop="10px" variant='h6'>Answer <Button onClick={() => setChangeOptionEnabled( true )} startIcon={<Edit sx={{ fontSize: "14px !important" }} />} sx={{ textTransform: "capitalize", fontSize: "12px !important" }}>Change answer</Button> </Typography>
                        <Box key={options[0].answer_id} sx={{ marginBottom: "5px", display: "flex", gap: "5px" }}>{parse( options[0].answer_text )}</Box>

                    </Box>}
                    {changeOptionEnabled && questionType === "MCQ" && <RadioGroup name='correctAnswer' onChange={( e ) => { setCorrectAnswer( e.target.value ) }} value={correctAnswer}>
                        <Typography variant='h6' display="flex" gap="10px" alignItems={{ lg: "center", md: "center", sm: "flex-start", xs: "flex-start" }} flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }} marginTop="20px">Add new options<Button variant='outlined' color='error' onClick={retrieveOptions} startIcon={<RefreshIcon color='error' sx={{ fontSize: "14px !important" }} />} sx={{ textTransform: "capitalize", fontSize: "12px !important" }}>Retrieve original options</Button></Typography>
                        {numberOfAnswer.map( ( i, index ) => (
                            <FormControl key={index} fullWidth>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Answer {index === 0 ? ' ' : index + 1}*</Typography>
                                    {questionType === 'MCQ' && questionSelectionType === 'single' && <FormControlLabel value={`${index}`} control={<Radio />} labelPlacement="end" label="Correct answer" />}
                                    {questionType === 'MCQ' && questionSelectionType !== 'single' && <FormControlLabel value={`${index}`} control={<Checkbox name='correctAnswer' onChange={e => { setMultipleCorrectAnswers( { ...multipleCorrectAnswers, [index]: e.target.checked } ) }} />} labelPlacement="end" label="Correct answer" />}
                                </Box>
                                {latexOptions[index]?.startsWith( 'LATEX-' ) ? <EditableMathField
                                    latex={latexOptions[index] ? latexOptions[index] : ""}
                                    onChange={( mathField ) => {
                                        // setFieldValue( 'answers', setNewAnswers( index, mathField.latex(), mathField.latex().replace( /\\\\/g, "" ) ) )
                                        setNewOptions( setNewAnswers( index, mathField.latex(), mathField.latex().replace( /\\\\/g, "" ) ) )
                                        setLatexOptions( { ...latexOptions, [index]: mathField.latex() } )
                                    }}
                                    placeholder='Option goes here...'
                                /> : <CustomWysiwyg placeholder="Option goes here..." value={latexOptions[index] || ""} height='45px' onChange={( val, textVal ) => {
                                    setNewOptions( setNewAnswers( index, val, textVal ) )
                                    setLatexOptions( { ...latexOptions, [index]: val } )
                                }} />}
                                {latexOptions[index]?.match( /\$(.)+\$/ ) && <Box marginTop="10px" className='wysiwyg-text-container' padding="10px" border={border[1]} borderRadius="10px">
                                    <Latex delimiters={latexDelimiters} >{latexOptions[index]}</Latex>
                                </Box>}
                            </FormControl> ) )}
                        <Typography variant="body2" color="errorMessage.main" sx={{ fontWeight: "bold", margin: "5px 0 5px 0" }}>{errorMessages.answers ? errorMessages.answers : ""}</Typography>
                        {questionType === 'MCQ' && <Box>
                            <Button disableElevation variant='contained' onClick={addAnswerField} sx={{ textTransform: "capitalize", marginTop: "10px", marginBottom: "10px", width: "fit-content" }} color='warning' >Add another answer option</Button>
                            {numberOfAnswer.length > 2 && <Button onClick={removeLastOption} variant='outlined' sx={{ textTransform: "capitalize", marginTop: "10px", marginLeft: "10px", marginBottom: "10px", width: "fit-content" }} color='error'>Remove last option</Button>}
                        </Box>}
                    </RadioGroup>}
                    {questionType !== "MCQ" && <Box>
                        <FormLabel sx={{ fontSize: "14px", margin: "10px 0" }}>Answer*</FormLabel>
                        <CustomWysiwyg value={question.question_id} modules={modules} onChange={( e, textVal ) => { setNewAnswers( 0, e, textVal ) }} />
                    </Box>
                    }
                    <Box display="flex" gap="10px" marginTop="15px" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }}>
                        <Button disableElevation onClick={updateQuestion} disabled={isUpdating || isDisabled} sx={{ textTransform: "capitalize" }} variant="contained">{isUpdating ? "Updating..." : "Update"}</Button>
                        <Button disableElevation onClick={() => deleteQuestion( question.question_id )} variant='contained' color='error' sx={{ textTransform: "capitalize" }}>Delete Question</Button>
                    </Box>
                </form>
            </Box >}
        </Box >
    )
}

export default QuestionDetail

