import React, { useEffect, useState } from 'react'
import { Typography, Box, Button, Paper, useTheme, Dialog } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import thankYouImg from '../../assets/thank-you-exam-completion.svg'
import api from '../../service/api'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import { showDateInIndianDateFormat } from '../../utils/utilities'
import Latex from 'react-latex-next'

const CandidateParticipationDetails = observer( () => {
    const { pid, id } = useParams()
    const [answers, setAnswers] = useState( null )
    const [questions, setQuestions] = useState( null )
    const [showResultDialog, setShowResultDialog] = useState( true )
    const [participation, setParticipation] = useState( null )
    const [inProgress, setInProgress] = useState( true )

    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const getAnswerText = ( id, options ) => {
        for ( let i = 0; i < options.length; i++ ) {
            if ( id === options[i].answer_id )
                // return options[i].answer_text.includes( "LATEX-" ) ? <EditableMathField id="static-latex" style={{ color: "inherit !important", padding: "0" }} latex={options[i].answer_text.split( "LATEX-" )[1]} /> : parse( options[i].answer_text )
                return <Latex>{options[i].answer_text?.replace( /LATEX-/gi, "" )}</Latex>
        }
    }


    useEffect( () => {
        const getData = async () => {
            try {
                const { data: participationDataRes } = await api.admissionExams.fetchParticipationData( id, pid )
                const { data: submissionDataRes } = await api.admissionExams.fetchParticipationSubmissions( id, pid )
                const { data: questionsDataRes } = await api.admissionExams.fetchParticipationQuestions( id, pid )
                const participationData = participationDataRes.data || participationDataRes
                const submissionData = submissionDataRes.data || submissionDataRes
                const questionsData = questionsDataRes.data || questionsDataRes

                let subs = {}
                submissionData.forEach( sub => {
                    subs[sub.question_id] ? subs[sub.question_id].push( sub ) : subs[sub.question_id] = [sub]
                } )
                setAnswers( subs )
                setQuestions( questionsData )
                setParticipation( participationData )
            } catch ( e ) {
                console.log( e )
                if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                    UserStore.setERPSessionState( "ended" )
                }
                else
                    toast( e?.response?.data?.message || "Something went wrong! try again later" )
            } finally {
                setInProgress( false )
            }
        }

        getData()
    }, [pid, id, UserStore] )

    return (
        <Box minHeight={{ md: "500px", xs: "900px" }} sx={{ padding: "20px" }} flexGrow={1} alignItems="stretch" display="flex" flexDirection="column" overflow="hidden">

            {participation && showResultDialog && <Dialog open={showResultDialog} >
                <Box padding="20px" alignItems="center" display="flex" flexDirection="column" gap="20px">
                    <img width="180px" src={thankYouImg} alt="thankyou" />
                    {!inProgress ? <Box>
                        <Typography textAlign="center" variant='subtitle2'>Submitted your answers successfully! and your score is</Typography>
                        <Typography textAlign="center" variant='h6'>{participation.participation.exam_participation_score ? participation.participation.exam_participation_score : 0} out of {participation.marks.sum}</Typography>
                    </Box> : <DataLoadingSpinner waitingMessage="Loading your score..." />}
                    <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} onClick={e => setShowResultDialog( false )} color='secondary'>Okay</Button>
                </Box>
            </Dialog>}

            {participation && !inProgress && !showResultDialog && <Box flexGrow={{ sm: "1", xs: "initial" }} display="flex" flexDirection={{ lg: "row-reverse", md: "row-reverse", sm: "column", xs: "column" }} overflow="auto" gap="20px">
                <Paper sx={{ flexGrow: "1", position: "relative", display: "flex", flexDirection: "column", borderRadius: "5px", overflow: "auto" }} >
                    <Box
                        sx={{
                            position: 'relative',
                            background: `url('/imgs/subjects-header-bg.jpg')`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                        color="white"
                        gap="20px"
                        display="flex"
                        flexDirection={{ md: "row", xs: "column" }}
                        justifyContent="space-between"
                        alignItems={{ md: "center", xs: "flex-start" }}
                        padding="20px"
                    >
                        <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}></Box>
                        <Box sx={{ zIndex: 10, position: "relative" }}>
                            <Typography variant='h5' textTransform="capitalize">  {participation && participation.participation.exam_title}</Typography>
                            <Typography variant='body2' fontSize="14px"> {`${showDateInIndianDateFormat( participation.participation.exam_participation_start_time )}`}</Typography>
                            <Typography variant='subtitle2' fontSize="12px" >{`${new Date( participation.participation.exam_participation_start_time ).toLocaleTimeString()}`} - {`${new Date( participation.participation.exam_participation_end_time ).toLocaleTimeString()}`}</Typography>
                        </Box>
                        <Typography sx={{ zIndex: 10, position: "relative" }} variant='body2' textTransform="capitalize"> Attempt: {participation.participation.exam_participation_attempt}</Typography>
                        <Typography sx={{ zIndex: 10, position: "relative" }} bgcolor="#D9D9D914" border="1px solid #fff" textAlign="center" borderRadius="5px" padding="5px 20px" fontWeight="bold" fontSize="16px" variant='h6'>{participation.participation.exam_participation_score ? participation.participation.exam_participation_score : 0} out of {participation.marks.sum}</Typography>

                    </Box>
                    <Box display="flex" flexGrow={1} flexDirection="column" gap="10px" margin="20px" bgcolor={palette.contentBg} border={border[1]} borderRadius="5px" className="custom-scrollbar" overflow="auto">
                        <Box padding="20px 20px 0 20px" overflow="auto" className="custom-scrollbar">
                            {questions && answers && questions.map( ( data, index ) => {
                                return (
                                    <Box maxWidth="800px" key={index} paddingBottom="10px">
                                        <Box sx={{ fontWeight: "600", "& p": { margin: 0, display: "inline-block" } }} >
                                            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                                                <Typography variant='body2' fontSize="12px" >Question {index + 1}</Typography>
                                                {/* {answers[data.question.question_id] &&
                                                    <Box display="flex" justifyContent="center" >
                                                        {( answers[data.question.question_id].length > 1 || ( answers[data.question.question_id][0].submission_score !== null && answers[data.question.question_id][0].submission_score >= 0 ) )
                                                            ? <Box color={answeredCorrectly ? "#E4586C" : "#22DD3B"} fontWeight="bold" sx={{ display: "flex !important", gap: "10px", alignItems: "center", marginX: "10px !important", marginY: { lg: "0 !important", md: "0 !important", sm: "10px !important", xs: "10px !important" } }} fontSize="12px">
                                                                <Typography textAlign="center" fontSize="14px" sx={{ width: "20px", display: "flex", alignItems: "center", color: "white", justifyContent: "center", borderRadius: "50%", margin: 0, padding: 0, aspectRatio: 1 / 1 }} bgcolor={!answeredCorrectly ? "#22DD3B" : "#E4586C"} >
                                                                    {( answers[data.question.question_id] && answers[data.question.question_id][0].submission_score ) ? answers[data.question.question_id][0].submission_score : 0}
                                                                </Typography> OUT OF {data.question.question_point}
                                                            </Box>
                                                            : <Tooltip title="Yet to be evaulated" sx={{ marginLeft: "10px !important" }}>
                                                                <AccessTimeFilledIcon color='error' fontSize='small' />
                                                            </Tooltip>
                                                        }
                                                    </Box>} */}
                                            </Box>
                                            <Box className='wysiwyg-text-container' fontSize="16px" width="100%" sx={{ wordBreak: "break-word" }} fontWeight={600} textAlign="justify">
                                                <Latex>{data.question.question_text.trim()?.replace( /LATEX-/gi, "" )}</Latex>
                                                {/* {data.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={data.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : parse( data.question.question_text.trim() )} */}
                                            </Box>
                                        </Box>
                                        <Box alignItems="center">
                                            <Box border={border[1]} borderColor={answers[data.question.question_id] && palette.success.dark} display="flex" borderRadius="5px" sx={{ padding: "10px", margin: "10px 0", fontSize: "14px", "& p": { margin: 0 } }} flexDirection={"column"} gap="10px" flexWrap="wrap" alignItems={"flex-start"}>
                                                {answers[data.question.question_id] && <Typography variant='subtitle2' color="secondary" fontSize="12px"><em><strong>Your answer:</strong></em> </Typography>}
                                                {answers[data.question.question_id] && answers[data.question.question_id].map( ( ans, index ) => (
                                                    ans.answer_id ? <Box key={ans.answer_id} borderRadius="5px" fontSize="14px" sx={{ fontWeight: "bold" }}>{getAnswerText( ans.answer_id, data.options )}</Box> : <Latex> {ans.answer_descriptive}</Latex>
                                                )
                                                )
                                                }
                                                {!answers[data.question.question_id] && <Typography variant='subtitle2' fontSize="12px" color="errorMessage.main">Not answered</Typography>}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            } )}
                        </Box>
                    </Box>
                </Paper>
            </Box>}
            {inProgress && <Paper>
                <DataLoadingSpinner waitingMessage="Loading details..." />
            </Paper>}
        </Box >
    )
} )

export default CandidateParticipationDetails