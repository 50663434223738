import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, Select, Typography, useTheme } from '@mui/material'
import { useOrganizationStore } from '../../../store/organization.store'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import * as Yup from 'yup'

const AddRule = ( { dialogState, setDialogState, getRules } ) => {

    const [loadingInstitutes, setLoadingInstitut] = useState( true )
    const [allDepartments, setAllDepartments] = useState( [] )
    const [loadingDepts, setLoadingDepts] = useState( false )
    const [academicYears, setAcademicYears] = useState( [] )
    const [addingRule, setAddingRule] = useState( false )
    const [departments, setDepartments] = useState( [] )
    const OrganizationStore = useOrganizationStore()

    const { palette } = useTheme()

    const initialValues = {
        academic_year: '-',
        institute_code: '-',
        department_assignment_id: null,
        department_code: null,
        department: "-",
        session_type: 'year'
    }

    const validationSchema = Yup.object().shape( {
        institute_code: Yup.string().not( ["-"], "Select institute" ).required( "Institute is required" ),
        academic_year: Yup.string().required( "Academic year is required" ).test( "CHECK_ACADEMIC_YEAR_FORMAT", "Invalid academic year format", val => {
            if ( val && val.trim() !== "" ) {
                if ( val.match( /^(\d{4}-\d{4})$/ ) ) return true
                return false
            } else return false
        } ),
        department: Yup.string().not( ["-"], "Select Department" ).required( "Department is required" ),
        department_assignment_id: Yup.string().required( "Department is required" ),
        department_code: Yup.string().required( "Department is required" ),
        session_type: Yup.string().oneOf( ["year", "semester"] )
    } )

    const handleDepartmentChange = async ( e, setValue ) => {
        try {
            setValue( 'department', e.target.value )
            setValue( 'department_assignment_id', e.target.value.split( "_" )[1] )
            setValue( 'department_code', e.target.value.split( "_" )[0]?.trim() )
        } catch ( e ) {
            console.log( e )
        } finally {
            setLoadingDepts( false )
        }
    }

    const handleInstituteChange = async ( e, setValue ) => {
        try {
            setLoadingDepts( true )
            const instituteDepartments = allDepartments.filter( item => item.institute_name_short.trim() === e.target.value.trim() )
            setDepartments( instituteDepartments )
            setValue( 'department_department', "-" )
            setValue( 'department_assignment_id', "" )
            setValue( 'department_code', "" )
            setValue( 'institute_code', e.target.value )
        } catch ( e ) { } finally {
            setLoadingDepts( false )
        }
    }

    const addRule = async ( values, { resetForm } ) => {
        try {
            setAddingRule( true )
            await api.createAcademicRule( values )
            await getRules()
            resetForm()
            setDialogState( false )
            toast.success( "Rule added successfully" )
        } catch ( err ) {
            if ( err.response )
                toast( err.response?.data?.message || "Error occured while adding new rule!" )
            else
                toast( "Error occured while adding new rule!" )
        } finally {
            setAddingRule( false )
        }
    }

    useEffect( () => {
        const getInstitutes = async () => {
            try {
                if ( OrganizationStore.getOrganization?.length === 0 )
                    await OrganizationStore.fetchOrganization()
                const { data } = await api.fetchAcademicChecklistDepartments()
                const { data: { data: academicYearsList } } = await api.fetchAllAcademicYears()
                setAcademicYears( academicYearsList )
                setAllDepartments( data.data )
                setLoadingInstitut( false )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fatching institutes and departments data!" )
            }
        }
        getInstitutes()
    }, [OrganizationStore] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "800px" } }} open={dialogState}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Add new academic rule</DialogTitle>
            <DialogContent>
                <Formik onSubmit={addRule} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => {
                        return (
                            <Form>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="institute"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Institute * </Typography></label>
                                    <Field size="small" onChange={( e ) => { handleInstituteChange( e, setFieldValue ) }} as={Select} sx={{ fontSize: "14px", textTransform: "capitalize" }} name="institute_code" id="institute" >
                                        <MenuItem disabled dense value="-">{loadingInstitutes ? <Typography fontSize="14px" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> Loading institutes... </Typography> : "Select Institute"}</MenuItem>
                                        {!loadingInstitutes && OrganizationStore.getOrganization.map( int => (
                                            <MenuItem dense value={int.institute_name_short} sx={{ fontSize: "14px", textTransform: "capitalize" }} key={int.institute_name_short} >{( int.institute_name ).toLowerCase()}</MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography fontSize="12px" fontWeight="500" color="error"> <ErrorMessage name="institute_code" /> </Typography>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="department"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Department * </Typography></label>
                                    <Field size="small" as={Select} onChange={( e ) => handleDepartmentChange( e, setFieldValue )} sx={{ fontSize: "14px", textTransform: "capitalize" }} name="department" id="department" >
                                        <MenuItem dense disabled value="-">{loadingDepts ? <Typography fontSize="14px" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> Loading departments... </Typography> : "Select Department"}</MenuItem>
                                        {values.institute_code === "-" && <Typography sx={{ pointerEvents: "none" }} padding="10px" variant='subtitle2' color="GrayText" >
                                            Please select an <b> <i>institute</i> </b> to browse departments.
                                        </Typography>}
                                        {!loadingDepts && departments.length > 0 && departments.map( dept => (
                                            <MenuItem dense value={`${dept.course_name?.trim()} - ${dept.course_branch_short_name?.trim()}_${dept.course_branch_assignment_id?.trim()}`} sx={{ fontSize: "14px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center" }} key={dept.course_branch_assignment_id} >{dept.course_name.toLowerCase()} {" - "} <b> {dept.course_branch_short_name}</b> </MenuItem>
                                        ) )}
                                        {!loadingDepts && values.institute_code !== "-" && departments.length === 0 && <Typography sx={{ pointerEvents: "none" }} padding="10px" variant='subtitle2' color="GrayText" >
                                            No <b> <i>departments</i> </b> exists for selected institute contact LMS team.
                                        </Typography>}
                                    </Field>
                                    <Typography fontSize="12px" fontWeight="500" color="error"> <ErrorMessage name="department" /> </Typography>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="ac-year"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Academic Year of admission * </Typography></label>
                                    <Field size="small" as={Select} sx={{ fontSize: "14px", textTransform: "capitalize" }} placeholder="YYYY-YYYY" name="academic_year" id="ac-year" >
                                        <MenuItem dense value={"-"} disabled sx={{ fontSize: "12px" }} >
                                            Select academic year of admission
                                        </MenuItem>
                                        {academicYears.map( ( year ) => (
                                            <MenuItem dense value={year.ac_year} sx={{ fontSize: "12px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center", }} key={year.ac_year_id} >
                                                {year.ac_year}
                                            </MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography fontSize="12px" fontWeight="500" color="error"> <ErrorMessage name="academic_year" /> </Typography>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="session-type"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Academic Year of admission * </Typography></label>
                                    <Field size="small" as={Select} sx={{ fontSize: "14px", textTransform: "capitalize" }} name="session_type" id="session-type" >
                                        <MenuItem dense value="year">Year wise</MenuItem>
                                        <MenuItem dense value="semester">Semester wise</MenuItem>
                                    </Field>
                                    <Typography fontSize="12px" fontWeight="500" color="error"> <ErrorMessage name="session_type" /> </Typography>
                                    <Typography fontSize="12px" fontWeight="500" color="error"> <ErrorMessage name="session_type" /> </Typography>
                                </FormControl>
                                <Box display="flex" gap="10px" alignItems="center" flexWrap='wrap' marginTop="40px">
                                    <Button disabled={addingRule} disableElevation startIcon={addingRule ? <CircularProgress size={14} /> : Icons.default.AddIcon} variant='contained' color="primary" sx={{ textTransform: "capitalize" }} type='submit' >{addingRule ? "Adding new rule" : "Add rule"}</Button>
                                    <Button disabled={addingRule} disableElevation startIcon={Icons.default.CloseIcon} onClick={() => setDialogState( false )} variant='outlined' color="error" sx={{ textTransform: "capitalize" }}  >Cancel</Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

export default AddRule
