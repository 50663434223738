import { Box, Button, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import noAnnouncementsImg from '../../../assets/no-announcements.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import api from '../../../service/api'
import CreateOrEditAnnouncementDialog from '../../../components/admin/announcements/CreateOrEditAnnouncementDialog'
import TryAgainMessageBlock from '../../../components/common/TryAgainMessageBlock'
import TableHeader from '../../../components/common/TableHeader'
import { useOrganizationStore } from '../../../store/organization.store'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import CodeButton from '../../../components/common/CodeButton'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useUserStore } from '../../../store/user.store'
import BlockMessage from '../../../components/common/BlockMessage'

const columns = [
    { name: "Title", padding: "10px 20px 10px 40px", minWidth: "200px" },
    { name: "Created on", padding: "10px 20px", },
    { name: "Duration", padding: "10px 20px", },
    { name: "Status", padding: "10px 20px", },
    { name: "Institute", padding: "10px 20px", },
    { name: "For", padding: "10px 40px 10px 20px", },
]

const Announcements = observer( () => {

    const [announcements, setAnnouncements] = useState( [] )
    const [filterInstitute, setFilterInstitute] = useState( "all" )
    const [hasPermission, setHasPermission] = useState( true )
    const [mode, setMode] = useState( 'manage' )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { palette, border, table } = useTheme()

    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()

    const getAnnouncements = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncements( { purpose: 'listing' } )
            setAnnouncements( data )
            setError( null )

        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching announcements!" } )
        } finally {
            setLoading( false )
        }
    }, [] )

    const filterFunc = announcement => {
        if ( filterInstitute === 'all' ) return true
        return announcement.institute_id?.toUpperCase() === filterInstitute
    }

    const handleCreateOrEditDialogClose = () => {
        setMode( "manage" )
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.announcements ) {
            setHasPermission( false )
        } else
            getAnnouncements()
    }, [getAnnouncements, UserStore] )

    useEffect( () => {
        const getInstitutes = async () => {
            await OrganizationStore.fetchOrganization()
        }
        if ( OrganizationStore.getOrganization?.length === 0 )
            getInstitutes()
    }, [OrganizationStore] )

    return hasPermission ? (
        <Box padding="20px" overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
            {mode !== "manage" && <CreateOrEditAnnouncementDialog getUpdatedData={getAnnouncements} handleClose={handleCreateOrEditDialogClose} state={mode !== "manage"} />}
            <Paper sx={{ flexGrow: 1, overflow: "clip", display: "flex", flexDirection: "column" }}>
                <Box padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box display="flex" gap="20px" justifyContent="space-between">
                        <Box>
                            <Typography variant='h5'>Announcements</Typography>
                            <Typography variant='subtitle2' color="textSecondary">Manage announcements across the institutes</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center">
                            <Select renderValue={() => filterInstitute?.toUpperCase()} value={filterInstitute} size="small" onChange={e => setFilterInstitute( e.target.value )} >
                                <MenuItem value="all" id="all" sx={{ fontSize: "14px" }} dense >
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <strong>[ALL]</strong>
                                        <Typography marginLeft="5px" variant='subtitle2' fontSize="12px">All institutes</Typography>
                                    </Box>
                                </MenuItem>
                                {OrganizationStore.getOrganization.map( institute => (
                                    <MenuItem sx={{ fontSize: "14px" }} value={institute.institute_name_short} key={institute.institute_name_short} id={institute.institute_name_short}>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <strong>[{institute.institute_name_short}]</strong>
                                            <Typography textTransform="capitalize" marginLeft="5px" variant='subtitle2' fontSize="12px">{institute.institute_name?.toLowerCase()}</Typography>
                                        </Box>
                                    </MenuItem>
                                ) )}
                            </Select>
                            <Box display="flex" alignItems="center">
                                <Button disableElevation sx={{ textTransform: "capitalize" }} variant="contained" startIcon={Icons.default.AddIcon} color="primary" onClick={() => setMode( "create" )}>
                                    Create new Announcement
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {!loading && !error && announcements.filter( filterFunc ).length > 0 &&
                    <Box flexGrow={1} margin="20px" borderRadius="5px" border={border[1]} overflow="clip" display="flex" flexDirection="column" bgcolor={palette.contentBg}>
                        <TableContainer sx={{ flexGrow: 1 }}>
                            <Table>
                                <TableHeader columns={columns} />
                                <TableBody>
                                    {announcements.filter( filterFunc ).map( announcement => (
                                        <TableRow sx={{ cursor: "pointer", '&:hover': { background: palette.rowHover } }} className='table-row' key={announcement.id}>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[0].padding || "10px 20px" }} color={table.color} variant='h6' fontSize="14px" fontWeight="500">{announcement.announcement_title}</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[1].padding || "10px 20px" }} color={table.color} fontSize="12px" variant='subtitle2'> {showDateInIndianDateFormat( announcement.announcement_created_at, { month: "short" } )}</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[2].padding || "10px 20px" }} color={table.color} fontSize="12px" variant='subtitle2'><em>{showDateInIndianDateFormat( announcement.announcement_starts_at, { month: "short", showTime: true } )}-{showDateInIndianDateFormat( announcement.announcement_ends_at, { month: "short", showTime: true } )}</em></Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[3].padding || "10px 20px" }} color={announcement.announcement_status === 'published' ? "success.main" : announcement.announcement_status === 'draft' ? "greyed.main" : table.color} fontSize="12px" variant='subtitle2'><em>{announcement.announcement_status}</em></Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[4].padding || "10px 20px" }} color={table.color} fontSize="12px" variant='subtitle2'> <CodeButton size="small">{announcement.institute_id}</CodeButton> </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ padding: "0" }}>
                                                <Link className='default-link' to={`/admin/announcement/${announcement.id}`}>
                                                    <Typography sx={{ padding: columns[5].padding || "10px 20px" }} color={table.color} fontSize="12px" variant='subtitle2'><em>{announcement.announcement_for?.join( ", " )}</em></Typography>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
                {!loading && !error && announcements.filter( filterFunc ).length === 0 &&
                    <Box padding="20px" flexGrow={1} justifyContent="center" display="flex" alignItems="center" flexDirection="column" gap="20px" bgcolor={palette.contentBg}>
                        <img src={noAnnouncementsImg} width="200px" alt="No subadmins added" />
                        <Typography variant='subtitle2' color="textSecondary">No Announcements {filterInstitute !== "all" && "for selected institute"}!</Typography>
                        <Button color='secondary' onClick={() => setMode( "create" )} startIcon={Icons.default.AddIcon} variant='outlined' sx={{ textTransform: "capitalize" }}>Create One</Button>
                    </Box>
                }
                {error && <Box justifyContent="center" display="flex" flexDirection="column" flexGrow={1}>
                    <TryAgainMessageBlock getData={getAnnouncements} err={error.message} code={error.code} loading={loading} />
                </Box>}
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading announcements..." />}
            </Paper>
        </Box> ) : ( < Box padding="20px" >
            <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
        </Box > )
} )

export default Announcements
