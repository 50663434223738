import { Box, Chip, CircularProgress, IconButton, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { Check, CheckBoxOutlineBlank, CheckBoxOutlined, Close, DeleteOutlined, EditOutlined } from '@mui/icons-material'
import ChecklistItemEditDialog from './ChecklistItemEditDialog'
import ConfirmDialog from '../../common/ConfirmDialog'
import React, { useState } from 'react'

const ChecklistTableRow = ( { showShadow, rule, item, deleteChecklist, getData, setItemsSelectedForDeletion, itemsSelectedForDeletion } ) => {

    const { palette, theme } = useTheme()
    const [deleting, setDeleting] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )

    const handleCheckboxChange = state => {
        if ( !state ) {
            setItemsSelectedForDeletion( [...itemsSelectedForDeletion, item.id] )
        } else {
            let newList = [...itemsSelectedForDeletion]
            const index = newList.indexOf( item.id )
            newList.splice( index, 1 )
            setItemsSelectedForDeletion( newList )
        }
    }

    const handleDelete = async () => {
        setDeleting( true )
        await deleteChecklist( [item.id] )
        setDeleting( false )
        setDeleteConfirmDialogState( false )
    }

    return (
        <TableRow
            className='table-row'
            sx={{
                background: palette.background.paper,
                cursor: "pointer",
                position: "relative",
                zIndex: 3,
                "&:hover .show-on-hover": {
                    display: "flex"
                },
                "&:hover": {
                    background: palette.rowHover
                },
                "&:hover .darken-on-hover": {
                    color: "GrayText"
                }
            }}>
            {/* Dialogs */}
            <ChecklistItemEditDialog getData={getData} state={editMode} rule={rule} setState={setEditMode} data={item} />
            <ConfirmDialog actionName="Delete" message={`Do you really want to delete ${item.input_label}`} confirmAction={handleDelete} status={deleteConfirmDialogState} cancelAction={() => setDeleteConfirmDialogState( false )} />

            {/* Content */}
            <TableCell sx={{ paddingY: "10px" }}>
                <Box display="flex" gap="10px" alignItems="center">
                    <IconButton className='show-on-hover' sx={{ borderRadius: "0", padding: "0", display: itemsSelectedForDeletion.includes( item.id ) ? "flex" : "none" }} onClick={() => handleCheckboxChange( itemsSelectedForDeletion.includes( item.id ) )}>
                        {itemsSelectedForDeletion.includes( item.id ) ? <CheckBoxOutlined className='darken-on-hover' fontSize='small' /> : <CheckBoxOutlineBlank className='darken-on-hover' sx={{ color: "lightgray" }} fontSize='small' />}
                    </IconButton>
                    {item.session_number}
                </Box>
            </TableCell>
            <TableCell sx={{ paddingY: "10px" }}>
                <Box display="flex" alignItems="center">{item.input_required ? <Check color="success" /> : <Close color="error" />}</Box>
            </TableCell>
            <TableCell sx={{ paddingY: "10px" }}><Typography title={item.input_label || "NA"} noWrap fontSize="14px" width="280px" >{item.input_label || "NA"}</Typography></TableCell>
            <TableCell sx={{ paddingY: "10px" }}>{item.input_type || "NA"}</TableCell>
            <TableCell sx={{ paddingY: "10px" }} >
                <Box display="flex" alignItems="center">{item.has_attachment ? <Check color="success" /> : <Close color="error" />}</Box>
            </TableCell>
            <TableCell sx={{ paddingY: "10px" }}><Typography title={item.attachment_label || "NA"} noWrap fontSize="14px" width="280px" >{item.attachment_label || "NA"}</Typography></TableCell>
            <TableCell sx={{ paddingY: "10px" }}>{item.attachment_file_size_limit ? `${item.attachment_file_size_limit} MB` : "NA"}</TableCell>
            <TableCell sx={{ paddingY: "10px" }}> <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                {item.attachment_allowed_file_extentions.length > 0 && item.attachment_allowed_file_extentions.map( ( ext, index ) => ( <Chip key={index} sx={{ fontWeight: "bold" }} size="small" label={ext} /> ) )}
                {item.attachment_allowed_file_extentions.length === 0 && "NA"}
            </Box> </TableCell>
            <TableCell sx={{ paddingY: "10px" }}>
                <Box display="flex" alignItems="center">{item.attachment_required ? <Check color="success" /> : <Close color="error" />}</Box>
            </TableCell>
            <TableCell sx={{ paddingY: "10px" }}>
                <Box display="flex" alignItems="center">{item.is_required ? <Check color="success" /> : <Close color="error" />}</Box>
            </TableCell>

            <TableCell className={`${showShadow ? "shadow-on-left-border" : ""} ${theme}`} sx={{ padding: "0 10px", flexDirection: "column", display: "table-cell", position: "sticky", right: "0", alignItems: "flex-end", background: "inherit" }}>
                <Box sx={{ display: "flex", height: "100%", flexGrow: 1, gap: "10px", alignItems: "center" }} >
                    <IconButton onClick={() => setEditMode( true )} color="secondary" size="small">
                        <EditOutlined sx={{ fontSize: "20px" }} />
                    </IconButton>
                    <IconButton disabled={deleting} onClick={() => setDeleteConfirmDialogState( true )} color="error" size="small">
                        {deleting ? <CircularProgress size={20} /> : <DeleteOutlined sx={{ fontSize: "20px" }} />}
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default ChecklistTableRow
