import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { attachmentThumbnails, convertToCDNLink, getFileSizeFromBytes, Icons } from '../../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import CustomWysiwyg from '../../common/CustomWysiwyg'
import { AttachmentOutlined, CheckBox, CheckBoxOutlineBlank, CheckCircleOutline, Close, Image } from '@mui/icons-material'
import { useOrganizationStore } from '../../../store/organization.store'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { formatDateTimeForDB } from '../../../utils/exam-utilities'

const userGroups = [
    { label: "All students", value: "STUDENT" },
    { label: "All faculties", value: "FACULTY,HOD,PRINCIPAL" },
    { label: "All HOD", value: "HOD" },
    { label: "Principal", value: "PRINCIPAL" },
    { label: "Auditors", value: "AUDITOR" },
]

const CreateOrEditAnnouncementDialog = ( { getUpdatedData, state, handleClose, announcement, mode } ) => {

    const [loadingInstitutes, setLoadingInstitutes] = useState( true )
    const [processingRequest, setProcessingRequest] = useState( false )
    const [removingAttachment, setRemovingAttachment] = useState( [] )
    const [uploadingFiles, setUploadingFiles] = useState( [] )
    const [dragOverStatus, setDragOverStatus] = useState( false )

    const { palette, border } = useTheme()

    const attachmentsInputRef = useRef()

    const OrganizationStore = useOrganizationStore()

    const initialValues = {
        announcement_title: announcement?.announcement_title || "",
        announcement_message: announcement?.announcement_message || "",
        institutes: announcement?.institute_id ? [announcement?.institute_id] : [],
        status: announcement?.status || "draft",
        announcement_for: announcement?.announcement_for || [],
        cover_image: announcement?.cover_image || null,
        attachments: announcement?.attachments || [],
        attachmentSizes: mode === 'edit' ? [] : [],
        announcement_ends_at: announcement ? dayjs( announcement?.announcement_ends_at ) : dayjs(),
        announcement_starts_at: announcement ? dayjs( announcement?.announcement_starts_at ) : dayjs()
    }

    const validationSchema = Yup.object().shape( {
        announcement_title: Yup.string().required( "Announcement title is required." ),
        announcement_message: Yup.string().required( "Announcement message is required." ),
        institutes: Yup.array().test( "ATLEASST_ONE_INSTITUTES_TEST", "Atleast one institute is required.", val => {
            return Array.isArray( val ) && val?.length > 0
        } ),
        announcement_for: Yup.array().test( "ATLEASST_ONE_INSTITUTES_TEST", "Please specify for which users this announcement is being made.", val => {
            return Array.isArray( val ) && val?.length > 0
        } ),
        announcement_starts_at: Yup.date().required( "Announcement start date and time limit is required!" ),
        announcement_ends_at: Yup.date().required( "Announcement end date and time is required." ).test( "TEST_VALID_END_AND _START_TIME", "Please provide valid time limits", ( val, { parent } ) => {
            if ( !parent.announcement_starts_at ) return false
            return parent.announcement_starts_at < val
        } )
    } )

    const toggleUserGroup = ( values, index, groupVal, setFieldValue ) => {
        const newValues = [...values]
        if ( index >= 0 ) {
            newValues.splice( index, 1 )
        } else {
            newValues.push( groupVal )
        }
        setFieldValue( 'announcement_for', newValues )
    }

    const handleCoverImageChange = ( e, setFieldValue ) => {
        const file = e.target.files[0]
        if ( !file.type?.includes( 'image' ) )
            return toast( "Unsupported cover image format, Only jpeg,jpg and png are supported" )
        setFieldValue( 'cover_image', file )
        e.target.value = ""
    }

    const handleAttachmementsChange = async ( e, values, setFieldValue ) => {
        const attachments = values.attachments
        const sizes = values.attachmentSizes
        if ( mode !== "edit" ) {
            if ( e.target.files.length > 0 )
                setFieldValue( 'attachments', [...attachments, ...e.target.files] )
            let newSizes = [...e.target.files].map( att => att.size )
            setFieldValue( 'attachmentSizes', [...sizes, ...newSizes] )
        } else {
            try {
                setUploadingFiles( [...e.target.files] )
                const formData = new FormData()
                for ( const file of e.target.files ) {
                    formData.append( 'attachments', file, file.name )
                }
                let newSizes = [...e.target.files].map( att => att.size )
                formData.append( 'attachmentSizes', JSON.stringify( [...sizes, ...newSizes] ) )
                await api.announcements.addAttachments( announcement.id, formData )
                const updatedData = await getUpdatedData()
                setFieldValue( 'attachments', updatedData.attachments )
            } catch ( err ) {
                console.log( err )

                toast( err?.response?.data?.message || "Something went wrong while uploading attachments" )
            } finally {
                setUploadingFiles( [] )
            }
        }
        e.target.value = ''
    }

    const removeAttachment = async ( attachments, position, setFieldValue ) => {
        if ( mode !== 'edit' ) {
            const newAttachments = [...attachments]
            newAttachments.splice( position, 1 )
            setFieldValue( 'attachments', newAttachments )
        } else {
            try {
                setRemovingAttachment( prev => [...prev, attachments[position].key] )
                const attachmentKey = attachments[position].key
                await api.announcements.removeAttachment( announcement.id, { key: attachmentKey } )
                const updatedData = await getUpdatedData()
                setFieldValue( 'attachments', updatedData.attachments )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while removing attachment" )
            } finally {
                setRemovingAttachment( prev => {
                    const newList = [...prev]
                    const index = newList.indexOf( attachments[position].key )
                    newList.splice( index, 1 )
                    return newList
                } )
            }
        }
    }

    const createAnnouncement = async ( values, { resetForm } ) => {
        setProcessingRequest( true )
        try {
            const formData = new FormData()
            for ( const key in values ) {
                if ( Object.prototype.hasOwnProperty.call( values, key ) ) {
                    if ( ['announcement_ends_at', 'announcement_starts_at'].includes( key ) ) {
                        let dateData = formatDateTimeForDB( values[key], "en-IN", "-" )
                        dateData = `${dateData.date} ${dateData.twentyFourFormat}`
                        formData.append( key, dateData )
                    } else if ( key === 'announcement_for' ) {
                        let allUsers = values[key].map( grp => grp.split( "," ) ).flat()
                        allUsers = [...new Set( allUsers )]
                        formData.append( key, JSON.stringify( allUsers ) )
                    } else if ( key === 'attachments' ) {
                        for ( const file of values[key] ) {
                            formData.append( 'attachments', file, file.name )
                        }
                    } else if ( key === 'cover_image' && values[key] ) {
                        formData.append( 'cover_image', values[key], values[key].name )
                    } else {
                        formData.append( key, typeof values[key] === 'string' ? values[key] : JSON.stringify( values[key] ) )
                    }
                }
            }
            await api.announcements.createAnnouncement( formData )
            await getUpdatedData()
            handleClose()
        } catch ( err ) {
            toast( err?.response?.data?.message || "An error accoured while creating announcement" )
        } finally {
            setProcessingRequest( false )
        }
    }

    const updateAnnouncement = async ( values ) => {
        setProcessingRequest( true )
        try {
            const formData = new FormData()
            formData.append( 'updateType', 'details' )
            for ( const key in values ) {
                if ( Object.prototype.hasOwnProperty.call( values, key ) ) {
                    if ( ['announcement_ends_at', 'announcement_starts_at'].includes( key ) ) {
                        let dateData = formatDateTimeForDB( values[key], "en-IN", "-" )
                        dateData = `${dateData.date} ${dateData.twentyFourFormat}`
                        formData.append( key, dateData )
                    } else if ( key === 'announcement_for' ) {
                        let allUsers = values[key].map( grp => grp.split( "," ) ).flat()
                        allUsers = [...new Set( allUsers )]
                        formData.append( key, JSON.stringify( allUsers ) )
                    } else if ( key === 'attachments' ) {

                    } else if ( key === 'cover_image' && values[key] ) {
                        if ( typeof values[key] !== 'string' )
                            formData.append( 'cover_image', values[key], values[key].name )
                        else
                            formData.append( 'cover_image', values[key] )
                    } else {
                        formData.append( key, typeof values[key] === 'string' ? values[key] : JSON.stringify( values[key] ) )
                    }
                }
            }
            await api.announcements.updateAnnouncement( announcement.id, formData )
            await getUpdatedData()
            handleClose()
        } catch ( err ) {
            console.log( err )

            toast( err?.response?.data?.message || "An error accoured while updating the announcement" )
        } finally {
            setProcessingRequest( false )
        }
    }

    const getOrganizations = useCallback( async () => {
        if ( OrganizationStore.getOrganization?.length === 0 )
            await OrganizationStore.fetchOrganization()
        setLoadingInstitutes( false )
    }, [OrganizationStore] )


    useEffect( () => {
        getOrganizations()
    }, [getOrganizations] )

    return (
        <Dialog open={state} PaperProps={{ sx: { width: "90%", maxWidth: "950px" } }}>
            <DialogTitle sx={{ borderBottom: border[1], zIndex: 5, bgcolor: palette.form.formCardBg, display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center", padding: "10px 20px" }} >
                <Box fontWeight="500" fontSize="18px">{announcement !== undefined ? <span> Edit announcement: <em><u>{announcement?.announcement_title}</u> </em> </span> : "Create new announcement"}</Box>
                <IconButton onClick={handleClose} >{Icons.default.CloseIcon}</IconButton>
            </DialogTitle>
            <DialogContent >
                <Box marginTop="20px" bgcolor={palette.form.formCardBg} border={border[1]} borderRadius="5px" padding="20px">
                    <Formik onSubmit={mode === 'edit' ? updateAnnouncement : createAnnouncement} initialValues={initialValues} validationSchema={validationSchema} >
                        {( { values, setFieldValue, errors } ) => {
                            return (
                                <Form>
                                    <Box
                                        onDragLeave={() => setDragOverStatus( false )}
                                        onDrop={() => setDragOverStatus( false )}
                                        onDragEnter={() => setDragOverStatus( true )}
                                        onDragOver={() => setDragOverStatus( true )}
                                        position="relative" overflow="clip" borderRadius="5px" bgcolor={palette.greyedOut} sx={{ cursor: "pointer", "&:hover": { bgcolor: palette.greyedOut + "33" } }} padding="20px" border={`3px dotted ${palette.borderColor}`}>
                                        <input onChange={e => handleCoverImageChange( e, setFieldValue )} accept="image/*" type="file" name="cover_image" id="cover_image_input" className="custom-file-input" />
                                        {!dragOverStatus ? <Box>
                                            <Typography color="textSecondary" textAlign="center" variant='subtitle2' display="flex" alignItems="center" gap="10px" justifyContent="center"> <Image /> {values.cover_image ? "Replace cover image" : "Add Cover image"}</Typography>
                                            {!values.cover_image && <Typography color="textSecondary" textAlign="center" variant='subtitle2' display="flex" alignItems="center" marginTop="10px" gap="10px" justifyContent="center"> <em>Drag and drop cover image here</em></Typography>}
                                        </Box> :
                                            <Typography sx={{ pointerEvents: "none" }} className="animation-shake" color="textSecondary" textAlign="center" variant='subtitle2' display="flex" alignItems="center" marginTop="10px" gap="10px" justifyContent="center"> <em>Drop the file here</em></Typography>
                                        }
                                        {values.cover_image && <Box sx={{ position: "absolute", inset: 0, opacity: 0.3, pointerEvents: "none", zIndex: "1" }}>
                                            <img style={{ objectFit: "cover" }} height="100%" width="100%" src={typeof values.cover_image === 'string' ? convertToCDNLink( values.cover_image ) : URL.createObjectURL( values.cover_image )} alt="Cover" />
                                        </Box>}
                                    </Box>
                                    <FormControl margin='normal' fullWidth>
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>What is this announcement about? *</Typography>
                                        <Field id="annoucement_title" size="small" as={TextField} placeholder="Eg: Maintenance warning!" type="text" name="announcement_title" />
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='announcement_title' /></Typography>
                                    </FormControl>
                                    <FormControl margin='normal' fullWidth>
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Explain in details here... *</Typography>
                                        <Box borderRadius="5px" border={border[1]} overflow="clip" bgcolor={palette.form.inputBg} >
                                            <CustomWysiwyg
                                                borderless
                                                height="150px"
                                                id='announcement_message_input'
                                                value={values.announcement_message}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            ['bold', 'italic', 'underline', 'blockquote'],
                                                            [{ align: [] }],
                                                            [{ script: 'sub' }, { script: 'super' }],
                                                            [{ list: 'ordered' }, { list: 'bullet' }],
                                                            [{ 'color': [] }, { 'background': [] }],
                                                            [{ 'font': [] }, { 'size': [] }],
                                                            ['link', 'image', 'video'],
                                                            ['clean'],
                                                            [{ 'customVideoUpload': true }]
                                                        ],
                                                        handlers: {
                                                            customVideoUpload: function () {
                                                                // Trigger file input click
                                                                document.getElementById( 'videoInput' ).click()
                                                            }
                                                        }
                                                    },
                                                }}
                                                placeholder={`Eg: Alive will be under maintenance from 12-02-2024 10:00 pm to 13-02-2024 00:00am`}
                                                onChange={( val, text ) => {
                                                    if ( text?.trim() !== '' )
                                                        setFieldValue( `announcement_message`, val )
                                                }} />
                                            <Box padding="10px" display="flex" gap="10px" flexWrap="wrap" borderTop={border[1]}>
                                                {values.attachments?.length > 0 && values.attachments?.map( ( attachment, attachmentIndex ) => {
                                                    const attachmentType = attachment.name?.split( "." ).pop()?.toLowerCase()
                                                    return (
                                                        <Box bgcolor={palette.primary.light + 22} border={`2px solid ${palette.primary.main}`} key={`attachment-${attachmentIndex}`} display="flex" gap="5px" alignItems="center" borderRadius="20px" padding="2px 5px 2px 2px">
                                                            <img width="35px" src={attachmentThumbnails[attachmentType] || attachmentThumbnails["default"]} alt={attachment.name} />
                                                            <Box>
                                                                <Typography title={attachment.name} maxWidth="150px" noWrap fontSize="14px" fontWeight="500">{attachment.name}</Typography>
                                                                <Typography marginTop="-2px" fontSize="12px"><em>{getFileSizeFromBytes( attachment.size )}</em></Typography>
                                                            </Box>
                                                            <IconButton disabled={processingRequest || removingAttachment.includes( attachment.key )} onClick={() => removeAttachment( values.attachments, attachmentIndex, setFieldValue )} size="small">
                                                                {( processingRequest || removingAttachment.includes( attachment.key ) ) ? <CircularProgress size={14} /> : <Close sx={{ fontSize: "18px" }} />}
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                } )}
                                                {uploadingFiles.length > 0 && uploadingFiles.map( ( attachment, attachmentIndex ) => {
                                                    const attachmentType = attachment.name?.split( "." ).pop()?.toLowerCase()
                                                    return (
                                                        <Box bgcolor={palette.primary.light + 22} border={`2px solid ${palette.primary.main}`} key={`uploadingattachment-${attachmentIndex}`} display="flex" gap="5px" alignItems="center" borderRadius="20px" padding="2px 5px 2px 2px">
                                                            <img width="35px" src={attachmentThumbnails[attachmentType] || attachmentThumbnails['default']} alt={attachment.name} />
                                                            <Box>
                                                                <Typography title={attachment.name} maxWidth="150px" noWrap fontSize="14px" fontWeight="500">{attachment.name}</Typography>
                                                                <Typography marginTop="-2px" fontSize="12px"><em>{getFileSizeFromBytes( attachment.size )}</em></Typography>
                                                            </Box>
                                                            <IconButton disabled={true} size="small">
                                                                <CircularProgress size={12} />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                } )}
                                                <Box position="relative">
                                                    <input onChange={e => handleAttachmementsChange( e, values, setFieldValue )} multiple className='custom-file-input' type="file" name="attachments" id="attachments_input" ref={attachmentsInputRef} />
                                                    <Button size='small' color='greyed' sx={{ textTransform: "capitalize" }} onClick={() => attachmentsInputRef?.current?.click()} startIcon={<AttachmentOutlined />}>Add Attachments</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='announcement_messsage' /></Typography>
                                    </FormControl>
                                    {mode !== 'edit' && <FormControl fullWidth margin='normal'>
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Choose the institutes for which this announcement is for... *</Typography>
                                        <Select
                                            name='institutes'
                                            onChange={e => setFieldValue( 'institutes', typeof e.target.value === 'string' ? e.target.value?.split( "," ) : e.target.value )}
                                            size='small'
                                            multiple
                                            displayEmpty
                                            renderValue={val => {
                                                return <Box display="flex" gap="5px" alignItems="center" flexWrap="wrap">
                                                    {val && val.map( institute => (
                                                        <Typography fontWeight="bolder" fontSize="12px" padding="5px 10px" borderRadius="5px" border={`2px solid ${palette.secondary.main}`} key={institute} color="secondary">{institute}</Typography>
                                                    ) )}
                                                    {val.length === 0 && <Typography color={palette.form.placeholder} fontSize="15px">Eg: AIT, AGS</Typography>}
                                                </Box>
                                            }}
                                            id="institutes-select"
                                            value={values.institutes}
                                        >
                                            <MenuItem sx={{ fontSize: "14px" }} value="0">{loadingInstitutes ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                            {
                                                OrganizationStore.getOrganization.map( ( institute, i ) => {
                                                    return <MenuItem id={`inst-${institute.institute_name_short}`} sx={{ fontSize: "12px", textTransform: "capitalize" }} value={institute.institute_name_short} key={'key-' + i}>
                                                        <Box display="flex" gap="10px" alignItems="center">
                                                            {values.institutes?.includes( institute.institute_name_short ) ? <CheckBox sx={{ fontSize: "16px" }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "16px" }} />}
                                                            <strong>[{institute.institute_name_short}]</strong>
                                                            <Typography marginLeft="5px" variant='subtitle2' fontSize="12px">{institute.institute_name?.toLowerCase()}</Typography></Box>
                                                    </MenuItem>
                                                } )
                                            }
                                        </Select>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='institutes' /></Typography>
                                    </FormControl>}
                                    <FormControl fullWidth margin='normal'>
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Select the user group that should receive this announcement... *</Typography>
                                        <Box display="flex" flexWrap="wrap" gap="10px">
                                            {userGroups.map( grp => {
                                                const selected = values.announcement_for?.indexOf( grp.value )
                                                return (
                                                    <Box
                                                        borderRadius="30px"
                                                        border={`2px solid ${palette.borderColor}`}
                                                        display="flex"
                                                        gap="10px"
                                                        padding="5px 15px"
                                                        onClick={() => toggleUserGroup( values.announcement_for, selected, grp.value, setFieldValue )}
                                                        bgcolor={selected >= 0 ? palette.success.main : palette.greyedOut}
                                                        sx={{ transition: "300ms", cursor: "pointer", borderColor: selected >= 0 ? palette.success.main : palette.borderColor, '&:hover': { borderColor: selected >= 0 ? palette.success.main : palette.common.font } }}
                                                        color={selected >= 0 ? palette.common.white : palette.common.font}
                                                        key={grp.value}
                                                    >
                                                        {selected >= 0 ? <CheckCircleOutline sx={{ color: "inherit" }} fontSize='small' /> : ""}
                                                        <Typography color={selected >= 0 ? palette.common.white : palette.common.font} fontWeight="500" fontSize="14px">{grp.label}</Typography>
                                                    </Box>
                                                )
                                            } )}
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='announcement_for' /></Typography>
                                    </FormControl>
                                    <FormControl margin='normal' sx={{ display: "flex", maxWidth: "250px" }}>
                                        <Box >
                                            <label htmlFor='announcement_starts_at_input' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Visibility Start Date & Time *</Typography> </label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    id="announcement_starts_at_input"
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    value={dayjs( values.announcement_starts_at )}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    onChange={( e ) => { if ( e.$d !== "Invalid Date" ) { setFieldValue( 'announcement_starts_at', e ) } }}

                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='announcement_starts_at' /></Typography>
                                    </FormControl>
                                    <FormControl margin="normal" sx={{ maxWidth: "250px", display: "flex" }}>
                                        <Box>
                                            <label htmlFor='announcement_ends_at_input' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Visibility End Date & Time *</Typography> </label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    id="announcement_ends_at_input"
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    value={dayjs( values.announcement_ends_at )}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    onChange={( e ) => { setFieldValue( 'announcement_ends_at', e ) }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='announcement_ends_at' /></Typography>
                                    </FormControl>
                                    {mode !== 'edit' && <FormControl margin='normal'>
                                        <FormControlLabel control={<Checkbox color='primaryDark' value={values.status === 'published'} onChange={e => { setFieldValue( 'status', e.target.checked ? 'published' : "draft" ) }} />} label="Publish this announcement right after the creation?" />
                                    </FormControl>}
                                    <Box display="flex" gap="20px" marginTop="40px">
                                        <Button disableElevation disabled={processingRequest} type='submit' startIcon={processingRequest && <CircularProgress size={14} />} variant='contained' sx={{ textTransform: "capitalize" }}>{processingRequest ? mode === 'edit' ? "Saving changes..." : "Creating announcement..." : mode === 'edit' ? "Save changes" : "Create Announcement"}</Button>
                                        <Button disableElevation color="error" onClick={() => { }} disabled={processingRequest} variant='contained' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

CreateOrEditAnnouncementDialog.propTypes = {
    state: PropTypes.bool.isRequired,
}

export default CreateOrEditAnnouncementDialog
