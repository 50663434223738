import { Button, ButtonGroup, CircularProgress, Collapse, IconButton, Paper, Tooltip, Typography, useTheme, Popover, Drawer } from "@mui/material"
import DetailsViewer from "../../components/study-material-components/DetailsViewer"
import { useDownloadStatusListStore } from "../../store/downloadstatuslistdialog.store"
import MaterialEdit from "../../components/study-material-components/MaterialEdit"
import FileInput from "../../components/study-material-components/FileInput"
import DataLoadingSpinner from "../../components/common/DataLoadingSpinner"
import GridView from "../../components/study-material-components/GridView"
import ListView from "../../components/study-material-components/ListView"
import BlockMessage from '../../components/common/BlockMessage'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import CustomSearchBar from "../../components/common/CustomSearchBar"
import ConfirmDialog from "../../components/common/ConfirmDialog"
import { useNavigate, useParams } from "react-router-dom"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Icons, flatColorsAndBackgrounds, convertToCDNLink, attachmentThumbnails, getFileSizeFromBytes } from "../../utils/utilities"
import GridViewIcon from '@mui/icons-material/GridView'
import { useUserStore } from "../../store/user.store"
import React, { useEffect, useState } from "react"
import AddIcon from '@mui/icons-material/Add'
import { toast } from "react-toastify"
import { observer } from "mobx-react"
import api from "../../service/api"
import { Box } from "@mui/system"
import BreadcrumbList from "../../components/common/BreadcrumbList"
import { List, Download, Visibility } from "@mui/icons-material"
import InfoIcon from '@mui/icons-material/Info'
import DocumentViewer from '../../components/common/DocumentViewer'
import { useSettingsStore } from '../../store/settings.store'

const Material = observer( ( { fromSubject = false } ) => {

    const [viewType, setViewType] = useState( localStorage.getItem( "studyMaterialView" ) || "grid" )
    const [selectedAttachments, setSelectedAttachments] = useState( [] )
    const [attachmentsSelectedForDetails, setAttachmentsSelectedForDetails] = useState( null )
    const [showDetails, setShowDetails] = useState( false )
    const [addMode, setAddMode] = useState( false )
    const [data, setData] = useState( null )
    const [attachments, setAttachments] = useState( null )
    const [isLoading, setIsLoading] = useState( true )
    const [editMode, setEditMode] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [filteredAttachments, setFilteredAttachments] = useState( null )
    const [isUploading, setIsUploading] = useState( false )
    const [progress, setProgress] = useState( 0 )
    const [searchParameter, setSearchParameter] = useState( '' )
    const [isUpdating, setIsUpdating] = useState( false )
    const [attachmentsNames, setAttachmentsNames] = useState( [] )
    const [attachmentsSizes, setAttachmentsSizes] = useState( [] )
    const [isDownloading, setIsDownloading] = useState( false )
    const [materialDeleteDialogState, setMaterialDeleteDialogState] = useState( false )

    const { material_id, subject_id } = useParams()

    const UserStore = useUserStore()
    const StudyMaterialStore = useStudyMaterialStore()
    const DownloadStatusListStore = useDownloadStatusListStore()

    const [filename, setfilename] = useState( null )
    const SettingsStore = useSettingsStore()
    const [anchorEl, setAnchorEl] = useState( null )
    const [formatFiles, setFormatFiles] = useState( [] )
    const [isDialogOpen, setIsDialogOpen] = useState( false )
    const [documentUrl, setDocumentUrl] = useState( null )
    const [documentType, setDocumentType] = useState( null )

    const navigate = useNavigate()


    const { palette, border } = useTheme()


    const searchAttachments = ( e ) => {
        const filter = attachments.filter( a => {
            return a.study_material_attachment_title?.toLowerCase()?.includes( e.target.value?.toLowerCase() )
        } )
        setSearchParameter( e.target.value )
        setFilteredAttachments( filter )
    }

    const progressHandler = ( event ) => {
        const { loaded, total } = event
        let percent = Math.round( ( loaded * 100 ) / total )
        if ( percent <= 100 ) {
            setProgress( percent )
        }
    }

    const getAttachments = async () => {
        try {
            await StudyMaterialStore.fetchAttachments( material_id )
            setAttachments( StudyMaterialStore.getAttachments )
            const filtered = StudyMaterialStore.getAttachments.filter( attachment => attachment.study_material_attachment_name.includes( searchParameter ) )
            setFilteredAttachments( filtered )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        }
    }

    const uploadAttachments = async () => {
        setIsUploading( true )
        try {
            const formData = new FormData()
            let emptyName = false
            for ( let i = 0; i < attachmentsNames.length; i++ ) {
                const name = attachmentsNames[i]
                if ( name === "" ) {
                    emptyName = true
                }
            }
            if ( !emptyName ) {
                selectedAttachments.forEach( ( attachment, i ) => {
                    const ext = attachment.name.split( "." ).pop()
                    let fileName = encodeURIComponent( `${attachment.name.split( `.${ext}` )[0]}-${Date.now()}${i}.${ext}`.replace( /\s/g, '-' ) )
                    formData.append( 'attachments[]', attachment, fileName )
                } )
                formData.append( 'attachmentNames[]', JSON.stringify( attachmentsNames ) )
                formData.append( 'attachmentsSizes[]', JSON.stringify( attachmentsSizes ) )
                await StudyMaterialStore.addAttachments( material_id, formData, progressHandler )
                setIsUploading( false )
                setProgress( 0 )
                setSelectedAttachments( [] )
                setAttachmentsNames( [] )
                setAttachmentsSizes( [] )
                await getAttachments()
            } else {
                toast( "Please provide names for all the attachments" )
            }
        } catch ( e ) {
            console.log( e )
            setIsUploading( false )
            setProgress( 0 )
            await getAttachments()
            if ( e?.response?.data?.data?.unuploaded?.length > 0 ) {
                setSelectedAttachments( [] )
                setAttachmentsNames( [] )
                setAttachmentsSizes( [] )
                let unuploadedFiles = []
                for ( let i = 0; i < e?.response?.data?.data?.unuploaded.length; i++ ) {
                    const file = e?.response?.data?.data?.unuploaded[i]
                    unuploadedFiles.push( file.originalname.replace( /-(\d)+/g, "" ) )
                }
                const toastMsg = <p>Failed to upload these files: <b><i>{unuploadedFiles.join( ", " )}</i></b> due to unsupported format. </p>
                toast( toastMsg )
            } else
                toast( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsUploading( false )
            setProgress( 0 )
        }
    }

    const downloadStudyMaterial = async () => {
        setIsDownloading( true )
        toast( `Downloading ${data.study_material_name}...`, { position: "bottom-left" } )
        try {
            await DownloadStatusListStore.downloadMaterial( material_id, data )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsDownloading( false )
        }
    }

    const deleteAttachment = async ( id ) => {
        try {
            await StudyMaterialStore.deleteAttachment( id )
            getAttachments()
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        }
    }

    const deleteMaterial = async () => {
        try {
            setDeleting( true )
            await StudyMaterialStore.deleteMaterial( material_id )
            await StudyMaterialStore.fetchMaterials()
            navigate( '/faculty/material' )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setDeleting( false )
        }
    }

    const renameAttachment = async ( id, value ) => {
        try {
            const res = await api.renameAttachment( material_id, id, { new_title: value } )
            getAttachments()
            toast( res.data.data )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data )
        }
    }

    const removefilter = () => {
        setFilteredAttachments( [...attachments] )
        setSearchParameter( "" )
    }

    const update = async ( values ) => {
        setIsUpdating( true )
        try {
            setData( await StudyMaterialStore.updateMaterial( material_id, { name: values.materialName, description: values.materialDescription, tags: values.tags } ) )
            setEditMode( false )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsUpdating( false )
        }
    }

    const navigateToMaterials = () => {
        console.log( subject_id )
        if ( !fromSubject )
            navigate( UserStore.getUser['user_role'] === "STUDENT" ? `/student/material?mode=manage&institute=${data?.institute || "0"}` : '/faculty/material' )
        else
            navigate( UserStore.getUser['user_role'] === "STUDENT" ? `/student/subject/${subject_id}/materials` : `/faculty/subject/${subject_id}/materials` )
    }

    const handleOpenPopover = ( event ) => {
        const fileUrlString = SettingsStore.getUseSettings['sample_study_material_format']
        if ( fileUrlString ) {
            const parsedFiles = JSON.parse( fileUrlString )
            setFormatFiles( parsedFiles )
            setAnchorEl( event.currentTarget )
        } else {
            console.error( 'Cannot Retrieve Files' )
        }
    }

    const handleClosePopover = () => {
        setAnchorEl( null )
    }

    const handleViewFile = ( file ) => {
        const cdnLink = convertToCDNLink( file.location )
        setDocumentUrl( cdnLink )
        setDocumentType( file.name.split( '.' ).pop() )
        setfilename( file.name )
        setIsDialogOpen( true )
    }

    const handleDownloadFile = ( file ) => {
        const link = document.createElement( 'a' )
        link.href = file.location
        link.download = file.name
        link.click()
        link.remove()
    }

    const handleCloseDialog = () => {
        setIsDialogOpen( false )
        setDocumentUrl( null )
        setDocumentType( null )
    }

    useEffect( () => {
        const getData = async () => {
            try {
                await StudyMaterialStore.fetchMaterial( material_id )
                setData( StudyMaterialStore.getMaterial['studyMaterial'] )
                setAttachments( StudyMaterialStore.getMaterial['attachments'] )
                setFilteredAttachments( StudyMaterialStore.getMaterial['attachments'] )
            } catch ( e ) {
                console.log( e )
            } finally {
                setIsLoading( false )
            }
        }
        getData()
    }, [material_id, navigate, UserStore, StudyMaterialStore] )


    return (
        // <Layout navHeadText="Study Material">
        <Box flexGrow={1} display="flex" flexDirection="column" overflow="auto" padding={fromSubject ? "0" : "20px"}>
            <ConfirmDialog status={materialDeleteDialogState} waiting={deleting} confirmAction={deleteMaterial} cancelAction={() => setMaterialDeleteDialogState( false )} actionName="Delete" message={<p variant="body2">Do you really want to delete <strong><em>{data?.study_material_name}</em></strong>. All the attachments will be deleted!</p>} />

            {!fromSubject && <BreadcrumbList items={[
                { link: UserStore.getUser['user_role'] === "STUDENT" ? `/student/material?mode=manage&institute=${data?.institute || "0"}` : '/faculty/material', label: "Study Materials" },
                data ? data?.study_material_name : "",
                editMode ? 'Edit' : ""
            ]} />}

            {/* {fromSubject && <Button sx={{ textTransform: 'capitalize', marginTop: "-10px", marginBottom: "10px" }} startIcon={Icons.default.ArrowBack} onClick={() => navigate( `${UserStore.getUser['user_role'] === "STUDENT" ? `/student/subject/${SubjectStore.getSubject.subject_id}/materials` : `/faculty/subject/${SubjectStore.getSubject.subject_id}/materials`}` )} >Back to materials</Button>} */}

            {!isLoading && data.study_material_id && !editMode &&
                <Paper sx={{ flexGrow: 1 }} elevation={fromSubject ? 1 : 0}>
                    {attachmentsSelectedForDetails && <DetailsViewer attachment={attachmentsSelectedForDetails} setShowDetails={setShowDetails} open={showDetails} />}

                    <Box borderBottom={`1px solid ${palette.borderColor}`} padding="20px" display="flex" flexDirection="column" gap="10px">
                        <Box display="flex" alignItems="flex-start" gap="10px">
                            {!fromSubject && <IconButton onClick={navigateToMaterials} size="small">
                                {Icons.default.ArrowBack}
                            </IconButton>}
                            <Box flexGrow={1}>
                                <Typography marginTop="3px" variant="h6" fontSize="18px" color="primaryDark">{data.study_material_name}</Typography>
                                <Typography marginTop="3px" variant="body2">{data.study_material_desc}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" paddingLeft={!fromSubject ? "45px" : "0"} justifyContent="space-between" gap="20px" flexWrap="wrap" alignItems="center">
                            <Box flexGrow={1} flexWrap="wrap" alignItems="center" display="flex" gap="10px" >
                                {data.study_material_tags && data.study_material_tags.split( "," ).length > 0 && data.study_material_tags.split( "," ).map( ( tag, index ) => {
                                    const { bg, color } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                                    return (
                                        <Typography borderRadius="5px" padding="2px 10px" variant="subtitle2" sx={{ color: color, background: bg }} key={index}>{tag}</Typography>
                                    )
                                } )}
                            </Box>
                            <Box flexGrow={1} display="flex" justifyContent={{ md: "flex-start", xs: "center" }} flexWrap="wrap" gap="10px" alignItems="center">
                                <CustomSearchBar onChange={searchAttachments} value={searchParameter} placeholder="Search attachments..." border="1px solid #ddd" style={{ flexGrow: 1, width: "200px" }} />
                                {UserStore.getUser.user_role !== "STUDENT" && <Box gap="10px" display="flex" alignItems="center">
                                    {data.empcode === UserStore.getUser['user_id'] && <Tooltip title="Edit" placement="top">
                                        <IconButton size="small" onClick={() => { setEditMode( true ); window.location.hash = "#Edit" }} >
                                            {Icons.default.EditIconOutlined}
                                        </IconButton>
                                    </Tooltip>}
                                    {UserStore.getUser.user_id === data.empcode && <Tooltip title="Delete" placement="top">
                                        <IconButton size="small" onClick={() => setMaterialDeleteDialogState( true )} >
                                            {Icons.default.DeleteIconOutlined}
                                        </IconButton>
                                    </Tooltip>}
                                </Box>}
                                {data.empcode === UserStore.getUser['user_id'] && <Tooltip title={addMode ? "Cancel" : "Add more attachments"}>
                                    <IconButton size="small" onClick={() => setAddMode( !addMode )} sx={{ transform: addMode ? "rotate(45deg)" : "rotate(0)", transition: "0.2s ease", color: addMode ? "error.main" : "secondary.main", "&:hover": { color: "#273679" } }} >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>}
                                {attachments.length > 0 && <Tooltip title={isDownloading ? "Downloading material..." : "Download all materials"} placement="top">
                                    <span>
                                        <IconButton size="small" disabled={isDownloading} onClick={downloadStudyMaterial}>
                                            {isDownloading ? Icons.default.DownloadingIcon : Icons.default.DownloadIconOutlined}
                                        </IconButton>
                                    </span>
                                </Tooltip>}
                                <ButtonGroup variant="contained" disableElevation sx={{ background: palette.greyedOut, border: `1px solid ${palette.borderColor}`, borderRadius: "20px" }}>
                                    <Tooltip title="List view" placement="top">
                                        <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "list" ); setViewType( "list" ) }} sx={{ color: viewType !== "grid" ? "primary.main" : "#666" }}>
                                            <List />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Grid view" placement="top">
                                        <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "grid" ); setViewType( "grid" ) }} sx={{ color: viewType === "grid" ? "primary.main" : "#666" }}>
                                            <GridViewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                    <Box padding="20px" borderRadius="5px" margin="20px" border={border[1]} bgcolor={palette.contentBg} >
                        <Collapse in={addMode} sx={{ padding: "0", position: "relative" }}>
                            <Box display="flex" sx={{ borderRadius: "5px", background: palette.errorMessage.main, padding: "10px", marginBottom: "20px", alignItems: "flex-start" }}>
                                <InfoIcon sx={{ marginRight: "5px", marginTop: "5px", color: "white", fontSize: "20px" }} />
                                <Box padding="5px 0" display="flex" gap="10px" flexDirection="column">
                                    <Typography variant="body2" color="white" sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
                                        Note: {SettingsStore.getUseSettings['sample_study_material_format_note'] || "The institute has recommended a specific format for creating and uploading Study material, we recommend to do the same"}
                                    </Typography>
                                    {SettingsStore.getUseSettings['sample_study_material_format'] && Array.isArray( JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ) ) && JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).length > 0 && (
                                        <Box display="flex" gap="5px" alignItems="center" flexWrap="wrap">
                                            {JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).slice( 0, 2 ).map( ( file, index ) => (
                                                <Box borderRadius="20px" bgcolor={"white"} key={index} gap="10px" padding='5px' sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <img style={{ objectFit: "cover" }} width="35px" src={attachmentThumbnails[file.name?.split( "." ).pop()] ? attachmentThumbnails[file.name?.split( "." ).pop()] : attachmentThumbnails["default"]} alt={file.type} />
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Typography variant="subtitle2" color="black" fontSize="12px">{file.name}</Typography>
                                                        <Typography variant="subtitle2" color="black" fontSize="10px">
                                                            {getFileSizeFromBytes( file.size )}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton color="black" sx={{ color: "black" }} size='small' onClick={() => handleViewFile( file )}>
                                                        <Visibility sx={{ fontSize: "18px" }} />
                                                    </IconButton>
                                                    <IconButton color="black" sx={{ color: "black" }} size='small' onClick={() => handleDownloadFile( file )}>
                                                        <Download sx={{ fontSize: "18px" }} />
                                                    </IconButton>
                                                </Box>
                                            ) )}
                                            {JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).length > 2 && <Button endIcon={Icons.default.KeyboardArrowDownIcon} sx={{ width: "fit-content", color: "white", fontWeight: "400", textTransform: "capitalize" }}
                                                onClick={handleOpenPopover}>
                                                View more
                                            </Button>}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <FileInput attachmentsSizes={attachmentsSizes} setAttachmentsSizes={setAttachmentsSizes} size={60} progress={progress} isUploading={isUploading} setFieldValue={( name, value ) => { }} setAttachmentsNames={setAttachmentsNames} attachmentsNames={attachmentsNames} selectedAttachments={selectedAttachments} setSelectedAttachments={setSelectedAttachments} />
                            {selectedAttachments.length > 0 && <Button onClick={uploadAttachments} disabled={isUploading} startIcon={<CloudUploadIcon />} sx={{ textTransform: "capitalize", marginY: "20px" }} variant="contained" disableElevation>{isUploading ? <Typography display="flex" alignItems="center" gap="10px" fontWeight={600}> <CircularProgress size={10} /> Uploading attachments... </Typography> : "Upload Attachments"}</Button>}
                        </Collapse>

                        {viewType === "grid" ?
                            <GridView progressHandler={progressHandler} setAddMode={setAddMode} renameAttachment={renameAttachment} removefilter={removefilter} setShowDetails={setShowDetails} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} attachments={filteredAttachments} allAttachments={attachments} material={data} deleteAttachment={deleteAttachment} /> :
                            <ListView progressHandler={progressHandler} setAddMode={setAddMode} renameAttachment={renameAttachment} removefilter={removefilter} material={data} setShowDetails={setShowDetails} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} attachments={filteredAttachments} allAttachments={attachments} deleteAttachment={deleteAttachment} />}
                    </Box>
                </Paper>}
            {data && !data.study_material_id && <BlockMessage blockTitle="Material Not Found" type="error" message="Sorry, The study material you are looking for does not exists!" actions={[{ label: "Back to Materials", action: navigateToMaterials }]} />}
            {!isLoading && data && editMode && <Box>
                <MaterialEdit material={data} update={update} isUpdating={isUpdating} currentTags={data.study_material_tags.split( "," )} setEditMode={setEditMode} />
            </Box>}
            {/* {!isLoading && data && !editMode && manageAccessMode && <ManageAccess data={data} setManageAccessMode={setManageAccessMode} />} */}
            {isLoading && <Paper sx={{ height: "100%" }}>
                <DataLoadingSpinner waitingMessage="Loading study material..." />
            </Paper>}

            <Popover open={Boolean( anchorEl )} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}>
                <Box sx={{ p: 2, maxWidth: 400 }}>
                    {formatFiles.slice( 2 ).map( ( file, index ) => (
                        <Box key={index} gap="10px" padding='5px' sx={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ objectFit: "cover" }} width="45px" src={attachmentThumbnails[file.name?.split( "." ).pop()] ? attachmentThumbnails[file.name?.split( "." ).pop()] : attachmentThumbnails["default"]} alt={file.type} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle2" fontSize="12px">{file.name}</Typography>
                                <Typography variant="subtitle2" fontSize="10px" color="text.secondary">
                                    {getFileSizeFromBytes( file.size )}
                                </Typography>
                            </Box>
                            <IconButton onClick={() => handleViewFile( file )}>
                                <Visibility />
                            </IconButton>
                            <IconButton onClick={() => handleDownloadFile( file )}>
                                <Download />
                            </IconButton>
                        </Box>
                    ) )}
                </Box>
            </Popover>

            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={isDialogOpen}>
                <Box bgcolor="background.paper" flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={1} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{filename}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={handleCloseDialog}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer type={documentType} loadingText="Loading material..." file={documentUrl} />
                    </Box>
                </Box>
            </Drawer>
        </Box>
        // </Layout>

    )
} )

export default Material
