import { Avatar, Box, Typography, IconButton, AppBar, useTheme, Popover, Button, Badge, Select, MenuItem } from "@mui/material"
import ProfileImageCropAndUploadDialog from "../ProfileImageCropAndUploadDialog"
import { convertToCDNLink, Icons, showDateInIndianDateFormat } from "../../../utils/utilities"
import { useLayoutContext } from "../../../contexts/layout.context"
import { useThemeContext } from "../../../contexts/theme.context"
import { useUserStore } from "../../../store/user.store"
import { useAuthStore } from "../../../store/auth.store"
// import aliveLogo from '../../../assets/alivelogo.png'
import LogoutIcon from '@mui/icons-material/Logout'
import { useEffect, useRef, useState } from "react"
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from "react-router-dom"
import { AccountCircle, Edit } from "@mui/icons-material"
import { observer } from 'mobx-react'
import { toast } from "react-toastify"

const themeOptions = [
    'light', 'dark', 'system'
]

const Navbar = observer( ( { onLogout, openSideMenu, closeSideMenu, paddingRight } ) => {
    // const [user, setUser] = useState( null )
    const [selectedProfileImg, setSelectedProfileImg] = useState( null )
    const [settingsAnchorEl, setSettingsAnchorEl] = useState( null )
    const [anchorEl, setAnchorEl] = useState( null )
    const [profile, setProfile] = useState( null )
    const UserStore = useUserStore()
    const AuthStore = useAuthStore()
    const open = Boolean( anchorEl )

    const avatarRef = useRef()
    const profileImgInputRef = useRef()
    const uploadProfileAlertRef = useRef()

    const { menuState, navHeight, navHeadText } = useLayoutContext()

    const navigate = useNavigate()

    const { palette, theme } = useTheme()
    const { setSelectedTheme, selectedTheme } = useThemeContext()

    const handleProfileImgSelection = e => {
        const url = URL.createObjectURL( e.target.files[0] )
        setSelectedProfileImg( url )
        e.target.value = ''
    }

    const handleThemeChange = e => {
        setSelectedTheme( e.target.value )
        localStorage.setItem( 'preferedTheme', e.target.value )
        setSettingsAnchorEl( null )
    }

    const logout = async () => {
        // try {
        //     await onLogout( true, true )
        // } catch ( err ) {
        //     console.log( err )
        // }
        await AuthStore.doLogout()
        toast.success( "Logged out successfully!" )
        navigate( '/' )
    }

    useEffect( () => {
        let timeoutInt
        if ( !UserStore?.getUser?.user_profile ) {
            const lastAlertDate = localStorage.getItem( 'lastProfileAlertDate' )
            const today = showDateInIndianDateFormat( new Date() )
            if ( today !== lastAlertDate ) {
                uploadProfileAlertRef.current.classList.add( "pop-alert" )
                localStorage.setItem( 'lastProfileAlertDate', today )
                timeoutInt = setTimeout( () => {
                    uploadProfileAlertRef.current.classList.remove( "pop-alert" )
                }, 30000 )
            }
        }
        setProfile( UserStore?.getUser?.user_profile )
        return () => {
            if ( timeoutInt )
                clearTimeout( timeoutInt )
        }
    }, [UserStore] )

    return (
        <AppBar
            id="appbar"
            elevation={0}
            sx={{
                borderBottom: palette.grey[600] + " 1px solid",
                borderRadius: "0",
                background: palette.layoutBackground,
                transition: "0.2s ease-out",
                position: "absolute",
                color: "black",
                height: "fit-content",
                zIndex: "1220"
            }}
        >
            <Box alignItems="center" justifyContent=" space-between" height={`${navHeight}px`} paddingRight="20px" display="flex" maxWidth="100%" id="app-bar" sx={{ transition: "0.2s ease-out", paddingLeft: "20px" }}>
                <Box display="flex" gap="10px" alignItems="center">
                    <IconButton sx={{ marginLeft: "-12px", marginTop: "5px", display: { md: "none", xs: "block" } }} size="small" onClick={menuState ? closeSideMenu : openSideMenu}>
                        <MenuIcon />
                    </IconButton>
                    {/* <img width="30px" src={aliveLogo} alt="Alive Logo" /> */}
                    <Typography variant='h6' fontWeight="500" fontSize={{ sm: "18px", xs: "16px" }}>{navHeadText || `Welcome to digital classrooms`}</Typography>
                </Box>
                {<Box display="flex" alignItems="center" gap="10px">
                    <Box display={{ sm: "block", xs: "none" }}>
                        <Typography marginBottom="-5px" textAlign="right" variant='subtitle1' textTransform="capitalize">{new Date().toLocaleDateString( 'en-IN', { month: "long", year: "numeric", day: "2-digit" } )}</Typography>
                    </Box>
                    <IconButton onClick={( e ) => setSettingsAnchorEl( e.currentTarget )}>
                        {Icons.default.SettingsOutlined}
                    </IconButton>

                    <Box position="relative">

                        <Box
                            ref={uploadProfileAlertRef}
                            // className='pop-alert'
                            onClick={( e ) => { e.target.classList.remove( "pop-alert" ) }}
                            border={`2px solid ${palette.greyedOut}`}
                            position="absolute"
                            right="0%"
                            top="calc(100% + 10px)"
                            width="max-content"
                            borderRadius="10px"
                            bgcolor={theme === 'dark' ? palette.grey[100] : palette.common.font}
                            padding="10px"
                            sx={{
                                cursor: "pointer",
                                transition: "200ms",
                                transform: "translate(0,-3000px)",
                                opacity: 0,

                                '&::after': {
                                    content: `''`,
                                    position: "absolute",
                                    bottom: "100%",
                                    right: "10px",
                                    width: 0,
                                    height: 0,
                                    borderTop: "8px solid transparent",
                                    borderBottom: `8px solid ${theme === 'dark' ? palette.grey[100] : palette.common.font}`,
                                    borderLeft: "8px solid transparent",
                                    borderRight: "8px solid transparent",
                                }
                            }}
                        >
                            <Typography marginBottom="20px" sx={{ pointerEvents: "none" }} maxWidth="240px" variant="subtitle2" color={palette.common.bg} >
                                Your profile is currently in stealth mode. Upload a photo to come out of the shadows!
                            </Typography>
                            <Button onClick={() => { setAnchorEl( avatarRef.current ); uploadProfileAlertRef.current.classList.remove( "pop-alert" ) }} fullWidth variant="outlined" sx={{ textTransform: "capitalize" }} color="warning" size="small" startIcon={<AccountCircle />}> Add profile image</Button>
                        </Box>

                        <Avatar ref={avatarRef} onClick={( e ) => setAnchorEl( e.currentTarget )} sx={{ height: "32px", width: "32px", border: `4px solid ${palette.borderColor}` }} src={profile ? convertToCDNLink( profile ) : ""}>
                            {( UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : "" ).substring( 0, 1 )}
                        </Avatar>
                    </Box>

                    <Popover transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} elevation={1} open={Boolean( settingsAnchorEl )} onClose={() => setSettingsAnchorEl( null )} slotProps={{ paper: { sx: { maxWidth: "300px" } } }} anchorEl={settingsAnchorEl}>
                        <Box padding="10px" display="flex" gap="20px" alignItems="center">
                            <Typography width="max-content" variant="variant2">Theme</Typography>
                            <Select sx={{ height: "25px", textTransform: "capitalize" }} size="small" value={selectedTheme} onChange={handleThemeChange} >
                                {themeOptions.map( theme => (
                                    <MenuItem key={theme} value={theme} dense sx={{ fontSize: "14px", textTransform: "capitalize" }}>{theme}</MenuItem>
                                ) )}
                            </Select>
                        </Box>
                    </Popover>

                    <Popover slotProps={{ paper: { sx: { minWidth: "180px", border: `1px solid ${palette.borderColor}` } } }} sx={{ maxWidth: "300px" }} onClose={e => { setAnchorEl( null ) }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} anchorEl={anchorEl} open={open}>

                        <input onChange={handleProfileImgSelection} type="file" accept="image/jpeg" name="profile" id="profile" className="invisible-element" ref={profileImgInputRef} />

                        <Box display="flex" alignItems="center" flexDirection="column" gap="20px" padding="20px">
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Avatar onClick={() => profileImgInputRef.current.click()} sx={{ bgcolor: "primary.main", width: 20, height: 20, cursor: "pointer" }}>
                                        <Edit sx={{ fontSize: "15px" }} />
                                    </Avatar>
                                }
                            >
                                <Avatar onClick={() => profileImgInputRef.current.click()} sx={{ cursor: "pointer", border: `4px solid ${palette.borderColor}` }} alt={UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : ""} src={profile ? convertToCDNLink( profile ) : ""} >
                                    {( UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : "" ).substring( 0, 1 )}
                                </Avatar>
                            </Badge>
                            <Box>
                                <Typography marginBottom="-5px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : ""}</Typography>
                                <Typography color="textSecondary" fontSize="10px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_role"] ? UserStore.getUser["user_id"] : ""}</Typography>
                                <Typography color="textSecondary" fontSize="10px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_role"] ? UserStore.getUser["user_role"] : ""}</Typography>
                            </Box>
                            <Button color="error" onClick={logout} startIcon={<LogoutIcon sx={{ color: "error.main" }} fontSize="small" />}>
                                Logout
                            </Button>
                        </Box>
                    </Popover>
                </Box>}

                <ProfileImageCropAndUploadDialog setProfile={setProfile} setImageSrc={setSelectedProfileImg} imageSrc={selectedProfileImg} />

            </Box>
        </AppBar>
    )
} )

export default Navbar
