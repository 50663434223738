import { Box, CircularProgress, Dialog, Drawer, IconButton, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../service/api'
import { attachmentThumbnails, convertToCDNLink, docViewerSupportedFormats, getFileSizeFromBytes, Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import { toast } from 'react-toastify'
import DocumentViewer from './DocumentViewer'
import TryAgainMessageBlock from './TryAgainMessageBlock'
import DataLoadingSpinner from './DataLoadingSpinner'
import { AttachmentOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

const AttachmentChip = ( { attachment, announcement, setPreviewingFile, setPreviewFile } ) => {
    const [downloading, setDownloading] = useState( false )
    const [waitingForViewing, setWaitingForViewing] = useState( false )

    const { palette } = useTheme()

    const attachmentType = attachment.name?.split( "." ).pop()?.toLowerCase()

    const handleClickOnChip = () => {
        if ( docViewerSupportedFormats.includes( attachmentType ) )
            viewAttachment()
        else
            toast( "Cannot view this type of file please download and try to view it on the system." )
    }

    const viewAttachment = async () => {
        setWaitingForViewing( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncementAttachment( announcement.id, attachment )
            setPreviewFile( convertToCDNLink( data ) )
            setPreviewingFile( attachment )
        } catch ( err ) {
            console.log( err )

            toast( err?.response?.data?.message || "Couldn't preview the file" )
        } finally {
            setWaitingForViewing( false )
        }
    }


    const downloadAttachment = async () => {
        setDownloading( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncementAttachment( announcement.id, attachment )
            const anchor = window.document.createElement( 'a' )
            anchor.href = convertToCDNLink( data )
            anchor.download = attachment.name
            anchor.click()
            anchor.remove()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        } finally {
            setDownloading( false )
        }
    }


    return ( <Box sx={{ cursor: "pointer" }} bgcolor={palette.primary.light + 22} border={`2px solid ${palette.primary.main}`} display="flex" gap="5px" alignItems="center" borderRadius="20px" padding="2px 5px 2px 2px">
        <img width="35px" src={attachmentThumbnails[attachmentType] || attachmentThumbnails["default"]} alt={attachment.name} />
        <Box onClick={handleClickOnChip}>
            <Typography title={attachment.name} maxWidth="120px" noWrap fontSize={{ md: "14px", xs: "12px" }} fontWeight="500">{attachment.name}</Typography>
            <Box display="flex" gap="5px" alignItems="center">
                <Typography title={attachment.mimetype} display="block" marginTop="-2px" maxWidth="80px" noWrap fontSize={{ md: "12px", xs: "10px" }}><em>{attachment.mimetype}</em></Typography>
                <Typography display="inline" marginTop="-2px" fontSize={{ md: "12px", xs: "10px" }}><em>. {getFileSizeFromBytes( attachment.size )}</em></Typography>
            </Box>
        </Box>
        {docViewerSupportedFormats.includes( attachmentType ) && <IconButton onClick={() => viewAttachment( attachment )} size="small">
            {waitingForViewing ? <CircularProgress size={14} /> : Icons.default.VisibilityIcon}
        </IconButton>}
        <IconButton onClick={() => downloadAttachment( attachment )} size="small">
            {downloading ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}
        </IconButton>
    </Box> )
}

const AnnouncementDialog = ( { announcement_id, state, onClose } ) => {

    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFile, setPreviewingFile] = useState( null )
    const [announcement, setAnnouncement] = useState( null )
    const [showAttachmentsOnSmallScreen, setShowAttachmentsOnSmallScreen] = useState( false )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { palette, theme, border } = useTheme()

    const fetchAnnouncement = useCallback( async ( showLoading = true ) => {
        if ( showLoading )
            setLoading( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncement( announcement_id )
            setAnnouncement( data )
            setError( null )
            return data
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message } )
        } finally {
            setLoading( false )
        }
    }, [announcement_id] )

    useEffect( () => {
        fetchAnnouncement()
    }, [fetchAnnouncement] )

    return (
        <Dialog className={theme} PaperProps={{ sx: { height: "80%", display: "flex", flexDirection: "column", overflow: "hidden", width: "90%", maxWidth: "1200px" } }} open={state} onClose={onClose}>
            {Boolean( previewingFile ) && <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFile?.name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFile( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer fileDetails={previewingFile} loadingText="Loading attachment..." setFile={setPreviewFile} type={previewingFile?.type || previewingFile?.name?.split( "." ).pop()} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>}

            {!loading && !error && announcement && <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
                <Box position="relative" padding="20px" borderBottom={border[1]}>
                    {announcement?.cover_image && <Box sx={{ position: "absolute", inset: 0, opacity: 0.8, pointerEvents: "none", zIndex: "1" }}>
                        <img style={{ objectFit: "cover" }} height="100%" width="100%" src={convertToCDNLink( announcement.cover_image )} alt="Cover banner" />
                    </Box>}
                    {announcement?.cover_image && <Box sx={{ position: "absolute", inset: 0, background: `linear-gradient(to right,${palette.common.bg},transparent 250%)`, pointerEvents: "none", zIndex: "3" }}>
                    </Box>}
                    <Box position="relative" sx={{ zIndex: 10 }}>
                        <Typography marginTop="10px" variant='h4'>
                            {announcement.announcement_title}
                        </Typography>
                        <Typography display="flex" alignItems='flex-start' gap="20px" variant='subtitle2'>
                            <em>{`${showDateInIndianDateFormat( announcement.announcement_created_at )}`}</em>
                        </Typography>
                    </Box>
                </Box>
                <Box flexGrow={1} className="slim-custom-scrollbar" display={{ md: "grid", xs: "initial" }} flexDirection={{ md: "initial", xs: "column-reverse" }} overflow="auto" alignItems="flex-start" gridTemplateColumns={{ md: announcement.attachments && announcement.attachments?.length > 0 ? "auto 320px" : "1fr", xs: "1fr" }}>
                    <Box padding="0 20px" overflow="auto" className="slim-custom-scrollbar" height={{ md: "100%", xs: "initial" }}>
                        <Box className="wysiwyg-text-container" marginTop="10px" dangerouslySetInnerHTML={{ __html: announcement.announcement_message }}></Box>
                    </Box>
                    {announcement.attachments && announcement.attachments?.length > 0 && <Box bgcolor={{ md: palette.contentBg, xs: "none" }} position={{ md: "initial", xs: "absolute" }} bottom={0} left="0" right="5px" display="flex" className="slim-custom-scrollbar" height={{ md: "100%", xs: "initial" }} overflow="auto" alignContent="flex-start" alignItems="flex-start" gap="20px" flexWrap="wrap" >
                        <Box marginX={{ md: "0", xs: "10px" }} flexGrow={1}>
                            <Box border={border[1]} borderBottom={showAttachmentsOnSmallScreen ? border[1] : "none"} bgcolor={palette.contentBg} sx={{ cursor: "pointer" }} borderRadius="10px 10px 0 0" overflow="clip" padding="10px" onClick={() => setShowAttachmentsOnSmallScreen( prev => !prev )} display={{ md: "none", xs: "flex" }} alignItems="center" justifyContent="space-between" gap="20px">
                                <Box flexGrow={1} display="flex" alignItems="center" gap="20px">
                                    <AttachmentOutlined fontSize='small' />
                                    <Typography flexGrow={1} variant='subtitle2'>View Attachments</Typography>
                                </Box>
                                {!showAttachmentsOnSmallScreen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Box>
                            <Box bgcolor={palette.contentBg} borderRight={{ md: "none", xs: showAttachmentsOnSmallScreen ? border[1] : "none" }} borderLeft={{ md: "none", xs: showAttachmentsOnSmallScreen ? border[1] : "none" }} justifyContent={{ md: "flex-end", xs: "flex-start" }} className="slim-custom-scrollbar" overflow={{ md: "initial", xs: "auto" }} maxHeight={{ md: "initial", xs: "200px" }} display={{ md: "flex", xs: showAttachmentsOnSmallScreen ? "flex" : "none" }} padding={{ xs: "10px", md: "20px" }} flexWrap="wrap" alignItems="center" gap={{ md: "20px", xs: "10px" }} >
                                {announcement.attachments?.length > 0 && announcement.attachments?.map( ( attachment, attachmentIndex ) => {
                                    return (
                                        <AttachmentChip announcement={announcement} setPreviewFile={setPreviewFile} setPreviewingFile={setPreviewingFile} key={`attachment-${attachmentIndex}`} attachment={attachment} />
                                    )
                                } )}
                            </Box>
                        </Box>
                    </Box>}
                </Box>
            </Box>}
            {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={fetchAnnouncement} loading={loading} />}
            {!error && loading && <DataLoadingSpinner waitingMessage="Loading announcement details..." />}
        </Dialog >
    )
}

export default AnnouncementDialog
