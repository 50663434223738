import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import NotificationStore from "./notification.store"
import fileDownload from 'js-file-download'
import React from 'react'
import UserStore from './user.store'

class AssignmentStore {
    assignment
    subjectAssignment
    submission
    batch
    notify
    loading
    doEdit
    errors
    constructor () {
        this.assignment = []
        this.subjectAssignment = []
        this.submission = []
        this.batch = { "batches": [], "sections": [] }
        this.api = api
        this.loading = false
        this.notify = NotificationStore
        this.doEdit = false
        this.selectedAssignment = {}
        this.dueOrRecentAssignments = []
        this.errors = { assignments: true, recentAssignments: true, subjectAssignments: true }

        makeObservable( this, {
            assignment: observable,
            selectedAssignment: observable,
            dueOrRecentAssignments: observable,
            submission: observable,
            batch: observable,
            loading: observable,
            doEdit: observable,
            getAssignment: computed,
            getSubjectAssignment: computed,
            getSubmission: computed,
            getDueOrRecentAssignments: computed,
            getBatch: computed,
            setAssignment: action,
            fetchSubjectAssignments: action,
            fetchBatch: action,
            setUpdateStatus: action,
            setSubjectAssignment: action,
            downloadAssignment: action,
            fetchDueOrRecentAssignments: action,
            getSelectedAssignment: computed
        } )
    }

    get getAssignment() {
        return toJS( this.assignment )
    }

    get getSubmission() {
        return toJS( this.submission )
    }

    get getBatch() {
        return toJS( this.batch )
    }

    get getSelectedAssignment() {
        return toJS( this.selectedAssignment )
    }

    get getSubjectAssignment() {
        return toJS( this.subjectAssignment )
    }

    get getDueOrRecentAssignments() {
        return toJS( this.dueOrRecentAssignments )
    }

    get getErrors() {
        return toJS( this.errors )
    }

    setAssignment( assignments ) {
        runInAction( () => {
            this.assignment = assignments
        } )
    }

    setSubjectAssignment( assignments ) {
        runInAction( () => {
            this.subjectAssignment = assignments
        } )
    }

    fetchBatch = async ( ac_year_id ) => {
        try {
            const res = await this.api.getAllBatch( { ac_year_id: ac_year_id } )
            console.log( `Batch API`, res["data"]["data"] )
            if ( res.data.success === false && res.data.message === "Invalid Token" ) {
                return false
            }
            this.batch = res["data"]["data"] ? res["data"]["data"] : { "batches": [], "sections": [] }
            return true
        } catch ( err ) {
            console.log( err )
        }
    }

    uploadAssignment = async ( payload, subject_id = null ) => {
        try {
            const res = await this.api.uploadAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            if ( !subject_id )
                this.fetchAssignmentByAuthor()
            else
                this.fetchSubjectAssignments()

            return true
        } catch ( err ) {
            console.log( `Upload Err `, err )
            this.notify.setNotification( true, err.response["data"]["message"] )
            return false

        }
    }

    deleteAssignment = async ( payload ) => {
        try {
            const res = await this.api.deleteAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentByAuthor()
        } catch ( err ) {
            this.notify.setNotification( true, err.response?.data?.message || "Something went wrong while deleting your assignment! please try again." )
        }
    }

    deleteAssignmentSubmission = async ( payload ) => {
        try {
            const res = await this.api.deleteAssignmentSubmitted( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentSubmission( payload.assignment_id )
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }

    fetchAssignmentByAuthor = async () => {
        try {
            const res = await this.api.fetchAssignmentByAuthor()
            this.setAssignment( res["data"]["data"] ? res["data"]["data"] : [] )
            runInAction( () => {
                this.errors.assignments = false
            } )
        } catch ( err ) {
            runInAction( () => {
                this.errors.assignments = true
            } )
            this.notify.setNotification( true, err.response?.data?.message || "Something went wrong while fetching your assignments" )
        }
    }

    fetchAssignmentByStudent = async () => {
        try {
            const res = await this.api.fetchAssignmentByStudent()
            this.setAssignment( res["data"]["assignments"] ? res["data"]["assignments"] : [] )
            runInAction( () => {
                this.errors.assignments = false
            } )
        } catch ( err ) {
            runInAction( () => {
                this.errors.assignments = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else NotificationStore.setNotification( true, err?.response?.data?.message || 'Something went wrong' )
        }
    }

    fetchDueOrRecentAssignments = async ( subjectId ) => {
        try {
            const { data } = await this.api.fetchDueOrRecentAssignment( { subjectId } )
            if ( data.success ) {
                runInAction( () => {
                    this.dueOrRecentAssignments = data.data ? data.data : []
                    this.errors.recentAssignments = false
                } )
            }
        } catch ( err ) {
            runInAction( () => {
                this.errors.recentAssignments = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else NotificationStore.setNotification( true, err?.response?.data?.message || 'Something went wrong' )
        }
    }


    fetchSubjectAssignments = async ( subjectId ) => {
        try {
            const { data } = await this.api.fetchSubjectAssignment( { subjectId } )
            if ( data.success ) {
                this.setSubjectAssignment( data.data ? data.data : [] )
                runInAction( () => {
                    this.errors.subjectAssignments = false
                } )
            }
        } catch ( err ) {
            runInAction( () => {
                this.errors.subjectAssignments = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else NotificationStore.setNotification( true, err?.response?.data?.message || 'Something went wrong' )
        }
    }

    fetchAssignmentSubmission = async ( id ) => {
        try {
            const res = await this.api.fetchAssignmentSubmission( id )
            //
            let submissionData = JSON.parse( JSON.stringify( res["data"]["data"] ? res["data"]["data"] : [] ) )
            submissionData.map( ( a, index ) => a['id'] = index + 1 )
            console.log( `Fet Assignment Sub `, submissionData )

            this.submission = submissionData
        } catch ( err ) {
            console.log( `Upload Err `, err.response["data"]["message"] )
        }
    }

    downloadAssignment = async ( url ) => {
        let fname = url.split( "." )[url.split( "." ).length - 2] + "." + url.split( "." )[url.split( "." ).length - 1]
        try {
            const res = await this.api.downloadAssignment( url )
            fileDownload( res.data, fname?.replace( /%\d+/g, " " ) )
        } catch ( err ) {
            console.log( err )
        }
    }

    setAssignmentScore = async ( payload ) => {
        try {
            const res = await this.api.setAssignmentScore( payload )
            //this.fetchAssignmentByAuthor()
            this.updateSubmission( payload )
            this.notify.setNotification( true, res["data"]["message"] )
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }


    setAssignmentRemarks = async ( payload ) => {
        try {
            const res = await this.api.setAssignmentRemarks( payload )
            //this.fetchAssignmentByAuthor()
            this.updateSubmission( payload, 'remarks' )
            this.notify.setNotification( true, res["data"]["message"] )
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }

    updateSubmission = ( payload, type = 'marks' ) => {
        let currentValue = JSON.parse( JSON.stringify( this.submission ) )
        currentValue.forEach( p => {
            if ( p.assignment_submission_id === payload.assignment_submission_id )
                if ( type === 'marks' ) {
                    p.assignment_marks = payload.assignment_marks
                } else {
                    p.assignment_remarks = payload.assignment_remarks
                }
        } )
        //console.log(currentValue)
        this.submission = currentValue
    }

    uploadAssignmentSubmission = async ( payload, id ) => {
        this.loading = true
        try {
            const res = await this.api.uploadAssignmentSubmission( payload, { assignment_id: id } )
            //console.log(`Upload Assignment Submission `, res["data"])
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentByStudent()
            this.loading = false
            return true
        } catch ( err ) {
            this.notify.setNotification( true, err.response?.data?.message || "Something went wrong!" )
            this.loading = false
            return false
        }
    }

    setUpdateStatus = ( payload, status ) => {
        this.selectedAssignment = payload
        this.doEdit = status
    }

    updateAssignmentDetail = async ( payload ) => {
        payload["assignment_id"] = this.selectedAssignment["assignment_id"]
        try {
            const res = await api.updateAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            return true
        } catch ( err ) {
            this.notify.setNotification( true, err.response?.data?.message || "Something went wrong!" )
            return false
        }
    }
}

const AssignmentStoreInstance = new AssignmentStore()

export default AssignmentStoreInstance

const AssignmentStoreContext = React.createContext()

export const AssignmentStoreProvider = ( { children, store } ) => {
    return (
        <AssignmentStoreContext.Provider value={store}>{children}</AssignmentStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useAssignmentStore = () => React.useContext( AssignmentStoreContext )